@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.background-ground-trees {
  bottom: 40px;
  width: 80vw;
  margin: 0 auto;
  z-index: -1;
}

@include media-breakpoint-up(sm) {
  .background-ground-trees {
    bottom: 40px;
  }
}

@include media-breakpoint-up(md) {
  .background-ground-trees {
    bottom: 85px;
  }
}

@include media-breakpoint-up(lg) {
  .background-ground-trees {
    bottom: 165px;
  }
}

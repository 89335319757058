@import "node_modules/bootstrap/scss/_code.scss";

code {
  padding: 2px 4px;
  color: color("blue");
  background-color: gray("100");
  border: 1px solid rgba(color("blue"), 0.05);
  border-radius: 2px;
}

pre {
  background-color: gray("100");

  code {
    padding: 0;
    background-color: transparent;
  }
}

/*

Atom One Light by Daniel Gamage
Original One Light Syntax theme from https://github.com/atom/one-light-syntax

base:    #fafafa
mono-1:  #383a42
mono-2:  #686b77
mono-3:  #a0a1a7
hue-1:   #0184bb
hue-2:   #4078f2
hue-3:   #a626a4
hue-4:   #50a14f
hue-5:   #e45649
hue-5-2: #c91243
hue-6:   #986801
hue-6-2: #c18401

*/

.hljs {
  display: block;
  padding: 0.5em;
  overflow-x: auto;
  color: #383A42;
  background: #FAFAFA;
}

.hljs-comment,
.hljs-quote {
  font-style: italic;
  color: #A0A1A7;
}

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
  color: #A626A4;
}

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
  color: #E45649;
}

.hljs-literal {
  color: #0184BB;
}

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta-string {
  color: #50A14F;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #C18401;
}

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
  color: #986801;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-title {
  color: #4078F2;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 600;
}

.hljs-link {
  text-decoration: underline;
}

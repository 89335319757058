@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.help-character {
  position: absolute;
  display: none;
  width: 7rem;
  transform: translateX(-100%);
  z-index: $zindex-fixed;
}

@include media-breakpoint-up(md) {
  .help-character {
    display: block;
    bottom: 7rem;
  }
}

@include media-breakpoint-up(lg) {
  .help-character {
    bottom: 5rem;
  }
}

.container-help-bubble {
  margin-top: -20vh;
  max-width: 50vw;
}

.container-help-close {
  position: relative;
  bottom: 10vh;
}

.container-help-owl {
  bottom: -10vh;
  left: -20vw;
  height: 50%;
  width: 50%;
  transform: rotate(20deg);
  position: absolute;
}

.container-help-lightbulb {
  height: 10vh;
  width: auto;
}

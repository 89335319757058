@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.onboarding,
.carousel-inner,
.carousel-item {
  height: 100% !important;
}

.onboarding-background {
  position: absolute;
  padding-bottom: 15vh;
  height: 100%;
  width: 100%;

  * {
    height: 100%;
    width: 100%;
  }
}

.carousel-indicators li {
  $bullet_base_size: 6px;
  $bullet_base_margin: $bullet_base_size * 1.5;

  width: $bullet_base_size;
  height: $bullet_base_size;
  border-radius: $bullet_base_size;
  margin-left: $bullet_base_margin;
  margin-right: $bullet_base_margin;
  border: none;
  background-color: rgba($brainimals-pale-grey, 0.70´);
  padding: 0;

  transition: all 250ms ease-in-out;

  &:hover {
    cursor: pointer;
    border: none;
    background-color: $white;
  }

  &:focus {
    outline: 0;
  }

  &.active {
    background-color: $white;
    width: $bullet_base_size * 3;
  }
}

.onboarding-carousel-title {
  font-size: $h4-font-size;
  margin: 5vh 0 1rem 0;
}
.onboarding-carousel-description {
  font-size: $h6-font-size;
  margin: 0;
}

.onboarding-carousel-text {
  color: $white;
  font-size: $h6-font-size;
  margin-top: 2rem;
}

.onboarding-carousel {
  text-align: center;
  height: 80vh !important;

  .btn {
    z-index: 1000;
  }
}

.carousel-indicators {
  margin-bottom: 2.5vh;
}

.onboarding-devices {
  max-height: 40vh;
}

.onboarding-character,
.onboarding-badge,
.onboarding-trees {
  height: auto;
  max-width: 100%;

  * {
    height: 100%;
    width: auto;
  }

  > div > div {
    display: flex !important;
    flex-direction: column !important;
    align-content: center !important;
    justify-content: center !important;
  }
}

.onboarding-character {
  * svg {
    height: auto;
    width: 100%;
    max-height: 40vh;
  }
}

.onboarding-badge,
.onboarding-trees {
  margin: 0 auto;

  * svg {
    width: 100%;
    height: auto;
  }
}

.onboarding-badge {
  margin-top: 2vh;
  * svg {
    width: 100%;
    height: auto;
    max-height: 18vh;
  }
}

.onboarding-trees {
  margin-top: 5vh;
  * svg {
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: 50vw;
  }
}

@include media-breakpoint-up(sm) {
  .carousel-indicators {
    margin-bottom: 3.5vh;
  }
  .onboarding-carousel-title {
    font-size: $h5-font-size;
    margin: 5vh 0 1rem 0;
  }
  .onboarding-carousel-description {
    font-size: $h6-font-size;
  }
  .onboarding-carousel-text {
    font-size: $h6-font-size;
    margin-top: 2rem;
  }

  .onboarding-badge {
    margin-top: 0vh;
    * svg {
      max-height: 18vh;
    }
  }
  .onboarding-trees {
    margin-top: 20vh;
    * svg {
      max-width: 50vw;
    }
  }
}

@include media-breakpoint-up(md) {
  .onboarding-carousel-text {
    font-size: $h4-font-size;
    margin-top: 2rem;
  }
  .onboarding-carousel-title {
    font-size: $h4-font-size;
  }
  .onboarding-carousel-description {
    font-size: $h5-font-size;
  }
  .onboarding-carousel-text {
    font-size: $h5-font-size;
  }
  .onboarding-badge {
    margin-top: 4vh;
    * svg {
      max-height: 18vh;
    }
  }
  .onboarding-trees {
    margin-top: 6vh;
    * svg {
      max-width: 50vw;
    }
  }
}
@include media-breakpoint-up(lg) {
  .carousel-indicators {
    margin-bottom: 4.5vh;
  }
  .onboarding-carousel-title {
    font-size: $h3-font-size;
  }
  .onboarding-carousel-description {
    font-size: $h5-font-size;
  }
  .onboarding-carousel-text {
    font-size: $h5-font-size;
    margin-top: 2rem;
  }
  .onboarding-badge {
    margin-top: 4vh;
    * svg {
      max-height: 18vh;
    }
  }
  .onboarding-trees {
    margin-top: 5vh;
    * svg {
      max-width: 50vh;
    }
  }
}

@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Quicksand:400,500,700&subset=latin-ext);
@charset "UTF-8";
.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFF;
  --gray: #898D8F;
  --gray-dark: #62676A;
  --primary: #01507B;
  --secondary: #CFE676;
  --success: #4CA325;
  --info: #8CA2B5;
  --warning: #FDBD39;
  --danger: #E86B64;
  --light: #C2CFDB;
  --dark: #17232C;
  --brainimals-aqua: #11D1D2;
  --brainimals-topaz: #0EB0B1;
  --brainimals-blue-light: #6FCEED;
  --brainimals-deep-sea-blue: #01507B;
  --brainimals-dark-cyan: #0B8E90;
  --brainimals-darker-cyan: #045E61;
  --brainimals-green-light: #CFE676;
  --brainimals-green: #4CA325;
  --brainimals-orange: #FDBD39;
  --brainimals-red: #E86B64;
  --brainimals-dark-grey-blue: #314251;
  --brainimals-pale-grey-two: #DEE6EB;
  --brainimals-pale-grey: #EDF2F4;
  --brainimals-bluey-grey: #8CA2B5;
  --brainimals-cloudy-blue: #C2CFDB;
  --brainimals-blue-grey: #60768A;
  --brainimals-dark: #17232C;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Quicksand", "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Quicksand", "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  color: #4E5457;
  text-align: left;
  background-color: #FFF; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #01507B;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #001f2f;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #898D8F;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 400; }

.display-1 {
  font-size: 6rem;
  font-weight: 500;
  line-height: 1.2; }

.display-2 {
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.2; }

.display-3 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.2; }

.display-4 {
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 500; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #898D8F; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.line-height-normal {
  line-height: normal; }

.line-height-1 {
  line-height: 1 !important; }

.text-highlight {
  background-color: #60768A;
  padding: 0.2rem 0.5rem;
  margin: 0 !important;
  color: #FFF;
  border-radius: 0.25rem; }

.text-highlight:not(:last-of-type) {
  margin-right: 2px !important; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFF;
  border: 1px solid #C4C6C7;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #898D8F; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFF;
  background-color: #4E5457;
  border-radius: 0.2rem;
  -webkit-box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
          box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    -webkit-box-shadow: none;
            box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #4E5457; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

code {
  padding: 2px 4px;
  color: #007bff;
  background-color: #EBECEC;
  border: 1px solid rgba(0, 123, 255, 0.05);
  border-radius: 2px; }

pre {
  background-color: #EBECEC; }
  pre code {
    padding: 0;
    background-color: transparent; }

/*

Atom One Light by Daniel Gamage
Original One Light Syntax theme from https://github.com/atom/one-light-syntax

base:    #fafafa
mono-1:  #383a42
mono-2:  #686b77
mono-3:  #a0a1a7
hue-1:   #0184bb
hue-2:   #4078f2
hue-3:   #a626a4
hue-4:   #50a14f
hue-5:   #e45649
hue-5-2: #c91243
hue-6:   #986801
hue-6-2: #c18401

*/
.hljs {
  display: block;
  padding: 0.5em;
  overflow-x: auto;
  color: #383A42;
  background: #FAFAFA; }

.hljs-comment,
.hljs-quote {
  font-style: italic;
  color: #A0A1A7; }

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
  color: #A626A4; }

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
  color: #E45649; }

.hljs-literal {
  color: #0184BB; }

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta-string {
  color: #50A14F; }

.hljs-built_in,
.hljs-class .hljs-title {
  color: #C18401; }

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
  color: #986801; }

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-title {
  color: #4078F2; }

.hljs-emphasis {
  font-style: italic; }

.hljs-strong {
  font-weight: 600; }

.hljs-link {
  text-decoration: underline; }

.container {
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
      order: -1; }

.order-last {
  -ms-flex-order: 13;
      order: 13; }

.order-0 {
  -ms-flex-order: 0;
      order: 0; }

.order-1 {
  -ms-flex-order: 1;
      order: 1; }

.order-2 {
  -ms-flex-order: 2;
      order: 2; }

.order-3 {
  -ms-flex-order: 3;
      order: 3; }

.order-4 {
  -ms-flex-order: 4;
      order: 4; }

.order-5 {
  -ms-flex-order: 5;
      order: 5; }

.order-6 {
  -ms-flex-order: 6;
      order: 6; }

.order-7 {
  -ms-flex-order: 7;
      order: 7; }

.order-8 {
  -ms-flex-order: 8;
      order: 8; }

.order-9 {
  -ms-flex-order: 9;
      order: 9; }

.order-10 {
  -ms-flex-order: 10;
      order: 10; }

.order-11 {
  -ms-flex-order: 11;
      order: 11; }

.order-12 {
  -ms-flex-order: 12;
      order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #4E5457; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #C4C6C7; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #C4C6C7; }
  .table tbody + tbody {
    border-top: 2px solid #C4C6C7; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #C4C6C7; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #C4C6C7; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #4E5457;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8ceda; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ba4ba; }

.table-hover .table-primary:hover {
  background-color: #a7c2d1; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a7c2d1; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f2f8d9; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #e6f2b8; }

.table-hover .table-secondary:hover {
  background-color: #ebf4c3; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #ebf4c3; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cde5c2; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a2cf8e; }

.table-hover .table-success:hover {
  background-color: #beddb0; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #beddb0; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #dfe5ea; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #c3cfd9; }

.table-hover .table-info:hover {
  background-color: #d0d8e0; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #d0d8e0; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #feedc8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fedd98; }

.table-hover .table-warning:hover {
  background-color: #fee5af; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fee5af; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f9d6d4; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f3b2ae; }

.table-hover .table-danger:hover {
  background-color: #f6c1be; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f6c1be; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #eef2f5; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #dfe6ec; }

.table-hover .table-light:hover {
  background-color: #dee6ec; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #dee6ec; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bec1c4; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #868d91; }

.table-hover .table-dark:hover {
  background-color: #b1b4b8; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b1b4b8; }

.table-brainimals-aqua,
.table-brainimals-aqua > th,
.table-brainimals-aqua > td {
  background-color: #bcf2f2; }

.table-brainimals-aqua th,
.table-brainimals-aqua td,
.table-brainimals-aqua thead th,
.table-brainimals-aqua tbody + tbody {
  border-color: #83e7e8; }

.table-hover .table-brainimals-aqua:hover {
  background-color: #a7eeee; }
  .table-hover .table-brainimals-aqua:hover > td,
  .table-hover .table-brainimals-aqua:hover > th {
    background-color: #a7eeee; }

.table-brainimals-topaz,
.table-brainimals-topaz > th,
.table-brainimals-topaz > td {
  background-color: #bce9e9; }

.table-brainimals-topaz th,
.table-brainimals-topaz td,
.table-brainimals-topaz thead th,
.table-brainimals-topaz tbody + tbody {
  border-color: #82d6d6; }

.table-hover .table-brainimals-topaz:hover {
  background-color: #a9e3e3; }
  .table-hover .table-brainimals-topaz:hover > td,
  .table-hover .table-brainimals-topaz:hover > th {
    background-color: #a9e3e3; }

.table-brainimals-blue-light,
.table-brainimals-blue-light > th,
.table-brainimals-blue-light > td {
  background-color: #d7f1fa; }

.table-brainimals-blue-light th,
.table-brainimals-blue-light td,
.table-brainimals-blue-light thead th,
.table-brainimals-blue-light tbody + tbody {
  border-color: #b4e6f6; }

.table-hover .table-brainimals-blue-light:hover {
  background-color: #c0e9f7; }
  .table-hover .table-brainimals-blue-light:hover > td,
  .table-hover .table-brainimals-blue-light:hover > th {
    background-color: #c0e9f7; }

.table-brainimals-deep-sea-blue,
.table-brainimals-deep-sea-blue > th,
.table-brainimals-deep-sea-blue > td {
  background-color: #b8ceda; }

.table-brainimals-deep-sea-blue th,
.table-brainimals-deep-sea-blue td,
.table-brainimals-deep-sea-blue thead th,
.table-brainimals-deep-sea-blue tbody + tbody {
  border-color: #7ba4ba; }

.table-hover .table-brainimals-deep-sea-blue:hover {
  background-color: #a7c2d1; }
  .table-hover .table-brainimals-deep-sea-blue:hover > td,
  .table-hover .table-brainimals-deep-sea-blue:hover > th {
    background-color: #a7c2d1; }

.table-brainimals-dark-cyan,
.table-brainimals-dark-cyan > th,
.table-brainimals-dark-cyan > td {
  background-color: #bbdfe0; }

.table-brainimals-dark-cyan th,
.table-brainimals-dark-cyan td,
.table-brainimals-dark-cyan thead th,
.table-brainimals-dark-cyan tbody + tbody {
  border-color: #80c4c5; }

.table-hover .table-brainimals-dark-cyan:hover {
  background-color: #a9d7d8; }
  .table-hover .table-brainimals-dark-cyan:hover > td,
  .table-hover .table-brainimals-dark-cyan:hover > th {
    background-color: #a9d7d8; }

.table-brainimals-darker-cyan,
.table-brainimals-darker-cyan > th,
.table-brainimals-darker-cyan > td {
  background-color: #b9d2d3; }

.table-brainimals-darker-cyan th,
.table-brainimals-darker-cyan td,
.table-brainimals-darker-cyan thead th,
.table-brainimals-darker-cyan tbody + tbody {
  border-color: #7cabad; }

.table-hover .table-brainimals-darker-cyan:hover {
  background-color: #a9c8c9; }
  .table-hover .table-brainimals-darker-cyan:hover > td,
  .table-hover .table-brainimals-darker-cyan:hover > th {
    background-color: #a9c8c9; }

.table-brainimals-green-light,
.table-brainimals-green-light > th,
.table-brainimals-green-light > td {
  background-color: #f2f8d9; }

.table-brainimals-green-light th,
.table-brainimals-green-light td,
.table-brainimals-green-light thead th,
.table-brainimals-green-light tbody + tbody {
  border-color: #e6f2b8; }

.table-hover .table-brainimals-green-light:hover {
  background-color: #ebf4c3; }
  .table-hover .table-brainimals-green-light:hover > td,
  .table-hover .table-brainimals-green-light:hover > th {
    background-color: #ebf4c3; }

.table-brainimals-green,
.table-brainimals-green > th,
.table-brainimals-green > td {
  background-color: #cde5c2; }

.table-brainimals-green th,
.table-brainimals-green td,
.table-brainimals-green thead th,
.table-brainimals-green tbody + tbody {
  border-color: #a2cf8e; }

.table-hover .table-brainimals-green:hover {
  background-color: #beddb0; }
  .table-hover .table-brainimals-green:hover > td,
  .table-hover .table-brainimals-green:hover > th {
    background-color: #beddb0; }

.table-brainimals-orange,
.table-brainimals-orange > th,
.table-brainimals-orange > td {
  background-color: #feedc8; }

.table-brainimals-orange th,
.table-brainimals-orange td,
.table-brainimals-orange thead th,
.table-brainimals-orange tbody + tbody {
  border-color: #fedd98; }

.table-hover .table-brainimals-orange:hover {
  background-color: #fee5af; }
  .table-hover .table-brainimals-orange:hover > td,
  .table-hover .table-brainimals-orange:hover > th {
    background-color: #fee5af; }

.table-brainimals-red,
.table-brainimals-red > th,
.table-brainimals-red > td {
  background-color: #f9d6d4; }

.table-brainimals-red th,
.table-brainimals-red td,
.table-brainimals-red thead th,
.table-brainimals-red tbody + tbody {
  border-color: #f3b2ae; }

.table-hover .table-brainimals-red:hover {
  background-color: #f6c1be; }
  .table-hover .table-brainimals-red:hover > td,
  .table-hover .table-brainimals-red:hover > th {
    background-color: #f6c1be; }

.table-brainimals-dark-grey-blue,
.table-brainimals-dark-grey-blue > th,
.table-brainimals-dark-grey-blue > td {
  background-color: #c5cace; }

.table-brainimals-dark-grey-blue th,
.table-brainimals-dark-grey-blue td,
.table-brainimals-dark-grey-blue thead th,
.table-brainimals-dark-grey-blue tbody + tbody {
  border-color: #949da5; }

.table-hover .table-brainimals-dark-grey-blue:hover {
  background-color: #b7bdc2; }
  .table-hover .table-brainimals-dark-grey-blue:hover > td,
  .table-hover .table-brainimals-dark-grey-blue:hover > th {
    background-color: #b7bdc2; }

.table-brainimals-pale-grey-two,
.table-brainimals-pale-grey-two > th,
.table-brainimals-pale-grey-two > td {
  background-color: #f6f8f9; }

.table-brainimals-pale-grey-two th,
.table-brainimals-pale-grey-two td,
.table-brainimals-pale-grey-two thead th,
.table-brainimals-pale-grey-two tbody + tbody {
  border-color: #eef2f5; }

.table-hover .table-brainimals-pale-grey-two:hover {
  background-color: #e7ecef; }
  .table-hover .table-brainimals-pale-grey-two:hover > td,
  .table-hover .table-brainimals-pale-grey-two:hover > th {
    background-color: #e7ecef; }

.table-brainimals-pale-grey,
.table-brainimals-pale-grey > th,
.table-brainimals-pale-grey > td {
  background-color: #fafbfc; }

.table-brainimals-pale-grey th,
.table-brainimals-pale-grey td,
.table-brainimals-pale-grey thead th,
.table-brainimals-pale-grey tbody + tbody {
  border-color: #f6f8f9; }

.table-hover .table-brainimals-pale-grey:hover {
  background-color: #eaeef2; }
  .table-hover .table-brainimals-pale-grey:hover > td,
  .table-hover .table-brainimals-pale-grey:hover > th {
    background-color: #eaeef2; }

.table-brainimals-bluey-grey,
.table-brainimals-bluey-grey > th,
.table-brainimals-bluey-grey > td {
  background-color: #dfe5ea; }

.table-brainimals-bluey-grey th,
.table-brainimals-bluey-grey td,
.table-brainimals-bluey-grey thead th,
.table-brainimals-bluey-grey tbody + tbody {
  border-color: #c3cfd9; }

.table-hover .table-brainimals-bluey-grey:hover {
  background-color: #d0d8e0; }
  .table-hover .table-brainimals-bluey-grey:hover > td,
  .table-hover .table-brainimals-bluey-grey:hover > th {
    background-color: #d0d8e0; }

.table-brainimals-cloudy-blue,
.table-brainimals-cloudy-blue > th,
.table-brainimals-cloudy-blue > td {
  background-color: #eef2f5; }

.table-brainimals-cloudy-blue th,
.table-brainimals-cloudy-blue td,
.table-brainimals-cloudy-blue thead th,
.table-brainimals-cloudy-blue tbody + tbody {
  border-color: #dfe6ec; }

.table-hover .table-brainimals-cloudy-blue:hover {
  background-color: #dee6ec; }
  .table-hover .table-brainimals-cloudy-blue:hover > td,
  .table-hover .table-brainimals-cloudy-blue:hover > th {
    background-color: #dee6ec; }

.table-brainimals-blue-grey,
.table-brainimals-blue-grey > th,
.table-brainimals-blue-grey > td {
  background-color: #d2d9de; }

.table-brainimals-blue-grey th,
.table-brainimals-blue-grey td,
.table-brainimals-blue-grey thead th,
.table-brainimals-blue-grey tbody + tbody {
  border-color: #acb8c2; }

.table-hover .table-brainimals-blue-grey:hover {
  background-color: #c3cdd3; }
  .table-hover .table-brainimals-blue-grey:hover > td,
  .table-hover .table-brainimals-blue-grey:hover > th {
    background-color: #c3cdd3; }

.table-brainimals-dark,
.table-brainimals-dark > th,
.table-brainimals-dark > td {
  background-color: #bec1c4; }

.table-brainimals-dark th,
.table-brainimals-dark td,
.table-brainimals-dark thead th,
.table-brainimals-dark tbody + tbody {
  border-color: #868d91; }

.table-hover .table-brainimals-dark:hover {
  background-color: #b1b4b8; }
  .table-hover .table-brainimals-dark:hover > td,
  .table-hover .table-brainimals-dark:hover > th {
    background-color: #b1b4b8; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFF;
  background-color: #62676A;
  border-color: #747a7e; }

.table .thead-light th {
  color: #757A7C;
  background-color: #D8D9DA;
  border-color: #C4C6C7; }

.table-dark {
  color: #FFF;
  background-color: #62676A; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #747a7e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #FFF;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .form-control + label {
  display: block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  color: #314251;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(11, 142, 144, 0.15);
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .form-control + label {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .form-control::-ms-expand, .form-control + label::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, .form-control + label:focus {
    color: #314251;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: #07a5aa;
    outline: 0;
    -webkit-box-shadow: none, none;
            box-shadow: none, none; }
  .form-control::-webkit-input-placeholder, .form-control + label::-webkit-input-placeholder {
    color: rgba(117, 122, 124, 0.75);
    opacity: 1; }
  .form-control:-ms-input-placeholder, .form-control + label:-ms-input-placeholder {
    color: rgba(117, 122, 124, 0.75);
    opacity: 1; }
  .form-control::-ms-input-placeholder, .form-control + label::-ms-input-placeholder {
    color: rgba(117, 122, 124, 0.75);
    opacity: 1; }
  .form-control::placeholder, .form-control + label::placeholder {
    color: rgba(117, 122, 124, 0.75);
    opacity: 1; }
  .form-control:disabled, .form-control + label:disabled, .form-control[readonly], .form-control + label[readonly] {
    background-color: #D8D9DA;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #314251;
  background-color: rgba(255, 255, 255, 0.85); }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.4rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.6;
  color: #4E5457;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-sm + label.form-control-plaintext, .form-control-plaintext.form-control-lg, .form-control-lg + label.form-control-plaintext {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .form-control-sm + label {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg, .form-control-lg + label {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #898D8F; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4CA325; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #FFF;
  background-color: rgba(76, 163, 37, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .was-validated .form-control + label:valid, .form-control.is-valid, .form-control + label.is-valid {
  border-color: #4CA325;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234CA325' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.4em + 0.1875rem);
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .form-control + label:valid:focus, .form-control.is-valid:focus, .form-control + label.is-valid:focus {
    border-color: #4CA325;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control + label:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .was-validated .form-control + label:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control + label.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  .form-control + label.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #4CA325;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url('data:image/svg+xml;utf8,<svg width="15" height="9" viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg"><path d="M14.532 1.903c.213-.229.575-.929 0-1.463-.575-.533-1.214-.113-1.442.1L7.5 6.108 1.891.44c-.228-.212-.834-.545-1.4 0-.566.546-.213 1.244 0 1.473L7.5 8.799l7.032-6.896z" fill="%2301507B" fill-rule="evenodd"/></svg>') no-repeat right 0.75rem center/12px 8px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234CA325' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") rgba(255, 255, 255, 0.85) no-repeat center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #4CA325;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4CA325; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #4CA325; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #4CA325; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5fcd2e;
  background-color: #5fcd2e; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4CA325; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4CA325; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #4CA325;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #E86B64; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #FFF;
  background-color: rgba(232, 107, 100, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .was-validated .form-control + label:invalid, .form-control.is-invalid, .form-control + label.is-invalid {
  border-color: #E86B64;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E86B64' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23E86B64' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.4em + 0.1875rem);
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .form-control + label:invalid:focus, .form-control.is-invalid:focus, .form-control + label.is-invalid:focus {
    border-color: #E86B64;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control + label:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .was-validated .form-control + label:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control + label.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .form-control + label.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #E86B64;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url('data:image/svg+xml;utf8,<svg width="15" height="9" viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg"><path d="M14.532 1.903c.213-.229.575-.929 0-1.463-.575-.533-1.214-.113-1.442.1L7.5 6.108 1.891.44c-.228-.212-.834-.545-1.4 0-.566.546-.213 1.244 0 1.473L7.5 8.799l7.032-6.896z" fill="%2301507B" fill-rule="evenodd"/></svg>') no-repeat right 0.75rem center/12px 8px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E86B64' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23E86B64' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") rgba(255, 255, 255, 0.85) no-repeat center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #E86B64;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #E86B64; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #E86B64; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #E86B64; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ef9590;
  background-color: #ef9590; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #E86B64; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #E86B64; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #E86B64;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.25); }

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-align: center;
      align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
      -ms-flex-align: center;
          align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .form-control + label {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.form-control-search {
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  pointer-events: none;
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTkuNzkzIDE5LjY4M2wtNC43NDEtNC43MDVhOC43NyA4Ljc3IDAgMCAwIC4yMy0xMi4xNDZBOC44MiA4LjgyIDAgMCAwIDMuMTI5IDIuMDdhOC43NyA4Ljc3IDAgMCAwLTEuMjk3IDEyLjA4YzIuODc4IDMuNzIzIDguMTg0IDQuNTI2IDEyLjA0IDEuODIxbDQuODM0IDQuODIzYS43NzEuNzcxIDAgMCAwIDEuMDY5LS4wMTkuNzY3Ljc2NyAwIDAgMCAuMDE4LTEuMDY2di0uMDI2ek0xLjY3MyA4LjgzNWMwLTMuOTU5IDMuMjE3LTcuMTY4IDcuMTg1LTcuMTY4IDMuOTY4IDAgNy4xODQgMy4yMSA3LjE4NCA3LjE2OHMtMy4yMTYgNy4xNjctNy4xODQgNy4xNjctNy4xODQtMy4yMDktNy4xODQtNy4xNjd6IiBmaWxsPSIjM0M0MTQ1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4K"); }

.form-label-group {
  position: relative;
  margin-bottom: 1rem; }

.form-label-group > input,
.form-label-group > label {
  padding: 0.375rem 0.75rem; }

@media (min-width: 576px) {
  .form-label-group > input,
  .form-label-group > label {
    padding: 0.25rem 0.5rem; } }

@media (min-width: 768px) {
  .form-label-group > input,
  .form-label-group > label {
    padding: 0.375rem 0.75rem; } }

@media (min-width: 992px) {
  .form-label-group > input,
  .form-label-group > label {
    padding: 0.5rem 1rem; } }

.form-control + label, .form-control + label + label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: rgba(117, 122, 124, 0.75);
  background-color: transparent;
  -webkit-transition: all 0.1s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  -o-transition: all 0.1s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  transition: all 0.1s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  pointer-events: none; }

.form-label-group input::-webkit-input-placeholder {
  color: transparent; }

.form-label-group input:-ms-input-placeholder {
  color: transparent; }

.form-label-group input::-ms-input-placeholder {
  color: transparent; }

.form-label-group input::placeholder {
  color: transparent; }

.form-label-group input:not(:placeholder-shown) {
  padding-top: 0.625rem;
  padding-bottom: 0.125rem; }

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  font-size: 12px;
  color: rgba(117, 122, 124, 0.75); }

@media (min-width: 576px) {
  .form-label-group input:not(:placeholder-shown) {
    padding-top: 0.41667rem;
    padding-bottom: 0.08333rem; }
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: 0.08333rem;
    padding-bottom: 0.08333rem; } }

@media (min-width: 768px) {
  .form-label-group input:not(:placeholder-shown) {
    padding-top: 0.625rem;
    padding-bottom: 0.125rem; }
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem; } }

@media (min-width: 992px) {
  .form-label-group input:not(:placeholder-shown) {
    padding-top: 0.83333rem;
    padding-bottom: 0.16667rem; }
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: 0.16667rem;
    padding-bottom: 0.16667rem; } }

.bubble {
  cursor: default;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  line-height: 1.6;
  border-radius: 1rem;
  -webkit-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out, -webkit-box-shadow 0.25s ease-in-out;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out, -webkit-box-shadow 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out, color 0.25s ease-in-out, background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, color 0.25s ease-in-out, background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, color 0.25s ease-in-out, background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out, -webkit-box-shadow 0.25s ease-in-out;
  min-height: -webkit-min-content !important;
  min-height: -moz-min-content !important;
  min-height: min-content !important;
  max-width: 100% !important; }
  .bubble:not(:first-of-type) {
    margin-top: 1rem; }
  @media (prefers-reduced-motion: reduce) {
    .bubble {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .bubble:hover, .bubble:focus {
    text-decoration: none; }
  .bubble p {
    margin: 0;
    line-height: 1.5rem; }
  .bubble span:not(:only-child) img {
    margin-top: 1rem; }

.bubble-block {
  width: 100%; }

.bubble-default, .bubble__tip {
  color: #4E5457;
  background-color: #FFF; }

.content-bottom > *:first-of-type {
  margin-top: auto; }

.content-top > *:first-of-type {
  margin-bottom: auto; }

.bubble-primary, .bubble-primary.bubble__dragging-over, .bubble__info,
.bubble__interactive {
  color: #FFF;
  background-color: #01507B;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-primary:focus, .bubble__info:focus,
  .bubble__interactive:focus, .bubble-primary.focus, .focus.bubble__info,
  .focus.bubble__interactive {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(1, 80, 123, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(1, 80, 123, 0.5); }

.bubble-secondary, .bubble-secondary.bubble__dragging-over {
  color: #4E5457;
  background-color: #CFE676;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-secondary:focus, .bubble-secondary.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(207, 230, 118, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(207, 230, 118, 0.5); }

.bubble-success, .bubble-success.bubble__dragging-over {
  color: #FFF;
  background-color: #4CA325;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-success:focus, .bubble-success.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(76, 163, 37, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(76, 163, 37, 0.5); }

.bubble-info, .bubble-info.bubble__dragging-over {
  color: #4E5457;
  background-color: #8CA2B5;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-info:focus, .bubble-info.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(140, 162, 181, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(140, 162, 181, 0.5); }

.bubble-warning, .bubble-warning.bubble__dragging-over {
  color: #4E5457;
  background-color: #FDBD39;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-warning:focus, .bubble-warning.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(253, 189, 57, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(253, 189, 57, 0.5); }

.bubble-danger, .bubble-danger.bubble__dragging-over {
  color: #FFF;
  background-color: #E86B64;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-danger:focus, .bubble-danger.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(232, 107, 100, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(232, 107, 100, 0.5); }

.bubble-light, .bubble-light.bubble__dragging-over {
  color: #4E5457;
  background-color: #C2CFDB;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-light:focus, .bubble-light.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(194, 207, 219, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(194, 207, 219, 0.5); }

.bubble-dark, .bubble-dark.bubble__dragging-over {
  color: #FFF;
  background-color: #17232C;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-dark:focus, .bubble-dark.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(23, 35, 44, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(23, 35, 44, 0.5); }

.bubble-brainimals-aqua, .bubble-brainimals-aqua.bubble__dragging-over {
  color: #4E5457;
  background-color: #11D1D2;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-aqua:focus, .bubble-brainimals-aqua.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(17, 209, 210, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(17, 209, 210, 0.5); }

.bubble-brainimals-topaz, .bubble-brainimals-topaz.bubble__dragging-over {
  color: #FFF;
  background-color: #0EB0B1;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-topaz:focus, .bubble-brainimals-topaz.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(14, 176, 177, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(14, 176, 177, 0.5); }

.bubble-brainimals-blue-light, .bubble-brainimals-blue-light.bubble__dragging-over {
  color: #4E5457;
  background-color: #6FCEED;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-blue-light:focus, .bubble-brainimals-blue-light.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(111, 206, 237, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(111, 206, 237, 0.5); }

.bubble-brainimals-deep-sea-blue, .bubble-brainimals-deep-sea-blue.bubble__dragging-over {
  color: #FFF;
  background-color: #01507B;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-deep-sea-blue:focus, .bubble-brainimals-deep-sea-blue.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(1, 80, 123, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(1, 80, 123, 0.5); }

.bubble-brainimals-dark-cyan, .bubble-brainimals-dark-cyan.bubble__dragging-over {
  color: #FFF;
  background-color: #0B8E90;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-dark-cyan:focus, .bubble-brainimals-dark-cyan.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(11, 142, 144, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(11, 142, 144, 0.5); }

.bubble-brainimals-darker-cyan, .bubble-brainimals-darker-cyan.bubble__dragging-over {
  color: #FFF;
  background-color: #045E61;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-darker-cyan:focus, .bubble-brainimals-darker-cyan.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(4, 94, 97, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(4, 94, 97, 0.5); }

.bubble-brainimals-green-light, .bubble-brainimals-green-light.bubble__dragging-over {
  color: #4E5457;
  background-color: #CFE676;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-green-light:focus, .bubble-brainimals-green-light.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(207, 230, 118, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(207, 230, 118, 0.5); }

.bubble-brainimals-green, .bubble-brainimals-green.bubble__dragging-over {
  color: #FFF;
  background-color: #4CA325;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-green:focus, .bubble-brainimals-green.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(76, 163, 37, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(76, 163, 37, 0.5); }

.bubble-brainimals-orange, .bubble-brainimals-orange.bubble__dragging-over {
  color: #4E5457;
  background-color: #FDBD39;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-orange:focus, .bubble-brainimals-orange.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(253, 189, 57, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(253, 189, 57, 0.5); }

.bubble-brainimals-red, .bubble-brainimals-red.bubble__dragging-over {
  color: #FFF;
  background-color: #E86B64;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-red:focus, .bubble-brainimals-red.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(232, 107, 100, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(232, 107, 100, 0.5); }

.bubble-brainimals-dark-grey-blue, .bubble-brainimals-dark-grey-blue.bubble__dragging-over {
  color: #FFF;
  background-color: #314251;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-dark-grey-blue:focus, .bubble-brainimals-dark-grey-blue.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(49, 66, 81, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(49, 66, 81, 0.5); }

.bubble-brainimals-pale-grey-two, .bubble-brainimals-pale-grey-two.bubble__dragging-over {
  color: #4E5457;
  background-color: #DEE6EB;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-pale-grey-two:focus, .bubble-brainimals-pale-grey-two.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(222, 230, 235, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(222, 230, 235, 0.5); }

.bubble-brainimals-pale-grey, .bubble-brainimals-pale-grey.bubble__dragging-over {
  color: #4E5457;
  background-color: #EDF2F4;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-pale-grey:focus, .bubble-brainimals-pale-grey.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(237, 242, 244, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(237, 242, 244, 0.5); }

.bubble-brainimals-bluey-grey, .bubble-brainimals-bluey-grey.bubble__dragging-over {
  color: #4E5457;
  background-color: #8CA2B5;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-bluey-grey:focus, .bubble-brainimals-bluey-grey.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(140, 162, 181, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(140, 162, 181, 0.5); }

.bubble-brainimals-cloudy-blue, .bubble-brainimals-cloudy-blue.bubble__dragging-over {
  color: #4E5457;
  background-color: #C2CFDB;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-cloudy-blue:focus, .bubble-brainimals-cloudy-blue.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(194, 207, 219, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(194, 207, 219, 0.5); }

.bubble-brainimals-blue-grey, .bubble-brainimals-blue-grey.bubble__dragging-over {
  color: #FFF;
  background-color: #60768A;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-blue-grey:focus, .bubble-brainimals-blue-grey.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(96, 118, 138, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(96, 118, 138, 0.5); }

.bubble-brainimals-dark, .bubble-brainimals-dark.bubble__dragging-over {
  color: #FFF;
  background-color: #17232C;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .bubble-brainimals-dark:focus, .bubble-brainimals-dark.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(23, 35, 44, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(23, 35, 44, 0.5); }

.bubble .bubble__info-title,
.bubble .bubble__tip-title {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem; }

.bubble-lg {
  padding: 1rem 1rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 1rem; }
  .bubble-lg .bubble__info-title,
  .bubble-lg .bubble__tip-title {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem; }

.bubble-sm {
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1rem; }
  .bubble-sm .bubble__info-title,
  .bubble-sm .bubble__tip-title {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem; }

.bubble img, .bubble-notice img {
  max-height: 32vh;
  max-width: 100%; }

.bubble-help img {
  max-height: 45vh; }

@media (min-width: 576px) {
  .bubble {
    padding: 0.5rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 1rem; } }

@media (min-width: 768px) {
  .bubble {
    padding: 0.75rem 0.75rem;
    font-size: 1.3rem;
    line-height: 1.25;
    border-radius: 1rem; } }

@media (min-width: 992px) {
  .bubble {
    padding: 1rem 1rem;
    font-size: 1.4rem;
    line-height: 1.5;
    border-radius: 1rem; } }

.bubble-block {
  display: block;
  width: 100%; }
  .bubble-block + .bubble-block {
    margin-top: 0.5rem; }

.bubble__dragging-over.bubble-primary, .bubble__dragging-over.bubble__info,
.bubble__dragging-over.bubble__interactive {
  color: #FFF;
  background-color: #013755 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-secondary {
  color: #4E5457;
  background-color: #c4e056 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-success {
  color: #FFF;
  background-color: #3d841e !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-info {
  color: #FFF;
  background-color: #758fa6 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-warning {
  color: #4E5457;
  background-color: #fdb013 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-danger {
  color: #FFF;
  background-color: #e34b43 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-light {
  color: #4E5457;
  background-color: #aabccd !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-dark {
  color: #FFF;
  background-color: #0a0f13 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-aqua {
  color: #FFF;
  background-color: #0eaeaf !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-topaz {
  color: #FFF;
  background-color: #0b8d8e !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-blue-light {
  color: #4E5457;
  background-color: #4dc2e9 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-deep-sea-blue {
  color: #FFF;
  background-color: #013755 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-dark-cyan {
  color: #FFF;
  background-color: #086b6c !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-darker-cyan {
  color: #FFF;
  background-color: #023a3c !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-green-light {
  color: #4E5457;
  background-color: #c4e056 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-green {
  color: #FFF;
  background-color: #3d841e !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-orange {
  color: #4E5457;
  background-color: #fdb013 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-red {
  color: #FFF;
  background-color: #e34b43 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-dark-grey-blue {
  color: #FFF;
  background-color: #232f39 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-pale-grey-two {
  color: #4E5457;
  background-color: #c6d4dd !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-pale-grey {
  color: #4E5457;
  background-color: #d5e1e5 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-bluey-grey {
  color: #FFF;
  background-color: #758fa6 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-cloudy-blue {
  color: #4E5457;
  background-color: #aabccd !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-blue-grey {
  color: #FFF;
  background-color: #506373 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over.bubble-brainimals-dark {
  color: #FFF;
  background-color: #0a0f13 !important;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.bubble__dragging-over {
  -webkit-transform: scale(1.25) !important;
          transform: scale(1.25) !important; }

@media (min-width: 768px) {
  .bubble__dragging-over {
    -webkit-transform: scale(1.5) !important;
            transform: scale(1.5) !important; } }

.bubble__received {
  border-bottom-left-radius: 0px;
  text-align: left; }
  .bubble__received div span:only-child:not([class*="text-"]):not([class*="font-"]), .bubble__received div span:not([class*="text-"]):not([class*="font-"]) {
    display: block;
    margin: 0 !important; }
    .bubble__received div span:only-child:not([class*="text-"]):not([class*="font-"]) img, .bubble__received div span:not([class*="text-"]):not([class*="font-"]) img {
      height: 100% !important;
      width: 100% !important; }

.bubble__tip .bubble__tip-content span:only-child {
  padding: 0 !important;
  margin: 0 !important; }

.bubble__interactive {
  padding: 0.75rem 0.75rem !important; }

@media (min-width: 768px) {
  .bubble__interactive {
    padding: 1.125rem 0.75rem !important; } }

.bubble__sent,
.bubble__interactive {
  border-bottom-right-radius: 0px;
  text-align: right; }

.bubble__info,
.bubble__tip {
  border-bottom-left-radius: 0px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0; }

.bubble__info-title,
.bubble__tip-title {
  display: table;
  width: 100%;
  background-color: #6FCEED;
  color: #01507B;
  margin: 0 0 10px 0 !important;
  white-space: nowrap;
  padding: 0.3rem 1rem;
  font-size: 1rem; }

.bubble__info-content,
.bubble__tip-content {
  padding: 0 1rem;
  text-align: center; }

.bubble__option {
  color: #FFF;
  background-color: #014f7a;
  font-size: 1.5rem;
  margin: 0 5px;
  min-width: 5rem;
  text-align: center;
  border-radius: 10rem; }

.bubble table.table-grid {
  text-align: center;
  width: 100%; }

.bubble table.table-grid > thead {
  color: #6FCEED;
  font-weight: 600 !important; }

.bubble table.table-grid th:first-child {
  border-right: 0.15rem solid #9cddf3;
  border-bottom: 0.15rem solid #9cddf3; }

.bubble table.table-grid th:not(:first-child):not(:last-child) {
  border-right: 0.15rem solid #9cddf3;
  border-bottom: 0.15rem solid #9cddf3;
  border-left: 0.15rem solid #9cddf3; }

.bubble table.table-grid th:last-child {
  border-bottom: 0.15rem solid #9cddf3;
  border-left: 0.15rem solid #9cddf3; }

.bubble table.table-grid td:first-child {
  border-right: 0.15rem solid #9cddf3;
  border-top: 0.15rem solid #9cddf3; }

.bubble table.table-grid td:not(:first-child):not(:last-child) {
  border-right: 0.15rem solid #9cddf3;
  border-top: 0.15rem solid #9cddf3;
  border-left: 0.15rem solid #9cddf3; }

.bubble table.table-grid td:last-child {
  border-top: 0.15rem solid #87f5f5;
  border-left: 0.15rem solid #87f5f5; }

.placeholder {
  cursor: default;
  color: #FFF;
  display: inline;
  padding: 0.25rem;
  z-index: 1090;
  text-align: center;
  -webkit-transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, opacity 250ms ease-in-out;
  -o-transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, opacity 250ms ease-in-out;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, opacity 250ms ease-in-out; }

.bubble__dragging-over .placeholder__bubble {
  -webkit-transform: scale(1.1) !important;
          transform: scale(1.1) !important; }

.bubble span:not([class*="placeholder__underscore"]):not([class*="placeholder__bubble"]):not([class*="font-"]):not([class*="text-"]) {
  margin: auto 10px -10px; }

.bubble span[class*="placeholder__underscore"] {
  margin: auto -1px -10px; }

.placeholder__bubble {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: #01507B;
  border-radius: 2rem;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  padding: 0.375rem 0.76rem !important; }

.placeholder__bubble:empty {
  padding: 0.375rem 1.01rem !important; }

@media (min-width: 768px) {
  .placeholder__bubble {
    padding: 0.375rem 0.825rem !important; }
  .placeholder__bubble:empty {
    padding: 0.375rem 1.185rem !important; } }

@media (min-width: 992px) {
  .placeholder__bubble {
    padding: 0.5rem 1.005rem !important; }
  .placeholder__bubble:empty {
    padding: 0.5rem 1.35rem !important; } }

.bubble__dragging-over .placeholder__bubble {
  background-color: rgba(0, 0, 0, 0.5) !important; }

.placeholder__bubble__danger {
  border-color: #e66c67; }

.placeholder__bubble__success {
  border-color: #ade73f; }

.placeholder__underscore {
  -webkit-animation: pulseUnderscore 400ms alternate infinite;
          animation: pulseUnderscore 400ms alternate infinite;
  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: 0.2rem;
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  padding: 0.25rem 0.35rem !important; }

.placeholder__underscore:empty {
  padding: 0.25rem 0.65rem !important; }

@media (min-width: 576px) {
  .placeholder__underscore {
    padding: 0.25rem 0.375rem !important; }
  .placeholder__underscore:empty {
    padding: 0.25rem 0.65rem !important; } }

@media (min-width: 768px) {
  .placeholder__underscore {
    padding: 0.25rem 0.35rem !important; }
  .placeholder__underscore:empty {
    padding: 0.25rem 0.75rem !important; } }

@media (min-width: 992px) {
  .placeholder__underscore {
    padding: 0.25rem 0.4rem !important; }
  .placeholder__underscore:empty {
    padding: 0.25rem 0.8rem !important; } }

.placeholder__underscore__danger,
.placeholder__underscore__success {
  border-style: solid; }

.placeholder__underscore__danger {
  -webkit-animation: pulseUnderscoreWarning 400ms alternate infinite;
          animation: pulseUnderscoreWarning 400ms alternate infinite;
  border-color: RGBA(230, 108, 103, 1);
  color: RGBA(230, 108, 103, 1); }

.placeholder__underscore__success {
  -webkit-animation: pulseUnderscoreSuccess 400ms alternate infinite;
          animation: pulseUnderscoreSuccess 400ms alternate infinite;
  border-color: RGBA(173, 231, 63, 1);
  color: RGBA(173, 231, 63, 1); }

table .placeholder__underscore__danger,
table .placeholder__underscore__success {
  width: 100%; }

@-webkit-keyframes pulseUnderscore {
  0% {
    border-bottom-color: rgba(255, 255, 255, 0.6); }
  100% {
    border-bottom-color: white; } }

@keyframes pulseUnderscore {
  0% {
    border-bottom-color: rgba(255, 255, 255, 0.6); }
  100% {
    border-bottom-color: white; } }

@-webkit-keyframes pulseUnderscoreWarning {
  0% {
    border-color: RGBA(230, 108, 103, 0.6); }
  100% {
    border-color: RGBA(230, 108, 103, 1); } }

@keyframes pulseUnderscoreWarning {
  0% {
    border-color: RGBA(230, 108, 103, 0.6); }
  100% {
    border-color: RGBA(230, 108, 103, 1); } }

@-webkit-keyframes pulseUnderscoreSuccess {
  0% {
    border-color: RGBA(173, 231, 63, 0.6); }
  100% {
    border-color: RGBA(173, 231, 63, 1); } }

@keyframes pulseUnderscoreSuccess {
  0% {
    border-color: RGBA(173, 231, 63, 0.6); }
  100% {
    border-color: RGBA(173, 231, 63, 1); } }

.btn, .keyboard-button, .keyboard-button__dragging::after, .exercise-card-action {
  display: inline-block;
  font-weight: 500;
  color: #4E5457;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.6;
  border-radius: 3rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .keyboard-button, .keyboard-button__dragging::after, .exercise-card-action {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .btn:hover, .keyboard-button:hover, .keyboard-button__dragging:hover::after, .exercise-card-action:hover {
    color: #4E5457;
    text-decoration: none; }
  .btn:focus, .keyboard-button:focus, .keyboard-button__dragging:focus::after, .exercise-card-action:focus, .btn.focus, .focus.keyboard-button, .focus.keyboard-button__dragging::after, .focus.exercise-card-action {
    outline: 0;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .btn.disabled, .disabled.keyboard-button, .disabled.keyboard-button__dragging::after, .disabled.exercise-card-action, .btn:disabled, .keyboard-button:disabled, .keyboard-button__dragging:disabled::after, .exercise-card-action:disabled {
    opacity: 0.65;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .btn:not(:disabled):not(.disabled):active, .keyboard-button:not(:disabled):not(.disabled):active, .keyboard-button__dragging:not(:disabled):not(.disabled):active::after, .exercise-card-action:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .keyboard-button:not(:disabled):not(.disabled).active, .keyboard-button__dragging:not(:disabled):not(.disabled).active::after, .exercise-card-action:not(:disabled):not(.disabled).active {
    -webkit-box-shadow: none;
            box-shadow: none; }

a.btn.disabled, a.disabled.keyboard-button, a.disabled.keyboard-button__dragging::after, a.disabled.exercise-card-action,
fieldset:disabled a.btn,
fieldset:disabled a.keyboard-button,
fieldset:disabled a.keyboard-button__dragging::after,
fieldset:disabled a.exercise-card-action {
  pointer-events: none; }

.btn-primary, .exercise-card-action {
  color: #FFF;
  background-color: #01507B;
  border-color: #01507B;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-primary:hover, .exercise-card-action:hover {
    color: #FFF;
    background-color: #013755;
    border-color: #012f48; }
  .btn-primary:focus, .exercise-card-action:focus, .focus.btn-primary, .focus.exercise-card-action {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(39, 106, 143, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(39, 106, 143, 0.5); }
  .disabled.btn-primary, .disabled.exercise-card-action, .btn-primary:disabled, .exercise-card-action:disabled {
    color: #FFF;
    background-color: #01507B;
    border-color: #01507B; }
  .btn-primary:not(:disabled):not(.disabled):active, .exercise-card-action:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .exercise-card-action:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-primary,
  .show > .dropdown-toggle.exercise-card-action {
    color: #FFF;
    background-color: #012f48;
    border-color: #00273c; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .exercise-card-action:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .exercise-card-action:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-primary:focus,
    .show > .dropdown-toggle.exercise-card-action:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(39, 106, 143, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(39, 106, 143, 0.5); }

.btn-secondary {
  color: #4E5457;
  background-color: #CFE676;
  border-color: #CFE676;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-secondary:hover {
    color: #4E5457;
    background-color: #c4e056;
    border-color: #c0de4b; }
  .btn-secondary:focus, .focus.btn-secondary {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(188, 208, 113, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(188, 208, 113, 0.5); }
  .disabled.btn-secondary, .btn-secondary:disabled {
    color: #4E5457;
    background-color: #CFE676;
    border-color: #CFE676; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-secondary {
    color: #4E5457;
    background-color: #c0de4b;
    border-color: #bcdc40; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-secondary:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(188, 208, 113, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(188, 208, 113, 0.5); }

.btn-success {
  color: #FFF;
  background-color: #4CA325;
  border-color: #4CA325;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-success:hover {
    color: #FFF;
    background-color: #3d841e;
    border-color: #39791c; }
  .btn-success:focus, .focus.btn-success {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(103, 177, 70, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(103, 177, 70, 0.5); }
  .disabled.btn-success, .btn-success:disabled {
    color: #FFF;
    background-color: #4CA325;
    border-color: #4CA325; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-success {
    color: #FFF;
    background-color: #39791c;
    border-color: #346f19; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-success:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 177, 70, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(103, 177, 70, 0.5); }

.btn-info {
  color: #4E5457;
  background-color: #8CA2B5;
  border-color: #8CA2B5;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-info:hover {
    color: #FFF;
    background-color: #758fa6;
    border-color: #6d89a1; }
  .btn-info:focus, .focus.btn-info {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(131, 150, 167, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(131, 150, 167, 0.5); }
  .disabled.btn-info, .btn-info:disabled {
    color: #4E5457;
    background-color: #8CA2B5;
    border-color: #8CA2B5; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-info {
    color: #FFF;
    background-color: #6d89a1;
    border-color: #65839c; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-info:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(131, 150, 167, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(131, 150, 167, 0.5); }

.btn-warning {
  color: #4E5457;
  background-color: #FDBD39;
  border-color: #FDBD39;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-warning:hover {
    color: #4E5457;
    background-color: #fdb013;
    border-color: #fcac07; }
  .btn-warning:focus, .focus.btn-warning {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(227, 173, 62, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(227, 173, 62, 0.5); }
  .disabled.btn-warning, .btn-warning:disabled {
    color: #4E5457;
    background-color: #FDBD39;
    border-color: #FDBD39; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-warning {
    color: #4E5457;
    background-color: #fcac07;
    border-color: #f4a502; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-warning:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(227, 173, 62, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(227, 173, 62, 0.5); }

.btn-danger {
  color: #FFF;
  background-color: #E86B64;
  border-color: #E86B64;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-danger:hover {
    color: #FFF;
    background-color: #e34b43;
    border-color: #e14138; }
  .btn-danger:focus, .focus.btn-danger {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(235, 129, 123, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(235, 129, 123, 0.5); }
  .disabled.btn-danger, .btn-danger:disabled {
    color: #FFF;
    background-color: #E86B64;
    border-color: #E86B64; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-danger {
    color: #FFF;
    background-color: #e14138;
    border-color: #e0362c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-danger:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 129, 123, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(235, 129, 123, 0.5); }

.btn-light {
  color: #4E5457;
  background-color: #C2CFDB;
  border-color: #C2CFDB;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-light:hover {
    color: #4E5457;
    background-color: #aabccd;
    border-color: #a2b6c8; }
  .btn-light:focus, .focus.btn-light {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(177, 189, 199, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(177, 189, 199, 0.5); }
  .disabled.btn-light, .btn-light:disabled {
    color: #4E5457;
    background-color: #C2CFDB;
    border-color: #C2CFDB; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-light {
    color: #4E5457;
    background-color: #a2b6c8;
    border-color: #9aafc3; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-light:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(177, 189, 199, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(177, 189, 199, 0.5); }

.btn-dark {
  color: #FFF;
  background-color: #17232C;
  border-color: #17232C;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-dark:hover {
    color: #FFF;
    background-color: #0a0f13;
    border-color: #05080b; }
  .btn-dark:focus, .focus.btn-dark {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(58, 68, 76, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(58, 68, 76, 0.5); }
  .disabled.btn-dark, .btn-dark:disabled {
    color: #FFF;
    background-color: #17232C;
    border-color: #17232C; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-dark {
    color: #FFF;
    background-color: #05080b;
    border-color: #010202; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-dark:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 68, 76, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(58, 68, 76, 0.5); }

.btn-brainimals-aqua {
  color: #4E5457;
  background-color: #11D1D2;
  border-color: #11D1D2;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-aqua:hover {
    color: #FFF;
    background-color: #0eaeaf;
    border-color: #0da2a3; }
  .btn-brainimals-aqua:focus, .focus.btn-brainimals-aqua {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(26, 190, 192, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(26, 190, 192, 0.5); }
  .disabled.btn-brainimals-aqua, .btn-brainimals-aqua:disabled {
    color: #4E5457;
    background-color: #11D1D2;
    border-color: #11D1D2; }
  .btn-brainimals-aqua:not(:disabled):not(.disabled):active, .btn-brainimals-aqua:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-aqua {
    color: #FFF;
    background-color: #0da2a3;
    border-color: #0c9697; }
    .btn-brainimals-aqua:not(:disabled):not(.disabled):active:focus, .btn-brainimals-aqua:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-aqua:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(26, 190, 192, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(26, 190, 192, 0.5); }

.btn-brainimals-topaz {
  color: #FFF;
  background-color: #0EB0B1;
  border-color: #0EB0B1;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-topaz:hover {
    color: #FFF;
    background-color: #0b8d8e;
    border-color: #0a8182; }
  .btn-brainimals-topaz:focus, .focus.btn-brainimals-topaz {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(50, 188, 189, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(50, 188, 189, 0.5); }
  .disabled.btn-brainimals-topaz, .btn-brainimals-topaz:disabled {
    color: #FFF;
    background-color: #0EB0B1;
    border-color: #0EB0B1; }
  .btn-brainimals-topaz:not(:disabled):not(.disabled):active, .btn-brainimals-topaz:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-topaz {
    color: #FFF;
    background-color: #0a8182;
    border-color: #097576; }
    .btn-brainimals-topaz:not(:disabled):not(.disabled):active:focus, .btn-brainimals-topaz:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-topaz:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(50, 188, 189, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(50, 188, 189, 0.5); }

.btn-brainimals-blue-light {
  color: #4E5457;
  background-color: #6FCEED;
  border-color: #6FCEED;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-blue-light:hover {
    color: #4E5457;
    background-color: #4dc2e9;
    border-color: #42bfe7; }
  .btn-brainimals-blue-light:focus, .focus.btn-brainimals-blue-light {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(106, 188, 215, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(106, 188, 215, 0.5); }
  .disabled.btn-brainimals-blue-light, .btn-brainimals-blue-light:disabled {
    color: #4E5457;
    background-color: #6FCEED;
    border-color: #6FCEED; }
  .btn-brainimals-blue-light:not(:disabled):not(.disabled):active, .btn-brainimals-blue-light:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-blue-light {
    color: #4E5457;
    background-color: #42bfe7;
    border-color: #36bbe6; }
    .btn-brainimals-blue-light:not(:disabled):not(.disabled):active:focus, .btn-brainimals-blue-light:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-blue-light:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(106, 188, 215, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(106, 188, 215, 0.5); }

.btn-brainimals-deep-sea-blue {
  color: #FFF;
  background-color: #01507B;
  border-color: #01507B;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-deep-sea-blue:hover {
    color: #FFF;
    background-color: #013755;
    border-color: #012f48; }
  .btn-brainimals-deep-sea-blue:focus, .focus.btn-brainimals-deep-sea-blue {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(39, 106, 143, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(39, 106, 143, 0.5); }
  .disabled.btn-brainimals-deep-sea-blue, .btn-brainimals-deep-sea-blue:disabled {
    color: #FFF;
    background-color: #01507B;
    border-color: #01507B; }
  .btn-brainimals-deep-sea-blue:not(:disabled):not(.disabled):active, .btn-brainimals-deep-sea-blue:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-deep-sea-blue {
    color: #FFF;
    background-color: #012f48;
    border-color: #00273c; }
    .btn-brainimals-deep-sea-blue:not(:disabled):not(.disabled):active:focus, .btn-brainimals-deep-sea-blue:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-deep-sea-blue:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(39, 106, 143, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(39, 106, 143, 0.5); }

.btn-brainimals-dark-cyan {
  color: #FFF;
  background-color: #0B8E90;
  border-color: #0B8E90;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-dark-cyan:hover {
    color: #FFF;
    background-color: #086b6c;
    border-color: #075f61; }
  .btn-brainimals-dark-cyan:focus, .focus.btn-brainimals-dark-cyan {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(48, 159, 161, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(48, 159, 161, 0.5); }
  .disabled.btn-brainimals-dark-cyan, .btn-brainimals-dark-cyan:disabled {
    color: #FFF;
    background-color: #0B8E90;
    border-color: #0B8E90; }
  .btn-brainimals-dark-cyan:not(:disabled):not(.disabled):active, .btn-brainimals-dark-cyan:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-dark-cyan {
    color: #FFF;
    background-color: #075f61;
    border-color: #065455; }
    .btn-brainimals-dark-cyan:not(:disabled):not(.disabled):active:focus, .btn-brainimals-dark-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-dark-cyan:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 159, 161, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(48, 159, 161, 0.5); }

.btn-brainimals-darker-cyan {
  color: #FFF;
  background-color: #045E61;
  border-color: #045E61;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-darker-cyan:hover {
    color: #FFF;
    background-color: #023a3c;
    border-color: #022f30; }
  .btn-brainimals-darker-cyan:focus, .focus.btn-brainimals-darker-cyan {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(42, 118, 121, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(42, 118, 121, 0.5); }
  .disabled.btn-brainimals-darker-cyan, .btn-brainimals-darker-cyan:disabled {
    color: #FFF;
    background-color: #045E61;
    border-color: #045E61; }
  .btn-brainimals-darker-cyan:not(:disabled):not(.disabled):active, .btn-brainimals-darker-cyan:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-darker-cyan {
    color: #FFF;
    background-color: #022f30;
    border-color: #012324; }
    .btn-brainimals-darker-cyan:not(:disabled):not(.disabled):active:focus, .btn-brainimals-darker-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-darker-cyan:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(42, 118, 121, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(42, 118, 121, 0.5); }

.btn-brainimals-green-light {
  color: #4E5457;
  background-color: #CFE676;
  border-color: #CFE676;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-green-light:hover {
    color: #4E5457;
    background-color: #c4e056;
    border-color: #c0de4b; }
  .btn-brainimals-green-light:focus, .focus.btn-brainimals-green-light {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(188, 208, 113, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(188, 208, 113, 0.5); }
  .disabled.btn-brainimals-green-light, .btn-brainimals-green-light:disabled {
    color: #4E5457;
    background-color: #CFE676;
    border-color: #CFE676; }
  .btn-brainimals-green-light:not(:disabled):not(.disabled):active, .btn-brainimals-green-light:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-green-light {
    color: #4E5457;
    background-color: #c0de4b;
    border-color: #bcdc40; }
    .btn-brainimals-green-light:not(:disabled):not(.disabled):active:focus, .btn-brainimals-green-light:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-green-light:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(188, 208, 113, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(188, 208, 113, 0.5); }

.btn-brainimals-green {
  color: #FFF;
  background-color: #4CA325;
  border-color: #4CA325;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-green:hover {
    color: #FFF;
    background-color: #3d841e;
    border-color: #39791c; }
  .btn-brainimals-green:focus, .focus.btn-brainimals-green {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(103, 177, 70, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(103, 177, 70, 0.5); }
  .disabled.btn-brainimals-green, .btn-brainimals-green:disabled {
    color: #FFF;
    background-color: #4CA325;
    border-color: #4CA325; }
  .btn-brainimals-green:not(:disabled):not(.disabled):active, .btn-brainimals-green:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-green {
    color: #FFF;
    background-color: #39791c;
    border-color: #346f19; }
    .btn-brainimals-green:not(:disabled):not(.disabled):active:focus, .btn-brainimals-green:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-green:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 177, 70, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(103, 177, 70, 0.5); }

.btn-brainimals-orange {
  color: #4E5457;
  background-color: #FDBD39;
  border-color: #FDBD39;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-orange:hover {
    color: #4E5457;
    background-color: #fdb013;
    border-color: #fcac07; }
  .btn-brainimals-orange:focus, .focus.btn-brainimals-orange {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(227, 173, 62, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(227, 173, 62, 0.5); }
  .disabled.btn-brainimals-orange, .btn-brainimals-orange:disabled {
    color: #4E5457;
    background-color: #FDBD39;
    border-color: #FDBD39; }
  .btn-brainimals-orange:not(:disabled):not(.disabled):active, .btn-brainimals-orange:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-orange {
    color: #4E5457;
    background-color: #fcac07;
    border-color: #f4a502; }
    .btn-brainimals-orange:not(:disabled):not(.disabled):active:focus, .btn-brainimals-orange:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-orange:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(227, 173, 62, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(227, 173, 62, 0.5); }

.btn-brainimals-red {
  color: #FFF;
  background-color: #E86B64;
  border-color: #E86B64;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-red:hover {
    color: #FFF;
    background-color: #e34b43;
    border-color: #e14138; }
  .btn-brainimals-red:focus, .focus.btn-brainimals-red {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(235, 129, 123, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(235, 129, 123, 0.5); }
  .disabled.btn-brainimals-red, .btn-brainimals-red:disabled {
    color: #FFF;
    background-color: #E86B64;
    border-color: #E86B64; }
  .btn-brainimals-red:not(:disabled):not(.disabled):active, .btn-brainimals-red:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-red {
    color: #FFF;
    background-color: #e14138;
    border-color: #e0362c; }
    .btn-brainimals-red:not(:disabled):not(.disabled):active:focus, .btn-brainimals-red:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-red:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 129, 123, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(235, 129, 123, 0.5); }

.btn-brainimals-dark-grey-blue {
  color: #FFF;
  background-color: #314251;
  border-color: #314251;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-dark-grey-blue:hover {
    color: #FFF;
    background-color: #232f39;
    border-color: #1e2831; }
  .btn-brainimals-dark-grey-blue:focus, .focus.btn-brainimals-dark-grey-blue {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(80, 94, 107, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(80, 94, 107, 0.5); }
  .disabled.btn-brainimals-dark-grey-blue, .btn-brainimals-dark-grey-blue:disabled {
    color: #FFF;
    background-color: #314251;
    border-color: #314251; }
  .btn-brainimals-dark-grey-blue:not(:disabled):not(.disabled):active, .btn-brainimals-dark-grey-blue:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-dark-grey-blue {
    color: #FFF;
    background-color: #1e2831;
    border-color: #192229; }
    .btn-brainimals-dark-grey-blue:not(:disabled):not(.disabled):active:focus, .btn-brainimals-dark-grey-blue:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-dark-grey-blue:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 94, 107, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(80, 94, 107, 0.5); }

.btn-brainimals-pale-grey-two {
  color: #4E5457;
  background-color: #DEE6EB;
  border-color: #DEE6EB;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-pale-grey-two:hover {
    color: #4E5457;
    background-color: #c6d4dd;
    border-color: #beced8; }
  .btn-brainimals-pale-grey-two:focus, .focus.btn-brainimals-pale-grey-two {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(200, 208, 213, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(200, 208, 213, 0.5); }
  .disabled.btn-brainimals-pale-grey-two, .btn-brainimals-pale-grey-two:disabled {
    color: #4E5457;
    background-color: #DEE6EB;
    border-color: #DEE6EB; }
  .btn-brainimals-pale-grey-two:not(:disabled):not(.disabled):active, .btn-brainimals-pale-grey-two:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-pale-grey-two {
    color: #4E5457;
    background-color: #beced8;
    border-color: #b6c8d3; }
    .btn-brainimals-pale-grey-two:not(:disabled):not(.disabled):active:focus, .btn-brainimals-pale-grey-two:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-pale-grey-two:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(200, 208, 213, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(200, 208, 213, 0.5); }

.btn-brainimals-pale-grey {
  color: #4E5457;
  background-color: #EDF2F4;
  border-color: #EDF2F4;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-pale-grey:hover {
    color: #4E5457;
    background-color: #d5e1e5;
    border-color: #cddbe1; }
  .btn-brainimals-pale-grey:focus, .focus.btn-brainimals-pale-grey {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(213, 218, 220, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(213, 218, 220, 0.5); }
  .disabled.btn-brainimals-pale-grey, .btn-brainimals-pale-grey:disabled {
    color: #4E5457;
    background-color: #EDF2F4;
    border-color: #EDF2F4; }
  .btn-brainimals-pale-grey:not(:disabled):not(.disabled):active, .btn-brainimals-pale-grey:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-pale-grey {
    color: #4E5457;
    background-color: #cddbe1;
    border-color: #c5d5dc; }
    .btn-brainimals-pale-grey:not(:disabled):not(.disabled):active:focus, .btn-brainimals-pale-grey:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-pale-grey:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(213, 218, 220, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(213, 218, 220, 0.5); }

.btn-brainimals-bluey-grey {
  color: #4E5457;
  background-color: #8CA2B5;
  border-color: #8CA2B5;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-bluey-grey:hover {
    color: #FFF;
    background-color: #758fa6;
    border-color: #6d89a1; }
  .btn-brainimals-bluey-grey:focus, .focus.btn-brainimals-bluey-grey {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(131, 150, 167, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(131, 150, 167, 0.5); }
  .disabled.btn-brainimals-bluey-grey, .btn-brainimals-bluey-grey:disabled {
    color: #4E5457;
    background-color: #8CA2B5;
    border-color: #8CA2B5; }
  .btn-brainimals-bluey-grey:not(:disabled):not(.disabled):active, .btn-brainimals-bluey-grey:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-bluey-grey {
    color: #FFF;
    background-color: #6d89a1;
    border-color: #65839c; }
    .btn-brainimals-bluey-grey:not(:disabled):not(.disabled):active:focus, .btn-brainimals-bluey-grey:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-bluey-grey:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(131, 150, 167, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(131, 150, 167, 0.5); }

.btn-brainimals-cloudy-blue {
  color: #4E5457;
  background-color: #C2CFDB;
  border-color: #C2CFDB;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-cloudy-blue:hover {
    color: #4E5457;
    background-color: #aabccd;
    border-color: #a2b6c8; }
  .btn-brainimals-cloudy-blue:focus, .focus.btn-brainimals-cloudy-blue {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(177, 189, 199, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(177, 189, 199, 0.5); }
  .disabled.btn-brainimals-cloudy-blue, .btn-brainimals-cloudy-blue:disabled {
    color: #4E5457;
    background-color: #C2CFDB;
    border-color: #C2CFDB; }
  .btn-brainimals-cloudy-blue:not(:disabled):not(.disabled):active, .btn-brainimals-cloudy-blue:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-cloudy-blue {
    color: #4E5457;
    background-color: #a2b6c8;
    border-color: #9aafc3; }
    .btn-brainimals-cloudy-blue:not(:disabled):not(.disabled):active:focus, .btn-brainimals-cloudy-blue:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-cloudy-blue:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(177, 189, 199, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(177, 189, 199, 0.5); }

.btn-brainimals-blue-grey {
  color: #FFF;
  background-color: #60768A;
  border-color: #60768A;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-blue-grey:hover {
    color: #FFF;
    background-color: #506373;
    border-color: #4b5c6c; }
  .btn-brainimals-blue-grey:focus, .focus.btn-brainimals-blue-grey {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(120, 139, 156, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(120, 139, 156, 0.5); }
  .disabled.btn-brainimals-blue-grey, .btn-brainimals-blue-grey:disabled {
    color: #FFF;
    background-color: #60768A;
    border-color: #60768A; }
  .btn-brainimals-blue-grey:not(:disabled):not(.disabled):active, .btn-brainimals-blue-grey:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-blue-grey {
    color: #FFF;
    background-color: #4b5c6c;
    border-color: #465664; }
    .btn-brainimals-blue-grey:not(:disabled):not(.disabled):active:focus, .btn-brainimals-blue-grey:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-blue-grey:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(120, 139, 156, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(120, 139, 156, 0.5); }

.btn-brainimals-dark {
  color: #FFF;
  background-color: #17232C;
  border-color: #17232C;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-brainimals-dark:hover {
    color: #FFF;
    background-color: #0a0f13;
    border-color: #05080b; }
  .btn-brainimals-dark:focus, .focus.btn-brainimals-dark {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(58, 68, 76, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(58, 68, 76, 0.5); }
  .disabled.btn-brainimals-dark, .btn-brainimals-dark:disabled {
    color: #FFF;
    background-color: #17232C;
    border-color: #17232C; }
  .btn-brainimals-dark:not(:disabled):not(.disabled):active, .btn-brainimals-dark:not(:disabled):not(.disabled).active,
  .show > .dropdown-toggle.btn-brainimals-dark {
    color: #FFF;
    background-color: #05080b;
    border-color: #010202; }
    .btn-brainimals-dark:not(:disabled):not(.disabled):active:focus, .btn-brainimals-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dropdown-toggle.btn-brainimals-dark:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 68, 76, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(58, 68, 76, 0.5); }

.btn-outline-primary {
  color: #01507B;
  border-color: #01507B; }
  .btn-outline-primary:hover {
    color: #FFF;
    background-color: #01507B;
    border-color: #01507B; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(1, 80, 123, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(1, 80, 123, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #01507B;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFF;
    background-color: #01507B;
    border-color: #01507B; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(1, 80, 123, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(1, 80, 123, 0.5); }

.btn-outline-secondary {
  color: #CFE676;
  border-color: #CFE676; }
  .btn-outline-secondary:hover {
    color: #4E5457;
    background-color: #CFE676;
    border-color: #CFE676; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 230, 118, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(207, 230, 118, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #CFE676;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #4E5457;
    background-color: #CFE676;
    border-color: #CFE676; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 230, 118, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(207, 230, 118, 0.5); }

.btn-outline-success {
  color: #4CA325;
  border-color: #4CA325; }
  .btn-outline-success:hover {
    color: #FFF;
    background-color: #4CA325;
    border-color: #4CA325; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #4CA325;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFF;
    background-color: #4CA325;
    border-color: #4CA325; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.5); }

.btn-outline-info {
  color: #8CA2B5;
  border-color: #8CA2B5; }
  .btn-outline-info:hover {
    color: #4E5457;
    background-color: #8CA2B5;
    border-color: #8CA2B5; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(140, 162, 181, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(140, 162, 181, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #8CA2B5;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #4E5457;
    background-color: #8CA2B5;
    border-color: #8CA2B5; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(140, 162, 181, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(140, 162, 181, 0.5); }

.btn-outline-warning {
  color: #FDBD39;
  border-color: #FDBD39; }
  .btn-outline-warning:hover {
    color: #4E5457;
    background-color: #FDBD39;
    border-color: #FDBD39; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 189, 57, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(253, 189, 57, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FDBD39;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #4E5457;
    background-color: #FDBD39;
    border-color: #FDBD39; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 189, 57, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(253, 189, 57, 0.5); }

.btn-outline-danger {
  color: #E86B64;
  border-color: #E86B64; }
  .btn-outline-danger:hover {
    color: #FFF;
    background-color: #E86B64;
    border-color: #E86B64; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #E86B64;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFF;
    background-color: #E86B64;
    border-color: #E86B64; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.5); }

.btn-outline-light {
  color: #C2CFDB;
  border-color: #C2CFDB; }
  .btn-outline-light:hover {
    color: #4E5457;
    background-color: #C2CFDB;
    border-color: #C2CFDB; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(194, 207, 219, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(194, 207, 219, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #C2CFDB;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #4E5457;
    background-color: #C2CFDB;
    border-color: #C2CFDB; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(194, 207, 219, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(194, 207, 219, 0.5); }

.btn-outline-dark {
  color: #17232C;
  border-color: #17232C; }
  .btn-outline-dark:hover {
    color: #FFF;
    background-color: #17232C;
    border-color: #17232C; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 35, 44, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(23, 35, 44, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #17232C;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFF;
    background-color: #17232C;
    border-color: #17232C; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 35, 44, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(23, 35, 44, 0.5); }

.btn-outline-brainimals-aqua {
  color: #11D1D2;
  border-color: #11D1D2; }
  .btn-outline-brainimals-aqua:hover {
    color: #4E5457;
    background-color: #11D1D2;
    border-color: #11D1D2; }
  .btn-outline-brainimals-aqua:focus, .btn-outline-brainimals-aqua.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(17, 209, 210, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(17, 209, 210, 0.5); }
  .btn-outline-brainimals-aqua.disabled, .btn-outline-brainimals-aqua:disabled {
    color: #11D1D2;
    background-color: transparent; }
  .btn-outline-brainimals-aqua:not(:disabled):not(.disabled):active, .btn-outline-brainimals-aqua:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-aqua.dropdown-toggle {
    color: #4E5457;
    background-color: #11D1D2;
    border-color: #11D1D2; }
    .btn-outline-brainimals-aqua:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-aqua:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-aqua.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(17, 209, 210, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(17, 209, 210, 0.5); }

.btn-outline-brainimals-topaz {
  color: #0EB0B1;
  border-color: #0EB0B1; }
  .btn-outline-brainimals-topaz:hover {
    color: #FFF;
    background-color: #0EB0B1;
    border-color: #0EB0B1; }
  .btn-outline-brainimals-topaz:focus, .btn-outline-brainimals-topaz.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(14, 176, 177, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(14, 176, 177, 0.5); }
  .btn-outline-brainimals-topaz.disabled, .btn-outline-brainimals-topaz:disabled {
    color: #0EB0B1;
    background-color: transparent; }
  .btn-outline-brainimals-topaz:not(:disabled):not(.disabled):active, .btn-outline-brainimals-topaz:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-topaz.dropdown-toggle {
    color: #FFF;
    background-color: #0EB0B1;
    border-color: #0EB0B1; }
    .btn-outline-brainimals-topaz:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-topaz:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-topaz.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(14, 176, 177, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(14, 176, 177, 0.5); }

.btn-outline-brainimals-blue-light {
  color: #6FCEED;
  border-color: #6FCEED; }
  .btn-outline-brainimals-blue-light:hover {
    color: #4E5457;
    background-color: #6FCEED;
    border-color: #6FCEED; }
  .btn-outline-brainimals-blue-light:focus, .btn-outline-brainimals-blue-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 206, 237, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(111, 206, 237, 0.5); }
  .btn-outline-brainimals-blue-light.disabled, .btn-outline-brainimals-blue-light:disabled {
    color: #6FCEED;
    background-color: transparent; }
  .btn-outline-brainimals-blue-light:not(:disabled):not(.disabled):active, .btn-outline-brainimals-blue-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-blue-light.dropdown-toggle {
    color: #4E5457;
    background-color: #6FCEED;
    border-color: #6FCEED; }
    .btn-outline-brainimals-blue-light:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-blue-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-blue-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 206, 237, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(111, 206, 237, 0.5); }

.btn-outline-brainimals-deep-sea-blue {
  color: #01507B;
  border-color: #01507B; }
  .btn-outline-brainimals-deep-sea-blue:hover {
    color: #FFF;
    background-color: #01507B;
    border-color: #01507B; }
  .btn-outline-brainimals-deep-sea-blue:focus, .btn-outline-brainimals-deep-sea-blue.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(1, 80, 123, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(1, 80, 123, 0.5); }
  .btn-outline-brainimals-deep-sea-blue.disabled, .btn-outline-brainimals-deep-sea-blue:disabled {
    color: #01507B;
    background-color: transparent; }
  .btn-outline-brainimals-deep-sea-blue:not(:disabled):not(.disabled):active, .btn-outline-brainimals-deep-sea-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-deep-sea-blue.dropdown-toggle {
    color: #FFF;
    background-color: #01507B;
    border-color: #01507B; }
    .btn-outline-brainimals-deep-sea-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-deep-sea-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-deep-sea-blue.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(1, 80, 123, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(1, 80, 123, 0.5); }

.btn-outline-brainimals-dark-cyan {
  color: #0B8E90;
  border-color: #0B8E90; }
  .btn-outline-brainimals-dark-cyan:hover {
    color: #FFF;
    background-color: #0B8E90;
    border-color: #0B8E90; }
  .btn-outline-brainimals-dark-cyan:focus, .btn-outline-brainimals-dark-cyan.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(11, 142, 144, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(11, 142, 144, 0.5); }
  .btn-outline-brainimals-dark-cyan.disabled, .btn-outline-brainimals-dark-cyan:disabled {
    color: #0B8E90;
    background-color: transparent; }
  .btn-outline-brainimals-dark-cyan:not(:disabled):not(.disabled):active, .btn-outline-brainimals-dark-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-dark-cyan.dropdown-toggle {
    color: #FFF;
    background-color: #0B8E90;
    border-color: #0B8E90; }
    .btn-outline-brainimals-dark-cyan:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-dark-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-dark-cyan.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(11, 142, 144, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(11, 142, 144, 0.5); }

.btn-outline-brainimals-darker-cyan {
  color: #045E61;
  border-color: #045E61; }
  .btn-outline-brainimals-darker-cyan:hover {
    color: #FFF;
    background-color: #045E61;
    border-color: #045E61; }
  .btn-outline-brainimals-darker-cyan:focus, .btn-outline-brainimals-darker-cyan.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(4, 94, 97, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(4, 94, 97, 0.5); }
  .btn-outline-brainimals-darker-cyan.disabled, .btn-outline-brainimals-darker-cyan:disabled {
    color: #045E61;
    background-color: transparent; }
  .btn-outline-brainimals-darker-cyan:not(:disabled):not(.disabled):active, .btn-outline-brainimals-darker-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-darker-cyan.dropdown-toggle {
    color: #FFF;
    background-color: #045E61;
    border-color: #045E61; }
    .btn-outline-brainimals-darker-cyan:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-darker-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-darker-cyan.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(4, 94, 97, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(4, 94, 97, 0.5); }

.btn-outline-brainimals-green-light {
  color: #CFE676;
  border-color: #CFE676; }
  .btn-outline-brainimals-green-light:hover {
    color: #4E5457;
    background-color: #CFE676;
    border-color: #CFE676; }
  .btn-outline-brainimals-green-light:focus, .btn-outline-brainimals-green-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 230, 118, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(207, 230, 118, 0.5); }
  .btn-outline-brainimals-green-light.disabled, .btn-outline-brainimals-green-light:disabled {
    color: #CFE676;
    background-color: transparent; }
  .btn-outline-brainimals-green-light:not(:disabled):not(.disabled):active, .btn-outline-brainimals-green-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-green-light.dropdown-toggle {
    color: #4E5457;
    background-color: #CFE676;
    border-color: #CFE676; }
    .btn-outline-brainimals-green-light:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-green-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-green-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 230, 118, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(207, 230, 118, 0.5); }

.btn-outline-brainimals-green {
  color: #4CA325;
  border-color: #4CA325; }
  .btn-outline-brainimals-green:hover {
    color: #FFF;
    background-color: #4CA325;
    border-color: #4CA325; }
  .btn-outline-brainimals-green:focus, .btn-outline-brainimals-green.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.5); }
  .btn-outline-brainimals-green.disabled, .btn-outline-brainimals-green:disabled {
    color: #4CA325;
    background-color: transparent; }
  .btn-outline-brainimals-green:not(:disabled):not(.disabled):active, .btn-outline-brainimals-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-green.dropdown-toggle {
    color: #FFF;
    background-color: #4CA325;
    border-color: #4CA325; }
    .btn-outline-brainimals-green:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-green.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.5); }

.btn-outline-brainimals-orange {
  color: #FDBD39;
  border-color: #FDBD39; }
  .btn-outline-brainimals-orange:hover {
    color: #4E5457;
    background-color: #FDBD39;
    border-color: #FDBD39; }
  .btn-outline-brainimals-orange:focus, .btn-outline-brainimals-orange.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 189, 57, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(253, 189, 57, 0.5); }
  .btn-outline-brainimals-orange.disabled, .btn-outline-brainimals-orange:disabled {
    color: #FDBD39;
    background-color: transparent; }
  .btn-outline-brainimals-orange:not(:disabled):not(.disabled):active, .btn-outline-brainimals-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-orange.dropdown-toggle {
    color: #4E5457;
    background-color: #FDBD39;
    border-color: #FDBD39; }
    .btn-outline-brainimals-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-orange.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 189, 57, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(253, 189, 57, 0.5); }

.btn-outline-brainimals-red {
  color: #E86B64;
  border-color: #E86B64; }
  .btn-outline-brainimals-red:hover {
    color: #FFF;
    background-color: #E86B64;
    border-color: #E86B64; }
  .btn-outline-brainimals-red:focus, .btn-outline-brainimals-red.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.5); }
  .btn-outline-brainimals-red.disabled, .btn-outline-brainimals-red:disabled {
    color: #E86B64;
    background-color: transparent; }
  .btn-outline-brainimals-red:not(:disabled):not(.disabled):active, .btn-outline-brainimals-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-red.dropdown-toggle {
    color: #FFF;
    background-color: #E86B64;
    border-color: #E86B64; }
    .btn-outline-brainimals-red:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-red.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.5); }

.btn-outline-brainimals-dark-grey-blue {
  color: #314251;
  border-color: #314251; }
  .btn-outline-brainimals-dark-grey-blue:hover {
    color: #FFF;
    background-color: #314251;
    border-color: #314251; }
  .btn-outline-brainimals-dark-grey-blue:focus, .btn-outline-brainimals-dark-grey-blue.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(49, 66, 81, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(49, 66, 81, 0.5); }
  .btn-outline-brainimals-dark-grey-blue.disabled, .btn-outline-brainimals-dark-grey-blue:disabled {
    color: #314251;
    background-color: transparent; }
  .btn-outline-brainimals-dark-grey-blue:not(:disabled):not(.disabled):active, .btn-outline-brainimals-dark-grey-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-dark-grey-blue.dropdown-toggle {
    color: #FFF;
    background-color: #314251;
    border-color: #314251; }
    .btn-outline-brainimals-dark-grey-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-dark-grey-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-dark-grey-blue.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(49, 66, 81, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(49, 66, 81, 0.5); }

.btn-outline-brainimals-pale-grey-two {
  color: #DEE6EB;
  border-color: #DEE6EB; }
  .btn-outline-brainimals-pale-grey-two:hover {
    color: #4E5457;
    background-color: #DEE6EB;
    border-color: #DEE6EB; }
  .btn-outline-brainimals-pale-grey-two:focus, .btn-outline-brainimals-pale-grey-two.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 230, 235, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(222, 230, 235, 0.5); }
  .btn-outline-brainimals-pale-grey-two.disabled, .btn-outline-brainimals-pale-grey-two:disabled {
    color: #DEE6EB;
    background-color: transparent; }
  .btn-outline-brainimals-pale-grey-two:not(:disabled):not(.disabled):active, .btn-outline-brainimals-pale-grey-two:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-pale-grey-two.dropdown-toggle {
    color: #4E5457;
    background-color: #DEE6EB;
    border-color: #DEE6EB; }
    .btn-outline-brainimals-pale-grey-two:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-pale-grey-two:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-pale-grey-two.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 230, 235, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(222, 230, 235, 0.5); }

.btn-outline-brainimals-pale-grey {
  color: #EDF2F4;
  border-color: #EDF2F4; }
  .btn-outline-brainimals-pale-grey:hover {
    color: #4E5457;
    background-color: #EDF2F4;
    border-color: #EDF2F4; }
  .btn-outline-brainimals-pale-grey:focus, .btn-outline-brainimals-pale-grey.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(237, 242, 244, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(237, 242, 244, 0.5); }
  .btn-outline-brainimals-pale-grey.disabled, .btn-outline-brainimals-pale-grey:disabled {
    color: #EDF2F4;
    background-color: transparent; }
  .btn-outline-brainimals-pale-grey:not(:disabled):not(.disabled):active, .btn-outline-brainimals-pale-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-pale-grey.dropdown-toggle {
    color: #4E5457;
    background-color: #EDF2F4;
    border-color: #EDF2F4; }
    .btn-outline-brainimals-pale-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-pale-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-pale-grey.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(237, 242, 244, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(237, 242, 244, 0.5); }

.btn-outline-brainimals-bluey-grey {
  color: #8CA2B5;
  border-color: #8CA2B5; }
  .btn-outline-brainimals-bluey-grey:hover {
    color: #4E5457;
    background-color: #8CA2B5;
    border-color: #8CA2B5; }
  .btn-outline-brainimals-bluey-grey:focus, .btn-outline-brainimals-bluey-grey.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(140, 162, 181, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(140, 162, 181, 0.5); }
  .btn-outline-brainimals-bluey-grey.disabled, .btn-outline-brainimals-bluey-grey:disabled {
    color: #8CA2B5;
    background-color: transparent; }
  .btn-outline-brainimals-bluey-grey:not(:disabled):not(.disabled):active, .btn-outline-brainimals-bluey-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-bluey-grey.dropdown-toggle {
    color: #4E5457;
    background-color: #8CA2B5;
    border-color: #8CA2B5; }
    .btn-outline-brainimals-bluey-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-bluey-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-bluey-grey.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(140, 162, 181, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(140, 162, 181, 0.5); }

.btn-outline-brainimals-cloudy-blue {
  color: #C2CFDB;
  border-color: #C2CFDB; }
  .btn-outline-brainimals-cloudy-blue:hover {
    color: #4E5457;
    background-color: #C2CFDB;
    border-color: #C2CFDB; }
  .btn-outline-brainimals-cloudy-blue:focus, .btn-outline-brainimals-cloudy-blue.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(194, 207, 219, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(194, 207, 219, 0.5); }
  .btn-outline-brainimals-cloudy-blue.disabled, .btn-outline-brainimals-cloudy-blue:disabled {
    color: #C2CFDB;
    background-color: transparent; }
  .btn-outline-brainimals-cloudy-blue:not(:disabled):not(.disabled):active, .btn-outline-brainimals-cloudy-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-cloudy-blue.dropdown-toggle {
    color: #4E5457;
    background-color: #C2CFDB;
    border-color: #C2CFDB; }
    .btn-outline-brainimals-cloudy-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-cloudy-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-cloudy-blue.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(194, 207, 219, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(194, 207, 219, 0.5); }

.btn-outline-brainimals-blue-grey {
  color: #60768A;
  border-color: #60768A; }
  .btn-outline-brainimals-blue-grey:hover {
    color: #FFF;
    background-color: #60768A;
    border-color: #60768A; }
  .btn-outline-brainimals-blue-grey:focus, .btn-outline-brainimals-blue-grey.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(96, 118, 138, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(96, 118, 138, 0.5); }
  .btn-outline-brainimals-blue-grey.disabled, .btn-outline-brainimals-blue-grey:disabled {
    color: #60768A;
    background-color: transparent; }
  .btn-outline-brainimals-blue-grey:not(:disabled):not(.disabled):active, .btn-outline-brainimals-blue-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-blue-grey.dropdown-toggle {
    color: #FFF;
    background-color: #60768A;
    border-color: #60768A; }
    .btn-outline-brainimals-blue-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-blue-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-blue-grey.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(96, 118, 138, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(96, 118, 138, 0.5); }

.btn-outline-brainimals-dark {
  color: #17232C;
  border-color: #17232C; }
  .btn-outline-brainimals-dark:hover {
    color: #FFF;
    background-color: #17232C;
    border-color: #17232C; }
  .btn-outline-brainimals-dark:focus, .btn-outline-brainimals-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 35, 44, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(23, 35, 44, 0.5); }
  .btn-outline-brainimals-dark.disabled, .btn-outline-brainimals-dark:disabled {
    color: #17232C;
    background-color: transparent; }
  .btn-outline-brainimals-dark:not(:disabled):not(.disabled):active, .btn-outline-brainimals-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brainimals-dark.dropdown-toggle {
    color: #FFF;
    background-color: #17232C;
    border-color: #17232C; }
    .btn-outline-brainimals-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-brainimals-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brainimals-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 35, 44, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(23, 35, 44, 0.5); }

.btn-link {
  font-weight: 500;
  color: #01507B;
  text-decoration: none; }
  .btn-link:hover {
    color: #001f2f;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #898D8F;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .keyboard-button, .btn-group-lg > .keyboard-button__dragging::after, .btn-group-lg > .exercise-card-action {
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 9rem; }

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .keyboard-button, .btn-group-sm > .keyboard-button__dragging::after, .btn-group-sm > .exercise-card-action {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.btn, .keyboard-button, .keyboard-button__dragging::after, .exercise-card-action {
  -o-object-fit: contain;
     object-fit: contain;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content; }

.btn-primary, .exercise-card-action {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-secondary {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-success {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-info {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-warning {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-danger {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-light {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-dark {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-aqua {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-topaz {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-blue-light {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-deep-sea-blue {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-dark-cyan {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-darker-cyan {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-green-light {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-green {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-orange {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-red {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-dark-grey-blue {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-pale-grey-two {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-pale-grey {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-bluey-grey {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-cloudy-blue {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-blue-grey {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-brainimals-dark {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-gray-100 {
  color: #4E5457;
  background-color: #EBECEC;
  border-color: #EBECEC;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-gray-100:hover {
    color: #4E5457;
    background-color: #d7d9d9;
    border-color: #d1d3d3; }
  .btn-gray-100:focus, .btn-gray-100.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(211, 213, 214, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(211, 213, 214, 0.5); }
  .btn-gray-100.disabled, .btn-gray-100:disabled {
    color: #4E5457;
    background-color: #EBECEC;
    border-color: #EBECEC; }
  .btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #4E5457;
    background-color: #d1d3d3;
    border-color: #cacdcd; }
    .btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-100.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(211, 213, 214, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(211, 213, 214, 0.5); }

.btn-gray-200 {
  color: #4E5457;
  background-color: #D8D9DA;
  border-color: #D8D9DA;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-gray-200:hover {
    color: #4E5457;
    background-color: #c4c6c7;
    border-color: #bec0c1; }
  .btn-gray-200:focus, .btn-gray-200.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(195, 197, 198, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(195, 197, 198, 0.5); }
  .btn-gray-200.disabled, .btn-gray-200:disabled {
    color: #4E5457;
    background-color: #D8D9DA;
    border-color: #D8D9DA; }
  .btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-gray-200.dropdown-toggle {
    color: #4E5457;
    background-color: #bec0c1;
    border-color: #b7b9bb; }
    .btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-200.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(195, 197, 198, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(195, 197, 198, 0.5); }

.btn-gray-300 {
  color: #4E5457;
  background-color: #C4C6C7;
  border-color: #C4C6C7;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-gray-300:hover {
    color: #4E5457;
    background-color: #b0b3b4;
    border-color: #aaadae; }
  .btn-gray-300:focus, .btn-gray-300.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(178, 181, 182, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(178, 181, 182, 0.5); }
  .btn-gray-300.disabled, .btn-gray-300:disabled {
    color: #4E5457;
    background-color: #C4C6C7;
    border-color: #C4C6C7; }
  .btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-gray-300.dropdown-toggle {
    color: #4E5457;
    background-color: #aaadae;
    border-color: #a3a6a8; }
    .btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-300.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(178, 181, 182, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(178, 181, 182, 0.5); }

.btn-gray-400 {
  color: #4E5457;
  background-color: #B1B3B5;
  border-color: #B1B3B5;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-gray-400:hover {
    color: #4E5457;
    background-color: #9da0a2;
    border-color: #979a9c; }
  .btn-gray-400:focus, .btn-gray-400.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(162, 165, 167, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(162, 165, 167, 0.5); }
  .btn-gray-400.disabled, .btn-gray-400:disabled {
    color: #4E5457;
    background-color: #B1B3B5;
    border-color: #B1B3B5; }
  .btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-gray-400.dropdown-toggle {
    color: #4E5457;
    background-color: #979a9c;
    border-color: #909396; }
    .btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-400.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(162, 165, 167, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(162, 165, 167, 0.5); }

.btn-gray-500 {
  color: #4E5457;
  background-color: #9DA0A2;
  border-color: #9DA0A2;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-gray-500:hover {
    color: #FFF;
    background-color: #898d8f;
    border-color: #838789; }
  .btn-gray-500:focus, .btn-gray-500.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(145, 149, 151, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(145, 149, 151, 0.5); }
  .btn-gray-500.disabled, .btn-gray-500:disabled {
    color: #4E5457;
    background-color: #9DA0A2;
    border-color: #9DA0A2; }
  .btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-gray-500.dropdown-toggle {
    color: #FFF;
    background-color: #838789;
    border-color: #7c8083; }
    .btn-gray-500:not(:disabled):not(.disabled):active:focus, .btn-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-500.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(145, 149, 151, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(145, 149, 151, 0.5); }

.btn-gray-600 {
  color: #FFF;
  background-color: #898D8F;
  border-color: #898D8F;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-gray-600:hover {
    color: #FFF;
    background-color: #767a7c;
    border-color: #707375; }
  .btn-gray-600:focus, .btn-gray-600.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(155, 158, 160, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(155, 158, 160, 0.5); }
  .btn-gray-600.disabled, .btn-gray-600:disabled {
    color: #FFF;
    background-color: #898D8F;
    border-color: #898D8F; }
  .btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-gray-600.dropdown-toggle {
    color: #FFF;
    background-color: #707375;
    border-color: #696d6f; }
    .btn-gray-600:not(:disabled):not(.disabled):active:focus, .btn-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-600.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(155, 158, 160, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(155, 158, 160, 0.5); }

.btn-gray-700 {
  color: #FFF;
  background-color: #757A7C;
  border-color: #757A7C;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-gray-700:hover {
    color: #FFF;
    background-color: #626768;
    border-color: #5c6062; }
  .btn-gray-700:focus, .btn-gray-700.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(138, 142, 144, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(138, 142, 144, 0.5); }
  .btn-gray-700.disabled, .btn-gray-700:disabled {
    color: #FFF;
    background-color: #757A7C;
    border-color: #757A7C; }
  .btn-gray-700:not(:disabled):not(.disabled):active, .btn-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-gray-700.dropdown-toggle {
    color: #FFF;
    background-color: #5c6062;
    border-color: #565a5b; }
    .btn-gray-700:not(:disabled):not(.disabled):active:focus, .btn-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-700.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(138, 142, 144, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(138, 142, 144, 0.5); }

.btn-gray-800 {
  color: #FFF;
  background-color: #62676A;
  border-color: #62676A;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-gray-800:hover {
    color: #FFF;
    background-color: #505456;
    border-color: #4a4d50; }
  .btn-gray-800:focus, .btn-gray-800.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(122, 126, 128, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(122, 126, 128, 0.5); }
  .btn-gray-800.disabled, .btn-gray-800:disabled {
    color: #FFF;
    background-color: #62676A;
    border-color: #62676A; }
  .btn-gray-800:not(:disabled):not(.disabled):active, .btn-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-gray-800.dropdown-toggle {
    color: #FFF;
    background-color: #4a4d50;
    border-color: #434749; }
    .btn-gray-800:not(:disabled):not(.disabled):active:focus, .btn-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-800.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(122, 126, 128, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(122, 126, 128, 0.5); }

.btn-gray-900 {
  color: #FFF;
  background-color: #4E5457;
  border-color: #4E5457;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-gray-900:hover {
    color: #FFF;
    background-color: #3c4143;
    border-color: #363a3c; }
  .btn-gray-900:focus, .btn-gray-900.focus {
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(105, 110, 112, 0.5);
            box-shadow: none, 0 0 0 0.2rem rgba(105, 110, 112, 0.5); }
  .btn-gray-900.disabled, .btn-gray-900:disabled {
    color: #FFF;
    background-color: #4E5457;
    border-color: #4E5457; }
  .btn-gray-900:not(:disabled):not(.disabled):active, .btn-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-gray-900.dropdown-toggle {
    color: #FFF;
    background-color: #363a3c;
    border-color: #303435; }
    .btn-gray-900:not(:disabled):not(.disabled):active:focus, .btn-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-900.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 110, 112, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(105, 110, 112, 0.5); }

.btn-outline-gray-100 {
  color: #EBECEC;
  border-color: #EBECEC; }
  .btn-outline-gray-100:hover {
    color: #4E5457;
    background-color: #EBECEC;
    border-color: #EBECEC; }
  .btn-outline-gray-100:focus, .btn-outline-gray-100.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 236, 236, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(235, 236, 236, 0.5); }
  .btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
    color: #EBECEC;
    background-color: transparent; }
  .btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-100.dropdown-toggle {
    color: #4E5457;
    background-color: #EBECEC;
    border-color: #EBECEC; }
    .btn-outline-gray-100:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-100.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 236, 236, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(235, 236, 236, 0.5); }

.btn-outline-gray-200 {
  color: #D8D9DA;
  border-color: #D8D9DA; }
  .btn-outline-gray-200:hover {
    color: #4E5457;
    background-color: #D8D9DA;
    border-color: #D8D9DA; }
  .btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 218, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(216, 217, 218, 0.5); }
  .btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
    color: #D8D9DA;
    background-color: transparent; }
  .btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-200.dropdown-toggle {
    color: #4E5457;
    background-color: #D8D9DA;
    border-color: #D8D9DA; }
    .btn-outline-gray-200:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-200.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 218, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(216, 217, 218, 0.5); }

.btn-outline-gray-300 {
  color: #C4C6C7;
  border-color: #C4C6C7; }
  .btn-outline-gray-300:hover {
    color: #4E5457;
    background-color: #C4C6C7;
    border-color: #C4C6C7; }
  .btn-outline-gray-300:focus, .btn-outline-gray-300.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(196, 198, 199, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(196, 198, 199, 0.5); }
  .btn-outline-gray-300.disabled, .btn-outline-gray-300:disabled {
    color: #C4C6C7;
    background-color: transparent; }
  .btn-outline-gray-300:not(:disabled):not(.disabled):active, .btn-outline-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-300.dropdown-toggle {
    color: #4E5457;
    background-color: #C4C6C7;
    border-color: #C4C6C7; }
    .btn-outline-gray-300:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-300.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(196, 198, 199, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(196, 198, 199, 0.5); }

.btn-outline-gray-400 {
  color: #B1B3B5;
  border-color: #B1B3B5; }
  .btn-outline-gray-400:hover {
    color: #4E5457;
    background-color: #B1B3B5;
    border-color: #B1B3B5; }
  .btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(177, 179, 181, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(177, 179, 181, 0.5); }
  .btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
    color: #B1B3B5;
    background-color: transparent; }
  .btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-400.dropdown-toggle {
    color: #4E5457;
    background-color: #B1B3B5;
    border-color: #B1B3B5; }
    .btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-400.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(177, 179, 181, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(177, 179, 181, 0.5); }

.btn-outline-gray-500 {
  color: #9DA0A2;
  border-color: #9DA0A2; }
  .btn-outline-gray-500:hover {
    color: #4E5457;
    background-color: #9DA0A2;
    border-color: #9DA0A2; }
  .btn-outline-gray-500:focus, .btn-outline-gray-500.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(157, 160, 162, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(157, 160, 162, 0.5); }
  .btn-outline-gray-500.disabled, .btn-outline-gray-500:disabled {
    color: #9DA0A2;
    background-color: transparent; }
  .btn-outline-gray-500:not(:disabled):not(.disabled):active, .btn-outline-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-500.dropdown-toggle {
    color: #4E5457;
    background-color: #9DA0A2;
    border-color: #9DA0A2; }
    .btn-outline-gray-500:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-500.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(157, 160, 162, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(157, 160, 162, 0.5); }

.btn-outline-gray-600 {
  color: #898D8F;
  border-color: #898D8F; }
  .btn-outline-gray-600:hover {
    color: #FFF;
    background-color: #898D8F;
    border-color: #898D8F; }
  .btn-outline-gray-600:focus, .btn-outline-gray-600.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(137, 141, 143, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(137, 141, 143, 0.5); }
  .btn-outline-gray-600.disabled, .btn-outline-gray-600:disabled {
    color: #898D8F;
    background-color: transparent; }
  .btn-outline-gray-600:not(:disabled):not(.disabled):active, .btn-outline-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-600.dropdown-toggle {
    color: #FFF;
    background-color: #898D8F;
    border-color: #898D8F; }
    .btn-outline-gray-600:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-600.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(137, 141, 143, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(137, 141, 143, 0.5); }

.btn-outline-gray-700 {
  color: #757A7C;
  border-color: #757A7C; }
  .btn-outline-gray-700:hover {
    color: #FFF;
    background-color: #757A7C;
    border-color: #757A7C; }
  .btn-outline-gray-700:focus, .btn-outline-gray-700.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 122, 124, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(117, 122, 124, 0.5); }
  .btn-outline-gray-700.disabled, .btn-outline-gray-700:disabled {
    color: #757A7C;
    background-color: transparent; }
  .btn-outline-gray-700:not(:disabled):not(.disabled):active, .btn-outline-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-700.dropdown-toggle {
    color: #FFF;
    background-color: #757A7C;
    border-color: #757A7C; }
    .btn-outline-gray-700:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-700.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 122, 124, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(117, 122, 124, 0.5); }

.btn-outline-gray-800 {
  color: #62676A;
  border-color: #62676A; }
  .btn-outline-gray-800:hover {
    color: #FFF;
    background-color: #62676A;
    border-color: #62676A; }
  .btn-outline-gray-800:focus, .btn-outline-gray-800.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(98, 103, 106, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(98, 103, 106, 0.5); }
  .btn-outline-gray-800.disabled, .btn-outline-gray-800:disabled {
    color: #62676A;
    background-color: transparent; }
  .btn-outline-gray-800:not(:disabled):not(.disabled):active, .btn-outline-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-800.dropdown-toggle {
    color: #FFF;
    background-color: #62676A;
    border-color: #62676A; }
    .btn-outline-gray-800:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-800.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(98, 103, 106, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(98, 103, 106, 0.5); }

.btn-outline-gray-900 {
  color: #4E5457;
  border-color: #4E5457; }
  .btn-outline-gray-900:hover {
    color: #FFF;
    background-color: #4E5457;
    border-color: #4E5457; }
  .btn-outline-gray-900:focus, .btn-outline-gray-900.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 84, 87, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(78, 84, 87, 0.5); }
  .btn-outline-gray-900.disabled, .btn-outline-gray-900:disabled {
    color: #4E5457;
    background-color: transparent; }
  .btn-outline-gray-900:not(:disabled):not(.disabled):active, .btn-outline-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-900.dropdown-toggle {
    color: #FFF;
    background-color: #4E5457;
    border-color: #4E5457; }
    .btn-outline-gray-900:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-900.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(78, 84, 87, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(78, 84, 87, 0.5); }

.keyboard-button:hover, .keyboard-button__dragging:hover::after {
  cursor: pointer;
  color: white; }

.keyboard-button.disabled, .disabled.keyboard-button__dragging::after {
  cursor: default !important; }

@media (min-width: 768px) {
  .keyboard-button, .keyboard-button__dragging::after {
    padding: 1rem 1rem;
    font-size: 1.4rem;
    line-height: 1.5;
    border-radius: 3rem; }
    .keyboard-button::after, .keyboard-button__dragging::after {
      padding: 1rem 1rem;
      font-size: 1.4rem;
      line-height: 1.5;
      border-radius: 3rem; } }

.keyboard-button, .keyboard-button__dragging::after {
  min-width: 2.4rem;
  -webkit-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  position: relative; }
  .keyboard-button::after, .keyboard-button__dragging::after {
    visibility: hidden;
    pointer-events: none;
    -webkit-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out; }

.keyboard-button__dragging {
  background-color: transparent !important;
  color: transparent !important; }
  .keyboard-button__dragging::after {
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
    color: #FFF !important;
    visibility: visible;
    position: absolute;
    top: -1rem;
    right: 0;
    left: 0;
    content: attr(data-value);
    padding-left: 0;
    padding-right: 0;
    background-color: rgba(1, 80, 123, 0.5) !important;
    -webkit-backdrop-filter: blur(10px) brightness(105%) saturate(150%);
            backdrop-filter: blur(10px) brightness(105%) saturate(150%);
    bottom: 1rem;
    margin-top: -2rem; }

@media (min-width: 768px) {
  .keyboard-button, .keyboard-button__dragging::after {
    min-width: 4.2rem; }
    .keyboard-button::after, .keyboard-button__dragging::after {
      bottom: 1rem;
      margin-top: -2.8rem; }
  .keyboard-button__dragging::after {
    top: -1rem;
    -webkit-transform: scale(1);
            transform: scale(1); } }

.keyboard-button__numpad.keyboard-button__dragging::after {
  border-radius: 3rem; }

.keyboard-button__multiple.keyboard-button__dragging::after  {
  border-radius: 3rem; }

.keyboard-button__multiple {
  color: #FFF;
  background-color: #01507B; }

.keyboard-button__numpad {
  background-color: rgba(1, 80, 123, 0.5); }

.keyboard-button__confirm,
.keyboard-button__erase,
.keyboard-button__erase-floating {
  width: 40px; }
  .keyboard-button__confirm div,
  .keyboard-button__erase div,
  .keyboard-button__erase-floating div {
    height: 100%;
    width: 100%; }
    .keyboard-button__confirm div svg,
    .keyboard-button__erase div svg,
    .keyboard-button__erase-floating div svg {
      height: 100% !important;
      width: 100% !important; }

.keyboard-button__numpad,
.keyboard-button__confirm,
.keyboard-button__erase,
.keyboard-button__erase-floating {
  color: #FFF;
  -o-object-fit: contain;
     object-fit: contain;
  margin: auto;
  padding: 0.45rem;
  border-radius: 100%; }
  .keyboard-button__numpad div svg,
  .keyboard-button__confirm div svg,
  .keyboard-button__erase div svg,
  .keyboard-button__erase-floating div svg {
    height: 80% !important;
    width: 80% !important; }

.keyboard-button__erase.keyboard-button__multiple,
.keyboard-button__erase-floating.keyboard-button__multiple,
.keyboard-button__multiple,
.keyboard-button__numpad,
.keyboard-button__confirm,
.keyboard-button__erase,
.keyboard-button__erase-floating {
  height: 40px;
  min-width: 40px;
  padding: 0.4rem 0.5rem;
  font-size: 1rem; }

@media (min-width: 576px) {
  .keyboard-button__erase.keyboard-button__multiple,
  .keyboard-button__erase-floating.keyboard-button__multiple,
  .keyboard-button__multiple,
  .keyboard-button__numpad,
  .keyboard-button__confirm,
  .keyboard-button__erase,
  .keyboard-button__erase-floating {
    height: 46px;
    min-width: 46px;
    padding: 0.6rem 0.5rem;
    font-size: 0.9rem; }
    .keyboard-button__erase.keyboard-button__multiple div svg,
    .keyboard-button__erase-floating.keyboard-button__multiple div svg,
    .keyboard-button__multiple div svg,
    .keyboard-button__numpad div svg,
    .keyboard-button__confirm div svg,
    .keyboard-button__erase div svg,
    .keyboard-button__erase-floating div svg {
      height: 85% !important;
      width: 85% !important; }
  .keyboard-button__confirm,
  .keyboard-button__erase,
  .keyboard-button__erase-floating {
    width: 46px; } }

@media (min-width: 768px) {
  .keyboard-button__erase.keyboard-button__multiple,
  .keyboard-button__erase-floating.keyboard-button__multiple,
  .keyboard-button__multiple,
  .keyboard-button__numpad,
  .keyboard-button__confirm,
  .keyboard-button__erase,
  .keyboard-button__erase-floating {
    height: 56px;
    min-width: 56px;
    padding: 0.75rem;
    font-size: 1.2rem; }
    .keyboard-button__erase.keyboard-button__multiple div svg,
    .keyboard-button__erase-floating.keyboard-button__multiple div svg,
    .keyboard-button__multiple div svg,
    .keyboard-button__numpad div svg,
    .keyboard-button__confirm div svg,
    .keyboard-button__erase div svg,
    .keyboard-button__erase-floating div svg {
      height: 70% !important;
      width: 70% !important; }
  .keyboard-button__confirm,
  .keyboard-button__erase,
  .keyboard-button__erase-floating {
    width: 56px; } }

.keyboard-button__confirm,
.keyboard-button__erase,
.keyboard-button__erase-floating {
  background-color: rgba(255, 255, 255, 0.15);
  cursor: default; }

.keyboard-button__confirm.keyboard-button__enabled {
  background-color: #CFE676; }
  .keyboard-button__confirm.keyboard-button__enabled, .keyboard-button__confirm.keyboard-button__enabled * {
    cursor: pointer; }

.keyboard-button__erase.keyboard-button__enabled,
.keyboard-button__erase-floating.keyboard-button__enabled {
  background-color: rgba(1, 80, 123, 0.3); }
  .keyboard-button__erase.keyboard-button__enabled, .keyboard-button__erase.keyboard-button__enabled *,
  .keyboard-button__erase-floating.keyboard-button__enabled,
  .keyboard-button__erase-floating.keyboard-button__enabled * {
    cursor: pointer; }

.keyboard-button__erase.keyboard-button__enabled:hover,
.keyboard-button__erase-floating.keyboard-button__enabled:hover {
  background-color: rgba(1, 80, 123, 0.6); }

.keyboard-button__erase.keyboard-button__multiple,
.keyboard-button__erase-floating.keyboard-button__multiple {
  border-radius: 10rem;
  padding: 6px; }

.keyboard-button .injected-svg, .keyboard-button__dragging::after .injected-svg {
  vertical-align: middle; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #4E5457;
  text-align: left;
  list-style: none;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #D8D9DA; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 500;
  color: #4E5457;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #42474a;
    text-decoration: none;
    background-color: #EBECEC; }
  .dropdown-item.active, .dropdown-item:active {
    color: #FFF;
    text-decoration: none;
    background-color: #045E61; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #898D8F;
    pointer-events: none;
    background-color: transparent; }

.show.dropdown-menu {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #898D8F;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #4E5457; }

.dropdown-menu {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .btn-group > .keyboard-button, .btn-group > .keyboard-button__dragging::after, .btn-group > .exercise-card-action,
  .btn-group-vertical > .btn,
  .btn-group-vertical > .keyboard-button,
  .btn-group-vertical > .keyboard-button__dragging::after,
  .btn-group-vertical > .exercise-card-action {
    position: relative;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
    .btn-group > .btn:hover, .btn-group > .keyboard-button:hover, .btn-group > .keyboard-button__dragging:hover::after, .btn-group > .exercise-card-action:hover,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .keyboard-button:hover,
    .btn-group-vertical > .keyboard-button__dragging:hover::after,
    .btn-group-vertical > .exercise-card-action:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .keyboard-button:focus, .btn-group > .keyboard-button__dragging:focus::after, .btn-group > .exercise-card-action:focus, .btn-group > .btn:active, .btn-group > .keyboard-button:active, .btn-group > .keyboard-button__dragging:active::after, .btn-group > .exercise-card-action:active, .btn-group > .btn.active, .btn-group > .active.keyboard-button, .btn-group > .active.keyboard-button__dragging::after, .btn-group > .active.exercise-card-action,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .keyboard-button:focus,
    .btn-group-vertical > .keyboard-button__dragging:focus::after,
    .btn-group-vertical > .exercise-card-action:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .keyboard-button:active,
    .btn-group-vertical > .keyboard-button__dragging:active::after,
    .btn-group-vertical > .exercise-card-action:active,
    .btn-group-vertical > .btn.active,
    .btn-group-vertical > .active.keyboard-button,
    .btn-group-vertical > .active.keyboard-button__dragging::after,
    .btn-group-vertical > .active.exercise-card-action {
      z-index: 1; }

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .btn-group > .keyboard-button:not(:first-child), .btn-group > .keyboard-button__dragging:not(:first-child)::after, .btn-group > .exercise-card-action:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .keyboard-button:not(:last-child):not(.dropdown-toggle), .btn-group > .keyboard-button__dragging:not(:last-child):not(.dropdown-toggle)::after, .btn-group > .exercise-card-action:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .keyboard-button,
.btn-group > .btn-group:not(:last-child) > .keyboard-button__dragging::after,
.btn-group > .btn-group:not(:last-child) > .exercise-card-action {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .btn-group > .keyboard-button:not(:first-child), .btn-group > .keyboard-button__dragging:not(:first-child)::after, .btn-group > .exercise-card-action:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .keyboard-button,
.btn-group > .btn-group:not(:first-child) > .keyboard-button__dragging::after,
.btn-group > .btn-group:not(:first-child) > .exercise-card-action {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .keyboard-button + .dropdown-toggle-split, .btn-group-sm > .keyboard-button__dragging::after + .dropdown-toggle-split, .btn-group-sm > .exercise-card-action + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .keyboard-button + .dropdown-toggle-split, .btn-group-lg > .keyboard-button__dragging::after + .dropdown-toggle-split, .btn-group-lg > .exercise-card-action + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-group.show .dropdown-toggle.btn-link {
    -webkit-box-shadow: none;
            box-shadow: none; }

.btn-group-vertical {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center; }
  
  .btn-group-vertical > .btn,
  .btn-group-vertical > .keyboard-button,
  .btn-group-vertical > .keyboard-button__dragging::after,
  .btn-group-vertical > .exercise-card-action,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .keyboard-button:not(:first-child), .btn-group-vertical > .keyboard-button__dragging:not(:first-child)::after, .btn-group-vertical > .exercise-card-action:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .keyboard-button:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .keyboard-button__dragging:not(:last-child):not(.dropdown-toggle)::after, .btn-group-vertical > .exercise-card-action:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .btn-group:not(:last-child) > .keyboard-button,
  .btn-group-vertical > .btn-group:not(:last-child) > .keyboard-button__dragging::after,
  .btn-group-vertical > .btn-group:not(:last-child) > .exercise-card-action {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .keyboard-button:not(:first-child), .btn-group-vertical > .keyboard-button__dragging:not(:first-child)::after, .btn-group-vertical > .exercise-card-action:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .keyboard-button,
  .btn-group-vertical > .btn-group:not(:first-child) > .keyboard-button__dragging::after,
  .btn-group-vertical > .btn-group:not(:first-child) > .exercise-card-action {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .btn-group-toggle > .keyboard-button, .btn-group-toggle > .keyboard-button__dragging::after, .btn-group-toggle > .exercise-card-action,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .btn-group > .keyboard-button,
.btn-group-toggle > .btn-group > .keyboard-button__dragging::after,
.btn-group-toggle > .btn-group > .exercise-card-action {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .keyboard-button input[type="radio"], .btn-group-toggle > .keyboard-button__dragging::after input[type="radio"], .btn-group-toggle > .exercise-card-action input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .keyboard-button input[type="checkbox"],
  .btn-group-toggle > .keyboard-button__dragging::after input[type="checkbox"],
  .btn-group-toggle > .exercise-card-action input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .keyboard-button input[type="radio"],
  .btn-group-toggle > .btn-group > .keyboard-button__dragging::after input[type="radio"],
  .btn-group-toggle > .btn-group > .exercise-card-action input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .keyboard-button input[type="checkbox"],
  .btn-group-toggle > .btn-group > .keyboard-button__dragging::after input[type="checkbox"],
  .btn-group-toggle > .btn-group > .exercise-card-action input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.btn-group {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
  border-radius: 0.25rem; }
  .btn-group .btn, .btn-group .keyboard-button, .btn-group .keyboard-button__dragging::after, .btn-group .exercise-card-action {
    -webkit-box-shadow: none;
            box-shadow: none; }
  .btn-group.btn-group-lg {
    border-radius: 0.3rem; }
  .btn-group.btn-group-sm {
    border-radius: 0.2rem; }

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .input-group > .form-control + label,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .input-group > .form-control + label + .form-control, .input-group > .form-control + label, .input-group > .form-control + label.form-control + label,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + label + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control + label + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control.form-control-plaintext + label,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .form-control.custom-select + label,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .form-control.custom-file + label,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .input-group > .form-control + label:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child), .input-group > .form-control + label:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .input-group > .form-control + label:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .keyboard-button, .input-group-prepend .keyboard-button__dragging::after, .input-group-prepend .exercise-card-action,
  .input-group-append .btn,
  .input-group-append .keyboard-button,
  .input-group-append .keyboard-button__dragging::after,
  .input-group-append .exercise-card-action {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus, .input-group-prepend .keyboard-button:focus, .input-group-prepend .keyboard-button__dragging:focus::after, .input-group-prepend .exercise-card-action:focus,
    .input-group-append .btn:focus,
    .input-group-append .keyboard-button:focus,
    .input-group-append .keyboard-button__dragging:focus::after,
    .input-group-append .exercise-card-action:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .keyboard-button + .btn, .input-group-prepend .keyboard-button__dragging::after + .btn, .input-group-prepend .exercise-card-action + .btn, .input-group-prepend .btn + .keyboard-button, .input-group-prepend .keyboard-button + .keyboard-button, .input-group-prepend .keyboard-button__dragging::after + .keyboard-button, .input-group-prepend .exercise-card-action + .keyboard-button, .input-group-prepend .btn + .keyboard-button__dragging::after, .input-group-prepend .keyboard-button + .keyboard-button__dragging::after, .input-group-prepend .keyboard-button__dragging::after + .keyboard-button__dragging::after, .input-group-prepend .exercise-card-action + .keyboard-button__dragging::after, .input-group-prepend .btn + .exercise-card-action, .input-group-prepend .keyboard-button + .exercise-card-action, .input-group-prepend .keyboard-button__dragging::after + .exercise-card-action, .input-group-prepend .exercise-card-action + .exercise-card-action,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .keyboard-button + .input-group-text,
  .input-group-prepend .keyboard-button__dragging::after + .input-group-text,
  .input-group-prepend .exercise-card-action + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .keyboard-button,
  .input-group-prepend .input-group-text + .keyboard-button__dragging::after,
  .input-group-prepend .input-group-text + .exercise-card-action,
  .input-group-append .btn + .btn,
  .input-group-append .keyboard-button + .btn,
  .input-group-append .keyboard-button__dragging::after + .btn,
  .input-group-append .exercise-card-action + .btn,
  .input-group-append .btn + .keyboard-button,
  .input-group-append .keyboard-button + .keyboard-button,
  .input-group-append .keyboard-button__dragging::after + .keyboard-button,
  .input-group-append .exercise-card-action + .keyboard-button,
  .input-group-append .btn + .keyboard-button__dragging::after,
  .input-group-append .keyboard-button + .keyboard-button__dragging::after,
  .input-group-append .keyboard-button__dragging::after + .keyboard-button__dragging::after,
  .input-group-append .exercise-card-action + .keyboard-button__dragging::after,
  .input-group-append .btn + .exercise-card-action,
  .input-group-append .keyboard-button + .exercise-card-action,
  .input-group-append .keyboard-button__dragging::after + .exercise-card-action,
  .input-group-append .exercise-card-action + .exercise-card-action,
  .input-group-append .btn + .input-group-text,
  .input-group-append .keyboard-button + .input-group-text,
  .input-group-append .keyboard-button__dragging::after + .input-group-text,
  .input-group-append .exercise-card-action + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .keyboard-button,
  .input-group-append .input-group-text + .keyboard-button__dragging::after,
  .input-group-append .input-group-text + .exercise-card-action {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  color: #314251;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(1, 80, 123, 0.25);
  border: 1px solid rgba(1, 80, 123, 0.25);
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .input-group-lg > .form-control + label:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control, .input-group-lg > .form-control + label,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .keyboard-button,
.input-group-lg > .input-group-prepend > .keyboard-button__dragging::after,
.input-group-lg > .input-group-prepend > .exercise-card-action,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .keyboard-button,
.input-group-lg > .input-group-append > .keyboard-button__dragging::after,
.input-group-lg > .input-group-append > .exercise-card-action {
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea), .input-group-sm > .form-control + label:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control, .input-group-sm > .form-control + label,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .keyboard-button,
.input-group-sm > .input-group-prepend > .keyboard-button__dragging::after,
.input-group-sm > .input-group-prepend > .exercise-card-action,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .keyboard-button,
.input-group-sm > .input-group-append > .keyboard-button__dragging::after,
.input-group-sm > .input-group-append > .exercise-card-action {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .keyboard-button, .input-group > .input-group-prepend > .keyboard-button__dragging::after, .input-group > .input-group-prepend > .exercise-card-action,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .keyboard-button,
.input-group > .input-group-append:not(:last-child) > .keyboard-button__dragging::after,
.input-group > .input-group-append:not(:last-child) > .exercise-card-action,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .keyboard-button:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .keyboard-button__dragging:not(:last-child):not(.dropdown-toggle)::after,
.input-group > .input-group-append:last-child > .exercise-card-action:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .keyboard-button, .input-group > .input-group-append > .keyboard-button__dragging::after, .input-group > .input-group-append > .exercise-card-action,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .keyboard-button,
.input-group > .input-group-prepend:not(:first-child) > .keyboard-button__dragging::after,
.input-group > .input-group-prepend:not(:first-child) > .exercise-card-action,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .keyboard-button:not(:first-child),
.input-group > .input-group-prepend:first-child > .keyboard-button__dragging:not(:first-child)::after,
.input-group > .input-group-prepend:first-child > .exercise-card-action:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group-text {
  border: none; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFF;
    border-color: #045E61;
    background-color: #045E61;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: none, none;
            box-shadow: none, none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #07a5aa; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #FFF;
    background-color: #22eff6;
    border-color: #22eff6;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #898D8F; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #D8D9DA; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: rgba(255, 255, 255, 0.85);
    border: #9DA0A2 solid 1px;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .custom-control-label::after {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.2rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,<svg width='11' height='9' viewBox='0 0 10 8' xmlns='http://www.w3.org/2000/svg'><path d='M3.574 8.6L.288 5.337l1.03-1.023 2.256 2.203L9.391.883l1.03 1.06z' fill='%23FFF' fill-rule='evenodd'/></svg>"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #045E61;
  background-color: #045E61;
  -webkit-box-shadow: none;
          box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFF' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(1, 80, 123, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(1, 80, 123, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(1, 80, 123, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.3rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #9DA0A2;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: rgba(255, 255, 255, 0.85);
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(1, 80, 123, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  color: #314251;
  vertical-align: middle;
  background: url('data:image/svg+xml;utf8,<svg width="15" height="9" viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg"><path d="M14.532 1.903c.213-.229.575-.929 0-1.463-.575-.533-1.214-.113-1.442.1L7.5 6.108 1.891.44c-.228-.212-.834-.545-1.4 0-.566.546-.213 1.244 0 1.473L7.5 8.799l7.032-6.896z" fill="%2301507B" fill-rule="evenodd"/></svg>') no-repeat right 0.75rem center/12px 8px;
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(11, 142, 144, 0.15);
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #07a5aa;
    outline: 0;
    -webkit-box-shadow: none, 0 0 0 0.2rem rgba(4, 94, 97, 0.25);
            box-shadow: none, 0 0 0 0.2rem rgba(4, 94, 97, 0.25); }
    .custom-select:focus::-ms-value {
      color: #314251;
      background-color: rgba(255, 255, 255, 0.85); }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #898D8F;
    background-color: #D8D9DA; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.4rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #07a5aa;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #D8D9DA; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 500;
  line-height: 1.6;
  color: #314251;
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(11, 142, 144, 0.15);
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.6em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.6;
    color: #314251;
    content: "Browse";
    background-color: rgba(1, 80, 123, 0.25);
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      -webkit-box-shadow: 0 0 0 1px #FFF, none;
              box-shadow: 0 0 0 1px #FFF, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFF, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFF, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #045E61;
    border: 0;
    border-radius: 1rem;
    -webkit-box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
            box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #22eff6; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #C4C6C7;
    border-color: transparent;
    border-radius: 1rem;
    -webkit-box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
            box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #045E61;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #22eff6; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #C4C6C7;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #045E61;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #22eff6; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #C4C6C7;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #C4C6C7;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #9DA0A2; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #9DA0A2; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #9DA0A2; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.custom-checkbox .custom-control-label::after,
.custom-radio .custom-control-label::after {
  border: 1px solid rgba(0, 0, 0, 0.15); }

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #898D8F;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #C4C6C7; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #D8D9DA #D8D9DA #C4C6C7; }
    .nav-tabs .nav-link.disabled {
      color: #898D8F;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #757A7C;
    background-color: #FFF;
    border-color: #C4C6C7 #C4C6C7 #FFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFF;
  background-color: #045E61; }

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.18rem;
  padding-bottom: 0.18rem;
  margin-right: 1rem;
  font-size: 1.4rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-align: center;
      align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.4rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFF; }

canvas {
  -webkit-transform: translateZ(-2px);
          transform: translateZ(-2px);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

.navbar {
  padding: 1rem; }

.navbar-shadow {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.navbar-column {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
  position: fixed;
  left: 0;
  z-index: 1090;
  height: 100vh;
  margin: -1rem;
  background-color: #FFF;
  -webkit-transition: left 200ms ease-in-out;
  -o-transition: left 200ms ease-in-out;
  transition: left 200ms ease-in-out; }
  .navbar-column.navbar-column-open {
    left: 100%; }
  .navbar-column .list-group-item:not(.active) .list-group-item-description-hover {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .navbar-column .list-group-item:not(.active) .list-group-item-description-hover {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .navbar-column .list-group-item:not(.active):hover .list-group-item-description-hover {
    max-height: 200px;
    overflow: hidden;
    opacity: 1;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transition-delay: 400ms;
         -o-transition-delay: 400ms;
            transition-delay: 400ms; }
    @media (prefers-reduced-motion: reduce) {
      .navbar-column .list-group-item:not(.active):hover .list-group-item-description-hover {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }

.navbar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1080;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition: background-color 200ms ease-in-out;
  -o-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  -webkit-transition-delay: 250ms;
       -o-transition-delay: 250ms;
          transition-delay: 250ms;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .navbar-backdrop.navbar-backdrop-open {
    pointer-events: all;
    background-color: rgba(0, 0, 0, 0.2); }

.navbar-column {
  width: 100vw;
  margin-left: -100%; }

@media (min-width: 576px) {
  .navbar-column {
    width: 300px;
    margin-left: -100%; } }

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .card-deck .card {
    margin-bottom: 0.5rem; }
  @media (min-width: 576px) {
    .card-deck {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem; }
      .card-deck .card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0;
            flex: 1 0;
        -ms-flex-direction: column;
            flex-direction: column;
        margin-right: 0.5rem;
        margin-bottom: 0;
        margin-left: 0.5rem; } }

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .card-group > .card {
    margin-bottom: 0.5rem; }
  @media (min-width: 576px) {
    .card-group {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
      .card-group > .card {
        -ms-flex: 1 0;
            flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.exercise-card {
  position: relative;
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
  -webkit-transform: translateZ(3rem);
          transform: translateZ(3rem);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  display: block;
  margin: 0 auto;
  z-index: 1;
  background-color: #01507B;
  min-height: 360px;
  min-width: 250px;
  max-width: 250px; }
  .exercise-card__finished {
    background-color: #01466c; }
  .exercise-card, .exercise-card::after, .exercise-card::before, .exercise-card svg {
    border-radius: 20px; }
  .exercise-card .js-tilt-glare {
    border-radius: 20px;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d; }
  .exercise-card__disabled {
    -webkit-filter: contrast(1) brightness(0.7);
            filter: contrast(1) brightness(0.7); }
    .exercise-card__disabled .exercise-card-subtitle, .exercise-card__disabled .exercise-card-title {
      opacity: 0.5; }
    .exercise-card__disabled svg {
      opacity: 0.6; }
  .exercise-card .exercise-card-badge {
    position: absolute;
    height: 2rem;
    width: 2rem;
    top: 1rem;
    left: 1rem;
    -webkit-transform: translateZ(0.5rem);
            transform: translateZ(0.5rem); }
    .exercise-card .exercise-card-badge * {
      height: 100%;
      width: 100%; }
  .exercise-card .exercise-card-subtitle {
    position: absolute;
    font-size: 1rem;
    -webkit-transform: translateZ(1.5rem);
            transform: translateZ(1.5rem);
    top: 1.5rem;
    margin: 0 10%;
    width: 80%; }
  .exercise-card .exercise-card-title {
    position: absolute;
    font-size: 1.5rem;
    -webkit-transform: translateZ(3rem);
            transform: translateZ(3rem);
    top: 3.5rem;
    margin: 0 10%;
    width: 80%; }
  .exercise-card .exercise-card-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }
    .exercise-card .exercise-card-cover *, .exercise-card .exercise-card-cover svg {
      height: 100%;
      width: 100%; }

.exercise-card-bg-element {
  position: absolute; }

.exercise-card .exercise-card-bg-symbol {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1; }

.exercise-card .exercise-card-bg-symbol svg {
  height: 100%;
  width: auto;
  margin-top: 15px; }

.exercise-card-action {
  background-color: #0171ae;
  border: 0;
  font-size: 1.5rem;
  position: absolute;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  line-height: 2rem;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  padding: 0.5rem 0.5rem 0.1rem 0.5rem;
  margin: 0;
  border-radius: 0 0 20px 20px;
  pointer-events: none; }
  .exercise-card-action .exercise-card-action-icon {
    display: inline-block;
    height: 1.75rem;
    width: 1.75rem;
    margin-right: 0.5rem; }
    .exercise-card-action .exercise-card-action-icon * {
      height: 100%;
      width: 100%; }
    .exercise-card-action .exercise-card-action-icon svg {
      margin-top: -0.3rem; }

.text-secondary {
  padding-top: 1rem;
  font-size: 1.5rem; }

.exercise-card__exercise {
  color: #FFF; }

@media (min-width: 576px) {
  .exercise-card {
    min-height: 420px;
    min-width: 392px;
    max-width: 392px; }
    .exercise-card .exercise-card-badge {
      height: 2.5rem;
      width: 2.5rem; }
    .exercise-card .exercise-card-subtitle {
      top: 2rem;
      font-size: 1.25rem; }
    .exercise-card .exercise-card-title {
      top: 4rem;
      font-size: 1.75rem; }
    .exercise-card .text-secondary {
      padding-top: 1rem;
      font-size: 1.5rem; } }

@media (min-width: 768px) {
  .exercise-card {
    min-height: 520px;
    min-width: 360px;
    max-width: 360px; }
    .exercise-card .exercise-card-badge {
      height: 3rem;
      width: 3rem; }
    .exercise-card .exercise-card-subtitle {
      top: 2rem;
      font-size: 1.5rem; }
    .exercise-card .exercise-card-title {
      top: 5rem;
      font-size: 2rem; }
    .exercise-card .text-secondary {
      padding-top: 2rem;
      font-size: 2rem; } }

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #C2CFDB;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #7290ac;
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxMCAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+SWNvbiAtIENsb3NlPC90aXRsZT48cGF0aCBkPSJNMS41NSAwTDAgMS41NWw2LjE0MyA2LjE0MkwwIDEzLjgzNWwxLjU1IDEuNTUgNy42OTItNy42OTN6IiBmaWxsPSIjODE4QTkxIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=); }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.active.breadcrumb-item {
  color: #314251; }

.breadcrumb-item + .breadcrumb-item::before {
  position: relative;
  bottom: -3px; }

.breadcrumb-item + .active {
  font-weight: 600; }

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #01507B;
  background-color: #FFF;
  border: 1px solid #C4C6C7; }
  .page-link:hover {
    z-index: 2;
    color: #001f2f;
    text-decoration: none;
    background-color: #D8D9DA;
    border-color: #C4C6C7; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    -webkit-box-shadow: none;
            box-shadow: none; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFF;
  background-color: #045E61;
  border-color: #045E61; }

.page-item.disabled .page-link {
  color: #898D8F;
  pointer-events: none;
  cursor: auto;
  background-color: #FFF;
  border-color: #C4C6C7; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.4rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge, .keyboard-button .badge, .keyboard-button__dragging::after .badge, .exercise-card-action .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFF;
  background-color: #01507B; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFF;
    background-color: #012f48; }
  a.badge-primary:focus, a.focus.badge-primary {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(1, 80, 123, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(1, 80, 123, 0.5); }

.badge-secondary {
  color: #4E5457;
  background-color: #CFE676; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #4E5457;
    background-color: #c0de4b; }
  a.badge-secondary:focus, a.focus.badge-secondary {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 230, 118, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(207, 230, 118, 0.5); }

.badge-success {
  color: #FFF;
  background-color: #4CA325; }
  a.badge-success:hover, a.badge-success:focus {
    color: #FFF;
    background-color: #39791c; }
  a.badge-success:focus, a.focus.badge-success {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.5); }

.badge-info {
  color: #4E5457;
  background-color: #8CA2B5; }
  a.badge-info:hover, a.badge-info:focus {
    color: #4E5457;
    background-color: #6d89a1; }
  a.badge-info:focus, a.focus.badge-info {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(140, 162, 181, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(140, 162, 181, 0.5); }

.badge-warning {
  color: #4E5457;
  background-color: #FDBD39; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #4E5457;
    background-color: #fcac07; }
  a.badge-warning:focus, a.focus.badge-warning {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 189, 57, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(253, 189, 57, 0.5); }

.badge-danger {
  color: #FFF;
  background-color: #E86B64; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFF;
    background-color: #e14138; }
  a.badge-danger:focus, a.focus.badge-danger {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.5); }

.badge-light {
  color: #4E5457;
  background-color: #C2CFDB; }
  a.badge-light:hover, a.badge-light:focus {
    color: #4E5457;
    background-color: #a2b6c8; }
  a.badge-light:focus, a.focus.badge-light {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(194, 207, 219, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(194, 207, 219, 0.5); }

.badge-dark {
  color: #FFF;
  background-color: #17232C; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #FFF;
    background-color: #05080b; }
  a.badge-dark:focus, a.focus.badge-dark {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 35, 44, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(23, 35, 44, 0.5); }

.badge-brainimals-aqua {
  color: #4E5457;
  background-color: #11D1D2; }
  a.badge-brainimals-aqua:hover, a.badge-brainimals-aqua:focus {
    color: #4E5457;
    background-color: #0da2a3; }
  a.badge-brainimals-aqua:focus, a.focus.badge-brainimals-aqua {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(17, 209, 210, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(17, 209, 210, 0.5); }

.badge-brainimals-topaz {
  color: #FFF;
  background-color: #0EB0B1; }
  a.badge-brainimals-topaz:hover, a.badge-brainimals-topaz:focus {
    color: #FFF;
    background-color: #0a8182; }
  a.badge-brainimals-topaz:focus, a.focus.badge-brainimals-topaz {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(14, 176, 177, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(14, 176, 177, 0.5); }

.badge-brainimals-blue-light {
  color: #4E5457;
  background-color: #6FCEED; }
  a.badge-brainimals-blue-light:hover, a.badge-brainimals-blue-light:focus {
    color: #4E5457;
    background-color: #42bfe7; }
  a.badge-brainimals-blue-light:focus, a.focus.badge-brainimals-blue-light {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 206, 237, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(111, 206, 237, 0.5); }

.badge-brainimals-deep-sea-blue {
  color: #FFF;
  background-color: #01507B; }
  a.badge-brainimals-deep-sea-blue:hover, a.badge-brainimals-deep-sea-blue:focus {
    color: #FFF;
    background-color: #012f48; }
  a.badge-brainimals-deep-sea-blue:focus, a.focus.badge-brainimals-deep-sea-blue {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(1, 80, 123, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(1, 80, 123, 0.5); }

.badge-brainimals-dark-cyan {
  color: #FFF;
  background-color: #0B8E90; }
  a.badge-brainimals-dark-cyan:hover, a.badge-brainimals-dark-cyan:focus {
    color: #FFF;
    background-color: #075f61; }
  a.badge-brainimals-dark-cyan:focus, a.focus.badge-brainimals-dark-cyan {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(11, 142, 144, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(11, 142, 144, 0.5); }

.badge-brainimals-darker-cyan {
  color: #FFF;
  background-color: #045E61; }
  a.badge-brainimals-darker-cyan:hover, a.badge-brainimals-darker-cyan:focus {
    color: #FFF;
    background-color: #022f30; }
  a.badge-brainimals-darker-cyan:focus, a.focus.badge-brainimals-darker-cyan {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(4, 94, 97, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(4, 94, 97, 0.5); }

.badge-brainimals-green-light {
  color: #4E5457;
  background-color: #CFE676; }
  a.badge-brainimals-green-light:hover, a.badge-brainimals-green-light:focus {
    color: #4E5457;
    background-color: #c0de4b; }
  a.badge-brainimals-green-light:focus, a.focus.badge-brainimals-green-light {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 230, 118, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(207, 230, 118, 0.5); }

.badge-brainimals-green {
  color: #FFF;
  background-color: #4CA325; }
  a.badge-brainimals-green:hover, a.badge-brainimals-green:focus {
    color: #FFF;
    background-color: #39791c; }
  a.badge-brainimals-green:focus, a.focus.badge-brainimals-green {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(76, 163, 37, 0.5); }

.badge-brainimals-orange {
  color: #4E5457;
  background-color: #FDBD39; }
  a.badge-brainimals-orange:hover, a.badge-brainimals-orange:focus {
    color: #4E5457;
    background-color: #fcac07; }
  a.badge-brainimals-orange:focus, a.focus.badge-brainimals-orange {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 189, 57, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(253, 189, 57, 0.5); }

.badge-brainimals-red {
  color: #FFF;
  background-color: #E86B64; }
  a.badge-brainimals-red:hover, a.badge-brainimals-red:focus {
    color: #FFF;
    background-color: #e14138; }
  a.badge-brainimals-red:focus, a.focus.badge-brainimals-red {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(232, 107, 100, 0.5); }

.badge-brainimals-dark-grey-blue {
  color: #FFF;
  background-color: #314251; }
  a.badge-brainimals-dark-grey-blue:hover, a.badge-brainimals-dark-grey-blue:focus {
    color: #FFF;
    background-color: #1e2831; }
  a.badge-brainimals-dark-grey-blue:focus, a.focus.badge-brainimals-dark-grey-blue {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(49, 66, 81, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(49, 66, 81, 0.5); }

.badge-brainimals-pale-grey-two {
  color: #4E5457;
  background-color: #DEE6EB; }
  a.badge-brainimals-pale-grey-two:hover, a.badge-brainimals-pale-grey-two:focus {
    color: #4E5457;
    background-color: #beced8; }
  a.badge-brainimals-pale-grey-two:focus, a.focus.badge-brainimals-pale-grey-two {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 230, 235, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(222, 230, 235, 0.5); }

.badge-brainimals-pale-grey {
  color: #4E5457;
  background-color: #EDF2F4; }
  a.badge-brainimals-pale-grey:hover, a.badge-brainimals-pale-grey:focus {
    color: #4E5457;
    background-color: #cddbe1; }
  a.badge-brainimals-pale-grey:focus, a.focus.badge-brainimals-pale-grey {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(237, 242, 244, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(237, 242, 244, 0.5); }

.badge-brainimals-bluey-grey {
  color: #4E5457;
  background-color: #8CA2B5; }
  a.badge-brainimals-bluey-grey:hover, a.badge-brainimals-bluey-grey:focus {
    color: #4E5457;
    background-color: #6d89a1; }
  a.badge-brainimals-bluey-grey:focus, a.focus.badge-brainimals-bluey-grey {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(140, 162, 181, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(140, 162, 181, 0.5); }

.badge-brainimals-cloudy-blue {
  color: #4E5457;
  background-color: #C2CFDB; }
  a.badge-brainimals-cloudy-blue:hover, a.badge-brainimals-cloudy-blue:focus {
    color: #4E5457;
    background-color: #a2b6c8; }
  a.badge-brainimals-cloudy-blue:focus, a.focus.badge-brainimals-cloudy-blue {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(194, 207, 219, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(194, 207, 219, 0.5); }

.badge-brainimals-blue-grey {
  color: #FFF;
  background-color: #60768A; }
  a.badge-brainimals-blue-grey:hover, a.badge-brainimals-blue-grey:focus {
    color: #FFF;
    background-color: #4b5c6c; }
  a.badge-brainimals-blue-grey:focus, a.focus.badge-brainimals-blue-grey {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(96, 118, 138, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(96, 118, 138, 0.5); }

.badge-brainimals-dark {
  color: #FFF;
  background-color: #17232C; }
  a.badge-brainimals-dark:hover, a.badge-brainimals-dark:focus {
    color: #FFF;
    background-color: #05080b; }
  a.badge-brainimals-dark:focus, a.focus.badge-brainimals-dark {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 35, 44, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(23, 35, 44, 0.5); }

.badge {
  cursor: default; }

.btn .badge, .keyboard-button .badge, .keyboard-button__dragging::after .badge, .exercise-card-action .badge {
  cursor: inherit; }

.badge-primary {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-secondary {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-success {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-info {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-warning {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-danger {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-light {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-dark {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-aqua {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-topaz {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-blue-light {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-deep-sea-blue {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-dark-cyan {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-darker-cyan {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-green-light {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-green {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-orange {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-red {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-dark-grey-blue {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-pale-grey-two {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-pale-grey {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-bluey-grey {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-cloudy-blue {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-blue-grey {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.badge-brainimals-dark {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.1), 0 4px 7px 0 rgba(49, 66, 81, 0.06), 0 0 0.15rem rgba(49, 66, 81, 0.16), 0 0.5rem 1rem rgba(49, 66, 81, 0.2); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #D8D9DA;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #012a40;
  background-color: #7ba4ba;
  border-color: #5288a5; }
  .alert-primary hr {
    border-top-color: #4a7a94; }
  .alert-primary .alert-link {
    color: #00090e; }

.alert-secondary {
  color: #6c783d;
  background-color: #e6f2b8;
  border-color: #deeea2; }
  .alert-secondary hr {
    border-top-color: #d6ea8c; }
  .alert-secondary .alert-link {
    color: #4e562c; }

.alert-success {
  color: #285513;
  background-color: #a2cf8e;
  border-color: #85c06b; }
  .alert-success hr {
    border-top-color: #76b859; }
  .alert-success .alert-link {
    color: #142b0a; }

.alert-info {
  color: #49545e;
  background-color: #c3cfd9;
  border-color: #b1c0cd; }
  .alert-info hr {
    border-top-color: #a1b3c3; }
  .alert-info .alert-link {
    color: #333a41; }

.alert-warning {
  color: #84621e;
  background-color: #fedd98;
  border-color: #fed278; }
  .alert-warning hr {
    border-top-color: #feca5f; }
  .alert-warning .alert-link {
    color: #5a4315; }

.alert-danger {
  color: #793834;
  background-color: #f3b2ae;
  border-color: #ef9a96; }
  .alert-danger hr {
    border-top-color: #ec8580; }
  .alert-danger .alert-link {
    color: #552725; }

.alert-light {
  color: #656c72;
  background-color: #dfe6ec;
  border-color: #d6dee7; }
  .alert-light hr {
    border-top-color: #c6d1de; }
  .alert-light .alert-link {
    color: #4d5257; }

.alert-dark {
  color: #0c1217;
  background-color: #868d91;
  border-color: #616970; }
  .alert-dark hr {
    border-top-color: #555c62; }
  .alert-dark .alert-link {
    color: black; }

.alert-brainimals-aqua {
  color: #096d6d;
  background-color: #83e7e8;
  border-color: #5de0e0; }
  .alert-brainimals-aqua hr {
    border-top-color: #48dcdc; }
  .alert-brainimals-aqua .alert-link {
    color: #053e3e; }

.alert-brainimals-topaz {
  color: #075c5c;
  background-color: #82d6d6;
  border-color: #5bc9ca; }
  .alert-brainimals-topaz hr {
    border-top-color: #48c3c4; }
  .alert-brainimals-topaz .alert-link {
    color: #032d2d; }

.alert-brainimals-blue-light {
  color: #3a6b7b;
  background-color: #b4e6f6;
  border-color: #9ddef3; }
  .alert-brainimals-blue-light hr {
    border-top-color: #86d6f0; }
  .alert-brainimals-blue-light .alert-link {
    color: #2a4d58; }

.alert-brainimals-deep-sea-blue {
  color: #012a40;
  background-color: #7ba4ba;
  border-color: #5288a5; }
  .alert-brainimals-deep-sea-blue hr {
    border-top-color: #4a7a94; }
  .alert-brainimals-deep-sea-blue .alert-link {
    color: #00090e; }

.alert-brainimals-dark-cyan {
  color: #064a4b;
  background-color: #80c4c5;
  border-color: #59b2b4; }
  .alert-brainimals-dark-cyan hr {
    border-top-color: #4ca6a8; }
  .alert-brainimals-dark-cyan .alert-link {
    color: #021b1c; }

.alert-brainimals-darker-cyan {
  color: #023132;
  background-color: #7cabad;
  border-color: #549294; }
  .alert-brainimals-darker-cyan hr {
    border-top-color: #4b8284; }
  .alert-brainimals-darker-cyan .alert-link {
    color: #000101; }

.alert-brainimals-green-light {
  color: #6c783d;
  background-color: #e6f2b8;
  border-color: #deeea2; }
  .alert-brainimals-green-light hr {
    border-top-color: #d6ea8c; }
  .alert-brainimals-green-light .alert-link {
    color: #4e562c; }

.alert-brainimals-green {
  color: #285513;
  background-color: #a2cf8e;
  border-color: #85c06b; }
  .alert-brainimals-green hr {
    border-top-color: #76b859; }
  .alert-brainimals-green .alert-link {
    color: #142b0a; }

.alert-brainimals-orange {
  color: #84621e;
  background-color: #fedd98;
  border-color: #fed278; }
  .alert-brainimals-orange hr {
    border-top-color: #feca5f; }
  .alert-brainimals-orange .alert-link {
    color: #5a4315; }

.alert-brainimals-red {
  color: #793834;
  background-color: #f3b2ae;
  border-color: #ef9a96; }
  .alert-brainimals-red hr {
    border-top-color: #ec8580; }
  .alert-brainimals-red .alert-link {
    color: #552725; }

.alert-brainimals-dark-grey-blue {
  color: #19222a;
  background-color: #949da5;
  border-color: #737e89; }
  .alert-brainimals-dark-grey-blue hr {
    border-top-color: #67717b; }
  .alert-brainimals-dark-grey-blue .alert-link {
    color: #06080a; }

.alert-brainimals-pale-grey-two {
  color: #73787a;
  background-color: #eef2f5;
  border-color: #e9eef1; }
  .alert-brainimals-pale-grey-two hr {
    border-top-color: #d9e2e7; }
  .alert-brainimals-pale-grey-two .alert-link {
    color: #5a5e60; }

.alert-brainimals-pale-grey {
  color: #7b7e7f;
  background-color: #f6f8f9;
  border-color: #f3f6f8; }
  .alert-brainimals-pale-grey hr {
    border-top-color: #e3eaef; }
  .alert-brainimals-pale-grey .alert-link {
    color: #626465; }

.alert-brainimals-bluey-grey {
  color: #49545e;
  background-color: #c3cfd9;
  border-color: #b1c0cd; }
  .alert-brainimals-bluey-grey hr {
    border-top-color: #a1b3c3; }
  .alert-brainimals-bluey-grey .alert-link {
    color: #333a41; }

.alert-brainimals-cloudy-blue {
  color: #656c72;
  background-color: #dfe6ec;
  border-color: #d6dee7; }
  .alert-brainimals-cloudy-blue hr {
    border-top-color: #c6d1de; }
  .alert-brainimals-cloudy-blue .alert-link {
    color: #4d5257; }

.alert-brainimals-blue-grey {
  color: #323d48;
  background-color: #acb8c2;
  border-color: #93a2af; }
  .alert-brainimals-blue-grey hr {
    border-top-color: #8495a4; }
  .alert-brainimals-blue-grey .alert-link {
    color: #1d242a; }

.alert-brainimals-dark {
  color: #0c1217;
  background-color: #868d91;
  border-color: #616970; }
  .alert-brainimals-dark hr {
    border-top-color: #555c62; }
  .alert-brainimals-dark .alert-link {
    color: black; }

.alert-primary {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-secondary {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-success {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-info {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-warning {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-danger {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-light {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-dark {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-aqua {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-topaz {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-blue-light {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-deep-sea-blue {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-dark-cyan {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-darker-cyan {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-green-light {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-green {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-orange {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-red {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-dark-grey-blue {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-pale-grey-two {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-pale-grey {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-bluey-grey {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-cloudy-blue {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-blue-grey {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.alert-brainimals-dark {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #FFF;
  border-radius: 0.25rem;
  -webkit-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1); }

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  color: #FFF;
  text-align: center;
  white-space: nowrap;
  background-color: #01507B;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.progress-indicator {
  margin-top: 1.5rem; }

.progress-indicator {
  margin-top: 1rem; }

@media (min-width: 768px) {
  .progress-indicator {
    margin-top: 2rem; } }

@media (min-width: 768px) {
  .progress-indicator {
    margin-top: 2.5rem; } }

.progress-indicator-icon {
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform .6s ease;
  transition: -webkit-transform .6s ease;
  -o-transition: transform .6s ease;
  transition: transform .6s ease;
  transition: transform .6s ease, -webkit-transform .6s ease;
  -webkit-transition-delay: 60ms;
       -o-transition-delay: 60ms;
          transition-delay: 60ms; }
  .progress-indicator-icon > div {
    height: 32px;
    width: 32px; }
    .progress-indicator-icon > div * {
      height: 100%;
      width: 100%; }

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start; }

.media-body {
  -ms-flex: 1 1;
      flex: 1 1; }

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #757A7C;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #757A7C;
    text-decoration: none;
    background-color: #EBECEC; }
  .list-group-item-action:active {
    color: #4E5457;
    background-color: #D8D9DA; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .disabled.list-group-item, .list-group-item:disabled {
    color: #898D8F;
    pointer-events: none;
    background-color: #FFF; }
  .active.list-group-item {
    z-index: 2;
    color: #FFF;
    background-color: #045E61;
    border-color: #045E61; }

.list-group-horizontal {
  -ms-flex-direction: row;
      flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
        flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
        flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
        flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
        flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #012a40;
  background-color: #b8ceda; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #012a40;
    background-color: #a7c2d1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFF;
    background-color: #012a40;
    border-color: #012a40; }

.list-group-item-secondary {
  color: #6c783d;
  background-color: #f2f8d9; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #6c783d;
    background-color: #ebf4c3; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFF;
    background-color: #6c783d;
    border-color: #6c783d; }

.list-group-item-success {
  color: #285513;
  background-color: #cde5c2; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #285513;
    background-color: #beddb0; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFF;
    background-color: #285513;
    border-color: #285513; }

.list-group-item-info {
  color: #49545e;
  background-color: #dfe5ea; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #49545e;
    background-color: #d0d8e0; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFF;
    background-color: #49545e;
    border-color: #49545e; }

.list-group-item-warning {
  color: #84621e;
  background-color: #feedc8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #84621e;
    background-color: #fee5af; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFF;
    background-color: #84621e;
    border-color: #84621e; }

.list-group-item-danger {
  color: #793834;
  background-color: #f9d6d4; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #793834;
    background-color: #f6c1be; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFF;
    background-color: #793834;
    border-color: #793834; }

.list-group-item-light {
  color: #656c72;
  background-color: #eef2f5; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #656c72;
    background-color: #dee6ec; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFF;
    background-color: #656c72;
    border-color: #656c72; }

.list-group-item-dark {
  color: #0c1217;
  background-color: #bec1c4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0c1217;
    background-color: #b1b4b8; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFF;
    background-color: #0c1217;
    border-color: #0c1217; }

.list-group-item-brainimals-aqua {
  color: #096d6d;
  background-color: #bcf2f2; }
  .list-group-item-brainimals-aqua.list-group-item-action:hover, .list-group-item-brainimals-aqua.list-group-item-action:focus {
    color: #096d6d;
    background-color: #a7eeee; }
  .list-group-item-brainimals-aqua.list-group-item-action.active {
    color: #FFF;
    background-color: #096d6d;
    border-color: #096d6d; }

.list-group-item-brainimals-topaz {
  color: #075c5c;
  background-color: #bce9e9; }
  .list-group-item-brainimals-topaz.list-group-item-action:hover, .list-group-item-brainimals-topaz.list-group-item-action:focus {
    color: #075c5c;
    background-color: #a9e3e3; }
  .list-group-item-brainimals-topaz.list-group-item-action.active {
    color: #FFF;
    background-color: #075c5c;
    border-color: #075c5c; }

.list-group-item-brainimals-blue-light {
  color: #3a6b7b;
  background-color: #d7f1fa; }
  .list-group-item-brainimals-blue-light.list-group-item-action:hover, .list-group-item-brainimals-blue-light.list-group-item-action:focus {
    color: #3a6b7b;
    background-color: #c0e9f7; }
  .list-group-item-brainimals-blue-light.list-group-item-action.active {
    color: #FFF;
    background-color: #3a6b7b;
    border-color: #3a6b7b; }

.list-group-item-brainimals-deep-sea-blue {
  color: #012a40;
  background-color: #b8ceda; }
  .list-group-item-brainimals-deep-sea-blue.list-group-item-action:hover, .list-group-item-brainimals-deep-sea-blue.list-group-item-action:focus {
    color: #012a40;
    background-color: #a7c2d1; }
  .list-group-item-brainimals-deep-sea-blue.list-group-item-action.active {
    color: #FFF;
    background-color: #012a40;
    border-color: #012a40; }

.list-group-item-brainimals-dark-cyan {
  color: #064a4b;
  background-color: #bbdfe0; }
  .list-group-item-brainimals-dark-cyan.list-group-item-action:hover, .list-group-item-brainimals-dark-cyan.list-group-item-action:focus {
    color: #064a4b;
    background-color: #a9d7d8; }
  .list-group-item-brainimals-dark-cyan.list-group-item-action.active {
    color: #FFF;
    background-color: #064a4b;
    border-color: #064a4b; }

.list-group-item-brainimals-darker-cyan {
  color: #023132;
  background-color: #b9d2d3; }
  .list-group-item-brainimals-darker-cyan.list-group-item-action:hover, .list-group-item-brainimals-darker-cyan.list-group-item-action:focus {
    color: #023132;
    background-color: #a9c8c9; }
  .list-group-item-brainimals-darker-cyan.list-group-item-action.active {
    color: #FFF;
    background-color: #023132;
    border-color: #023132; }

.list-group-item-brainimals-green-light {
  color: #6c783d;
  background-color: #f2f8d9; }
  .list-group-item-brainimals-green-light.list-group-item-action:hover, .list-group-item-brainimals-green-light.list-group-item-action:focus {
    color: #6c783d;
    background-color: #ebf4c3; }
  .list-group-item-brainimals-green-light.list-group-item-action.active {
    color: #FFF;
    background-color: #6c783d;
    border-color: #6c783d; }

.list-group-item-brainimals-green {
  color: #285513;
  background-color: #cde5c2; }
  .list-group-item-brainimals-green.list-group-item-action:hover, .list-group-item-brainimals-green.list-group-item-action:focus {
    color: #285513;
    background-color: #beddb0; }
  .list-group-item-brainimals-green.list-group-item-action.active {
    color: #FFF;
    background-color: #285513;
    border-color: #285513; }

.list-group-item-brainimals-orange {
  color: #84621e;
  background-color: #feedc8; }
  .list-group-item-brainimals-orange.list-group-item-action:hover, .list-group-item-brainimals-orange.list-group-item-action:focus {
    color: #84621e;
    background-color: #fee5af; }
  .list-group-item-brainimals-orange.list-group-item-action.active {
    color: #FFF;
    background-color: #84621e;
    border-color: #84621e; }

.list-group-item-brainimals-red {
  color: #793834;
  background-color: #f9d6d4; }
  .list-group-item-brainimals-red.list-group-item-action:hover, .list-group-item-brainimals-red.list-group-item-action:focus {
    color: #793834;
    background-color: #f6c1be; }
  .list-group-item-brainimals-red.list-group-item-action.active {
    color: #FFF;
    background-color: #793834;
    border-color: #793834; }

.list-group-item-brainimals-dark-grey-blue {
  color: #19222a;
  background-color: #c5cace; }
  .list-group-item-brainimals-dark-grey-blue.list-group-item-action:hover, .list-group-item-brainimals-dark-grey-blue.list-group-item-action:focus {
    color: #19222a;
    background-color: #b7bdc2; }
  .list-group-item-brainimals-dark-grey-blue.list-group-item-action.active {
    color: #FFF;
    background-color: #19222a;
    border-color: #19222a; }

.list-group-item-brainimals-pale-grey-two {
  color: #73787a;
  background-color: #f6f8f9; }
  .list-group-item-brainimals-pale-grey-two.list-group-item-action:hover, .list-group-item-brainimals-pale-grey-two.list-group-item-action:focus {
    color: #73787a;
    background-color: #e7ecef; }
  .list-group-item-brainimals-pale-grey-two.list-group-item-action.active {
    color: #FFF;
    background-color: #73787a;
    border-color: #73787a; }

.list-group-item-brainimals-pale-grey {
  color: #7b7e7f;
  background-color: #fafbfc; }
  .list-group-item-brainimals-pale-grey.list-group-item-action:hover, .list-group-item-brainimals-pale-grey.list-group-item-action:focus {
    color: #7b7e7f;
    background-color: #eaeef2; }
  .list-group-item-brainimals-pale-grey.list-group-item-action.active {
    color: #FFF;
    background-color: #7b7e7f;
    border-color: #7b7e7f; }

.list-group-item-brainimals-bluey-grey {
  color: #49545e;
  background-color: #dfe5ea; }
  .list-group-item-brainimals-bluey-grey.list-group-item-action:hover, .list-group-item-brainimals-bluey-grey.list-group-item-action:focus {
    color: #49545e;
    background-color: #d0d8e0; }
  .list-group-item-brainimals-bluey-grey.list-group-item-action.active {
    color: #FFF;
    background-color: #49545e;
    border-color: #49545e; }

.list-group-item-brainimals-cloudy-blue {
  color: #656c72;
  background-color: #eef2f5; }
  .list-group-item-brainimals-cloudy-blue.list-group-item-action:hover, .list-group-item-brainimals-cloudy-blue.list-group-item-action:focus {
    color: #656c72;
    background-color: #dee6ec; }
  .list-group-item-brainimals-cloudy-blue.list-group-item-action.active {
    color: #FFF;
    background-color: #656c72;
    border-color: #656c72; }

.list-group-item-brainimals-blue-grey {
  color: #323d48;
  background-color: #d2d9de; }
  .list-group-item-brainimals-blue-grey.list-group-item-action:hover, .list-group-item-brainimals-blue-grey.list-group-item-action:focus {
    color: #323d48;
    background-color: #c3cdd3; }
  .list-group-item-brainimals-blue-grey.list-group-item-action.active {
    color: #FFF;
    background-color: #323d48;
    border-color: #323d48; }

.list-group-item-brainimals-dark {
  color: #0c1217;
  background-color: #bec1c4; }
  .list-group-item-brainimals-dark.list-group-item-action:hover, .list-group-item-brainimals-dark.list-group-item-action:focus {
    color: #0c1217;
    background-color: #b1b4b8; }
  .list-group-item-brainimals-dark.list-group-item-action.active {
    color: #FFF;
    background-color: #0c1217;
    border-color: #0c1217; }

.list-group-item-variant-primary {
  color: #31b5fd;
  background-color: transparent; }
  .list-group-item-variant-primary.list-group-item-variant-action:hover, .list-group-item-variant-primary.list-group-item-variant-action:focus {
    color: #31b5fd;
    background-color: rgba(1, 80, 123, 0.2); }
  .list-group-item-variant-primary.list-group-item-variant-action.active {
    color: #31b5fd;
    background-color: #01507B;
    border-color: #01507B; }

.list-group-item-variant-secondary {
  color: #778f1a;
  background-color: transparent; }
  .list-group-item-variant-secondary.list-group-item-variant-action:hover, .list-group-item-variant-secondary.list-group-item-variant-action:focus {
    color: #778f1a;
    background-color: rgba(207, 230, 118, 0.2); }
  .list-group-item-variant-secondary.list-group-item-variant-action.active {
    color: #778f1a;
    background-color: #CFE676;
    border-color: #CFE676; }

.list-group-item-variant-success {
  color: #ade794;
  background-color: transparent; }
  .list-group-item-variant-success.list-group-item-variant-action:hover, .list-group-item-variant-success.list-group-item-variant-action:focus {
    color: #ade794;
    background-color: rgba(76, 163, 37, 0.2); }
  .list-group-item-variant-success.list-group-item-variant-action.active {
    color: #ade794;
    background-color: #4CA325;
    border-color: #4CA325; }

.list-group-item-variant-info {
  color: #384857;
  background-color: transparent; }
  .list-group-item-variant-info.list-group-item-variant-action:hover, .list-group-item-variant-info.list-group-item-variant-action:focus {
    color: #384857;
    background-color: rgba(140, 162, 181, 0.2); }
  .list-group-item-variant-info.list-group-item-variant-action.active {
    color: #384857;
    background-color: #8CA2B5;
    border-color: #8CA2B5; }

.list-group-item-variant-warning {
  color: #825801;
  background-color: transparent; }
  .list-group-item-variant-warning.list-group-item-variant-action:hover, .list-group-item-variant-warning.list-group-item-variant-action:focus {
    color: #825801;
    background-color: rgba(253, 189, 57, 0.2); }
  .list-group-item-variant-warning.list-group-item-variant-action.active {
    color: #825801;
    background-color: #FDBD39;
    border-color: #FDBD39; }

.list-group-item-variant-danger {
  color: #861a14;
  background-color: transparent; }
  .list-group-item-variant-danger.list-group-item-variant-action:hover, .list-group-item-variant-danger.list-group-item-variant-action:focus {
    color: #861a14;
    background-color: rgba(232, 107, 100, 0.2); }
  .list-group-item-variant-danger.list-group-item-variant-action.active {
    color: #861a14;
    background-color: #E86B64;
    border-color: #E86B64; }

.list-group-item-variant-light {
  color: #577693;
  background-color: transparent; }
  .list-group-item-variant-light.list-group-item-variant-action:hover, .list-group-item-variant-light.list-group-item-variant-action:focus {
    color: #577693;
    background-color: rgba(194, 207, 219, 0.2); }
  .list-group-item-variant-light.list-group-item-variant-action.active {
    color: #577693;
    background-color: #C2CFDB;
    border-color: #C2CFDB; }

.list-group-item-variant-dark {
  color: #5480a1;
  background-color: transparent; }
  .list-group-item-variant-dark.list-group-item-variant-action:hover, .list-group-item-variant-dark.list-group-item-variant-action:focus {
    color: #5480a1;
    background-color: rgba(23, 35, 44, 0.2); }
  .list-group-item-variant-dark.list-group-item-variant-action.active {
    color: #5480a1;
    background-color: #17232C;
    border-color: #17232C; }

.list-group-item-variant-brainimals-aqua {
  color: #9ef7f7;
  background-color: transparent; }
  .list-group-item-variant-brainimals-aqua.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-aqua.list-group-item-variant-action:focus {
    color: #9ef7f7;
    background-color: rgba(17, 209, 210, 0.2); }
  .list-group-item-variant-brainimals-aqua.list-group-item-variant-action.active {
    color: #9ef7f7;
    background-color: #11D1D2;
    border-color: #11D1D2; }

.list-group-item-variant-brainimals-topaz {
  color: #7df4f5;
  background-color: transparent; }
  .list-group-item-variant-brainimals-topaz.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-topaz.list-group-item-variant-action:focus {
    color: #7df4f5;
    background-color: rgba(14, 176, 177, 0.2); }
  .list-group-item-variant-brainimals-topaz.list-group-item-variant-action.active {
    color: #7df4f5;
    background-color: #0EB0B1;
    border-color: #0EB0B1; }

.list-group-item-variant-brainimals-blue-light {
  color: #137697;
  background-color: transparent; }
  .list-group-item-variant-brainimals-blue-light.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-blue-light.list-group-item-variant-action:focus {
    color: #137697;
    background-color: rgba(111, 206, 237, 0.2); }
  .list-group-item-variant-brainimals-blue-light.list-group-item-variant-action.active {
    color: #137697;
    background-color: #6FCEED;
    border-color: #6FCEED; }

.list-group-item-variant-brainimals-deep-sea-blue {
  color: #31b5fd;
  background-color: transparent; }
  .list-group-item-variant-brainimals-deep-sea-blue.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-deep-sea-blue.list-group-item-variant-action:focus {
    color: #31b5fd;
    background-color: rgba(1, 80, 123, 0.2); }
  .list-group-item-variant-brainimals-deep-sea-blue.list-group-item-variant-action.active {
    color: #31b5fd;
    background-color: #01507B;
    border-color: #01507B; }

.list-group-item-variant-brainimals-dark-cyan {
  color: #5bf0f2;
  background-color: transparent; }
  .list-group-item-variant-brainimals-dark-cyan.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-dark-cyan.list-group-item-variant-action:focus {
    color: #5bf0f2;
    background-color: rgba(11, 142, 144, 0.2); }
  .list-group-item-variant-brainimals-dark-cyan.list-group-item-variant-action.active {
    color: #5bf0f2;
    background-color: #0B8E90;
    border-color: #0B8E90; }

.list-group-item-variant-brainimals-darker-cyan {
  color: #22eff6;
  background-color: transparent; }
  .list-group-item-variant-brainimals-darker-cyan.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-darker-cyan.list-group-item-variant-action:focus {
    color: #22eff6;
    background-color: rgba(4, 94, 97, 0.2); }
  .list-group-item-variant-brainimals-darker-cyan.list-group-item-variant-action.active {
    color: #22eff6;
    background-color: #045E61;
    border-color: #045E61; }

.list-group-item-variant-brainimals-green-light {
  color: #778f1a;
  background-color: transparent; }
  .list-group-item-variant-brainimals-green-light.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-green-light.list-group-item-variant-action:focus {
    color: #778f1a;
    background-color: rgba(207, 230, 118, 0.2); }
  .list-group-item-variant-brainimals-green-light.list-group-item-variant-action.active {
    color: #778f1a;
    background-color: #CFE676;
    border-color: #CFE676; }

.list-group-item-variant-brainimals-green {
  color: #ade794;
  background-color: transparent; }
  .list-group-item-variant-brainimals-green.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-green.list-group-item-variant-action:focus {
    color: #ade794;
    background-color: rgba(76, 163, 37, 0.2); }
  .list-group-item-variant-brainimals-green.list-group-item-variant-action.active {
    color: #ade794;
    background-color: #4CA325;
    border-color: #4CA325; }

.list-group-item-variant-brainimals-orange {
  color: #825801;
  background-color: transparent; }
  .list-group-item-variant-brainimals-orange.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-orange.list-group-item-variant-action:focus {
    color: #825801;
    background-color: rgba(253, 189, 57, 0.2); }
  .list-group-item-variant-brainimals-orange.list-group-item-variant-action.active {
    color: #825801;
    background-color: #FDBD39;
    border-color: #FDBD39; }

.list-group-item-variant-brainimals-red {
  color: #861a14;
  background-color: transparent; }
  .list-group-item-variant-brainimals-red.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-red.list-group-item-variant-action:focus {
    color: #861a14;
    background-color: rgba(232, 107, 100, 0.2); }
  .list-group-item-variant-brainimals-red.list-group-item-variant-action.active {
    color: #861a14;
    background-color: #E86B64;
    border-color: #E86B64; }

.list-group-item-variant-brainimals-dark-grey-blue {
  color: #819cb3;
  background-color: transparent; }
  .list-group-item-variant-brainimals-dark-grey-blue.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-dark-grey-blue.list-group-item-variant-action:focus {
    color: #819cb3;
    background-color: rgba(49, 66, 81, 0.2); }
  .list-group-item-variant-brainimals-dark-grey-blue.list-group-item-variant-action.active {
    color: #819cb3;
    background-color: #314251;
    border-color: #314251; }

.list-group-item-variant-brainimals-pale-grey-two {
  color: #6f92a8;
  background-color: transparent; }
  .list-group-item-variant-brainimals-pale-grey-two.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-pale-grey-two.list-group-item-variant-action:focus {
    color: #6f92a8;
    background-color: rgba(222, 230, 235, 0.2); }
  .list-group-item-variant-brainimals-pale-grey-two.list-group-item-variant-action.active {
    color: #6f92a8;
    background-color: #DEE6EB;
    border-color: #DEE6EB; }

.list-group-item-variant-brainimals-pale-grey {
  color: #7ea2b0;
  background-color: transparent; }
  .list-group-item-variant-brainimals-pale-grey.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-pale-grey.list-group-item-variant-action:focus {
    color: #7ea2b0;
    background-color: rgba(237, 242, 244, 0.2); }
  .list-group-item-variant-brainimals-pale-grey.list-group-item-variant-action.active {
    color: #7ea2b0;
    background-color: #EDF2F4;
    border-color: #EDF2F4; }

.list-group-item-variant-brainimals-bluey-grey {
  color: #384857;
  background-color: transparent; }
  .list-group-item-variant-brainimals-bluey-grey.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-bluey-grey.list-group-item-variant-action:focus {
    color: #384857;
    background-color: rgba(140, 162, 181, 0.2); }
  .list-group-item-variant-brainimals-bluey-grey.list-group-item-variant-action.active {
    color: #384857;
    background-color: #8CA2B5;
    border-color: #8CA2B5; }

.list-group-item-variant-brainimals-cloudy-blue {
  color: #577693;
  background-color: transparent; }
  .list-group-item-variant-brainimals-cloudy-blue.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-cloudy-blue.list-group-item-variant-action:focus {
    color: #577693;
    background-color: rgba(194, 207, 219, 0.2); }
  .list-group-item-variant-brainimals-cloudy-blue.list-group-item-variant-action.active {
    color: #577693;
    background-color: #C2CFDB;
    border-color: #C2CFDB; }

.list-group-item-variant-brainimals-blue-grey {
  color: #c6cfd7;
  background-color: transparent; }
  .list-group-item-variant-brainimals-blue-grey.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-blue-grey.list-group-item-variant-action:focus {
    color: #c6cfd7;
    background-color: rgba(96, 118, 138, 0.2); }
  .list-group-item-variant-brainimals-blue-grey.list-group-item-variant-action.active {
    color: #c6cfd7;
    background-color: #60768A;
    border-color: #60768A; }

.list-group-item-variant-brainimals-dark {
  color: #5480a1;
  background-color: transparent; }
  .list-group-item-variant-brainimals-dark.list-group-item-variant-action:hover, .list-group-item-variant-brainimals-dark.list-group-item-variant-action:focus {
    color: #5480a1;
    background-color: rgba(23, 35, 44, 0.2); }
  .list-group-item-variant-brainimals-dark.list-group-item-variant-action.active {
    color: #5480a1;
    background-color: #17232C;
    border-color: #17232C; }

.list-group-item {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  .list-group-item*[class*="list-group-item"] {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .list-group-item*[class*="list-group-item"] {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }

.list-group-item-primary {
  color: #012a40;
  background-color: #7ba4ba; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #012a40;
    background-color: #6a98b1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFF;
    background-color: #012a40;
    border-color: #012a40; }

.list-group-item-secondary {
  color: #6c783d;
  background-color: #e6f2b8; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #6c783d;
    background-color: #deeea2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFF;
    background-color: #6c783d;
    border-color: #6c783d; }

.list-group-item-success {
  color: #285513;
  background-color: #a2cf8e; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #285513;
    background-color: #93c77c; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFF;
    background-color: #285513;
    border-color: #285513; }

.list-group-item-info {
  color: #49545e;
  background-color: #c3cfd9; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #49545e;
    background-color: #b3c3cf; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFF;
    background-color: #49545e;
    border-color: #49545e; }

.list-group-item-warning {
  color: #84621e;
  background-color: #fedd98; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #84621e;
    background-color: #fed57f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFF;
    background-color: #84621e;
    border-color: #84621e; }

.list-group-item-danger {
  color: #793834;
  background-color: #f3b2ae; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #793834;
    background-color: #f09d98; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFF;
    background-color: #793834;
    border-color: #793834; }

.list-group-item-light {
  color: #656c72;
  background-color: #dfe6ec; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #656c72;
    background-color: #cfdae3; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFF;
    background-color: #656c72;
    border-color: #656c72; }

.list-group-item-dark {
  color: #0c1217;
  background-color: #868d91; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0c1217;
    background-color: #798085; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFF;
    background-color: #0c1217;
    border-color: #0c1217; }

.list-group-item-brainimals-aqua {
  color: #096d6d;
  background-color: #83e7e8; }
  .list-group-item-brainimals-aqua.list-group-item-action:hover, .list-group-item-brainimals-aqua.list-group-item-action:focus {
    color: #096d6d;
    background-color: #6de3e4; }
  .list-group-item-brainimals-aqua.list-group-item-action.active {
    color: #FFF;
    background-color: #096d6d;
    border-color: #096d6d; }

.list-group-item-brainimals-topaz {
  color: #075c5c;
  background-color: #82d6d6; }
  .list-group-item-brainimals-topaz.list-group-item-action:hover, .list-group-item-brainimals-topaz.list-group-item-action:focus {
    color: #075c5c;
    background-color: #6fd0d0; }
  .list-group-item-brainimals-topaz.list-group-item-action.active {
    color: #FFF;
    background-color: #075c5c;
    border-color: #075c5c; }

.list-group-item-brainimals-blue-light {
  color: #3a6b7b;
  background-color: #b4e6f6; }
  .list-group-item-brainimals-blue-light.list-group-item-action:hover, .list-group-item-brainimals-blue-light.list-group-item-action:focus {
    color: #3a6b7b;
    background-color: #9ddef3; }
  .list-group-item-brainimals-blue-light.list-group-item-action.active {
    color: #FFF;
    background-color: #3a6b7b;
    border-color: #3a6b7b; }

.list-group-item-brainimals-deep-sea-blue {
  color: #012a40;
  background-color: #7ba4ba; }
  .list-group-item-brainimals-deep-sea-blue.list-group-item-action:hover, .list-group-item-brainimals-deep-sea-blue.list-group-item-action:focus {
    color: #012a40;
    background-color: #6a98b1; }
  .list-group-item-brainimals-deep-sea-blue.list-group-item-action.active {
    color: #FFF;
    background-color: #012a40;
    border-color: #012a40; }

.list-group-item-brainimals-dark-cyan {
  color: #064a4b;
  background-color: #80c4c5; }
  .list-group-item-brainimals-dark-cyan.list-group-item-action:hover, .list-group-item-brainimals-dark-cyan.list-group-item-action:focus {
    color: #064a4b;
    background-color: #6ebcbd; }
  .list-group-item-brainimals-dark-cyan.list-group-item-action.active {
    color: #FFF;
    background-color: #064a4b;
    border-color: #064a4b; }

.list-group-item-brainimals-darker-cyan {
  color: #023132;
  background-color: #7cabad; }
  .list-group-item-brainimals-darker-cyan.list-group-item-action:hover, .list-group-item-brainimals-darker-cyan.list-group-item-action:focus {
    color: #023132;
    background-color: #6ca1a3; }
  .list-group-item-brainimals-darker-cyan.list-group-item-action.active {
    color: #FFF;
    background-color: #023132;
    border-color: #023132; }

.list-group-item-brainimals-green-light {
  color: #6c783d;
  background-color: #e6f2b8; }
  .list-group-item-brainimals-green-light.list-group-item-action:hover, .list-group-item-brainimals-green-light.list-group-item-action:focus {
    color: #6c783d;
    background-color: #deeea2; }
  .list-group-item-brainimals-green-light.list-group-item-action.active {
    color: #FFF;
    background-color: #6c783d;
    border-color: #6c783d; }

.list-group-item-brainimals-green {
  color: #285513;
  background-color: #a2cf8e; }
  .list-group-item-brainimals-green.list-group-item-action:hover, .list-group-item-brainimals-green.list-group-item-action:focus {
    color: #285513;
    background-color: #93c77c; }
  .list-group-item-brainimals-green.list-group-item-action.active {
    color: #FFF;
    background-color: #285513;
    border-color: #285513; }

.list-group-item-brainimals-orange {
  color: #84621e;
  background-color: #fedd98; }
  .list-group-item-brainimals-orange.list-group-item-action:hover, .list-group-item-brainimals-orange.list-group-item-action:focus {
    color: #84621e;
    background-color: #fed57f; }
  .list-group-item-brainimals-orange.list-group-item-action.active {
    color: #FFF;
    background-color: #84621e;
    border-color: #84621e; }

.list-group-item-brainimals-red {
  color: #793834;
  background-color: #f3b2ae; }
  .list-group-item-brainimals-red.list-group-item-action:hover, .list-group-item-brainimals-red.list-group-item-action:focus {
    color: #793834;
    background-color: #f09d98; }
  .list-group-item-brainimals-red.list-group-item-action.active {
    color: #FFF;
    background-color: #793834;
    border-color: #793834; }

.list-group-item-brainimals-dark-grey-blue {
  color: #19222a;
  background-color: #949da5; }
  .list-group-item-brainimals-dark-grey-blue.list-group-item-action:hover, .list-group-item-brainimals-dark-grey-blue.list-group-item-action:focus {
    color: #19222a;
    background-color: #869099; }
  .list-group-item-brainimals-dark-grey-blue.list-group-item-action.active {
    color: #FFF;
    background-color: #19222a;
    border-color: #19222a; }

.list-group-item-brainimals-pale-grey-two {
  color: #73787a;
  background-color: #eef2f5; }
  .list-group-item-brainimals-pale-grey-two.list-group-item-action:hover, .list-group-item-brainimals-pale-grey-two.list-group-item-action:focus {
    color: #73787a;
    background-color: #dee6ec; }
  .list-group-item-brainimals-pale-grey-two.list-group-item-action.active {
    color: #FFF;
    background-color: #73787a;
    border-color: #73787a; }

.list-group-item-brainimals-pale-grey {
  color: #7b7e7f;
  background-color: #f6f8f9; }
  .list-group-item-brainimals-pale-grey.list-group-item-action:hover, .list-group-item-brainimals-pale-grey.list-group-item-action:focus {
    color: #7b7e7f;
    background-color: #e7ecef; }
  .list-group-item-brainimals-pale-grey.list-group-item-action.active {
    color: #FFF;
    background-color: #7b7e7f;
    border-color: #7b7e7f; }

.list-group-item-brainimals-bluey-grey {
  color: #49545e;
  background-color: #c3cfd9; }
  .list-group-item-brainimals-bluey-grey.list-group-item-action:hover, .list-group-item-brainimals-bluey-grey.list-group-item-action:focus {
    color: #49545e;
    background-color: #b3c3cf; }
  .list-group-item-brainimals-bluey-grey.list-group-item-action.active {
    color: #FFF;
    background-color: #49545e;
    border-color: #49545e; }

.list-group-item-brainimals-cloudy-blue {
  color: #656c72;
  background-color: #dfe6ec; }
  .list-group-item-brainimals-cloudy-blue.list-group-item-action:hover, .list-group-item-brainimals-cloudy-blue.list-group-item-action:focus {
    color: #656c72;
    background-color: #cfdae3; }
  .list-group-item-brainimals-cloudy-blue.list-group-item-action.active {
    color: #FFF;
    background-color: #656c72;
    border-color: #656c72; }

.list-group-item-brainimals-blue-grey {
  color: #323d48;
  background-color: #acb8c2; }
  .list-group-item-brainimals-blue-grey.list-group-item-action:hover, .list-group-item-brainimals-blue-grey.list-group-item-action:focus {
    color: #323d48;
    background-color: #9dabb7; }
  .list-group-item-brainimals-blue-grey.list-group-item-action.active {
    color: #FFF;
    background-color: #323d48;
    border-color: #323d48; }

.list-group-item-brainimals-dark {
  color: #0c1217;
  background-color: #868d91; }
  .list-group-item-brainimals-dark.list-group-item-action:hover, .list-group-item-brainimals-dark.list-group-item-action:focus {
    color: #0c1217;
    background-color: #798085; }
  .list-group-item-brainimals-dark.list-group-item-action.active {
    color: #FFF;
    background-color: #0c1217;
    border-color: #0c1217; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #FFF;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  -webkit-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
          box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #C4C6C7;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.6; }

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #C4C6C7;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-content {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-content {
  -webkit-box-shadow: 0 11px 15px -7px rgba(49, 66, 81, 0.2), 0 24px 38px 3px rgba(49, 66, 81, 0.14), 0 9px 46px 8px rgba(49, 66, 81, 0.12);
          box-shadow: 0 11px 15px -7px rgba(49, 66, 81, 0.2), 0 24px 38px 3px rgba(49, 66, 81, 0.14), 0 9px 46px 8px rgba(49, 66, 81, 0.12);
  border: 0; }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Quicksand", "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #01507B; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #01507B; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #01507B; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #01507B; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFF;
  text-align: center;
  background-color: #01507B;
  border-radius: 0.25rem; }

.tooltip-inner {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  font-weight: 500; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Quicksand", "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  -webkit-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
          box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #FFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #FFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #FFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #FFF; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #4E5457; }

.popover {
  -webkit-box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1);
          box-shadow: 0 1px 3px 0 rgba(49, 66, 81, 0.05), 0 4px 7px 0 rgba(49, 66, 81, 0.03), 0 0 0.15rem rgba(49, 66, 81, 0.08), 0 0.5rem 1rem rgba(49, 66, 81, 0.1); }

.popover-header {
  font-weight: 600;
  background: #EBECEC;
  border-bottom: 0; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  -ms-touch-action: pan-y;
      touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  -o-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 15%;
  color: #FFF;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  -o-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFF;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFF;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    -o-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFF;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #01507B !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #012f48 !important; }

.bg-secondary {
  background-color: #CFE676 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #c0de4b !important; }

.bg-success {
  background-color: #4CA325 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #39791c !important; }

.bg-info {
  background-color: #8CA2B5 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #6d89a1 !important; }

.bg-warning {
  background-color: #FDBD39 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #fcac07 !important; }

.bg-danger {
  background-color: #E86B64 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #e14138 !important; }

.bg-light {
  background-color: #C2CFDB !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #a2b6c8 !important; }

.bg-dark {
  background-color: #17232C !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #05080b !important; }

.bg-brainimals-aqua {
  background-color: #11D1D2 !important; }

a.bg-brainimals-aqua:hover, a.bg-brainimals-aqua:focus,
button.bg-brainimals-aqua:hover,
button.bg-brainimals-aqua:focus {
  background-color: #0da2a3 !important; }

.bg-brainimals-topaz {
  background-color: #0EB0B1 !important; }

a.bg-brainimals-topaz:hover, a.bg-brainimals-topaz:focus,
button.bg-brainimals-topaz:hover,
button.bg-brainimals-topaz:focus {
  background-color: #0a8182 !important; }

.bg-brainimals-blue-light {
  background-color: #6FCEED !important; }

a.bg-brainimals-blue-light:hover, a.bg-brainimals-blue-light:focus,
button.bg-brainimals-blue-light:hover,
button.bg-brainimals-blue-light:focus {
  background-color: #42bfe7 !important; }

.bg-brainimals-deep-sea-blue {
  background-color: #01507B !important; }

a.bg-brainimals-deep-sea-blue:hover, a.bg-brainimals-deep-sea-blue:focus,
button.bg-brainimals-deep-sea-blue:hover,
button.bg-brainimals-deep-sea-blue:focus {
  background-color: #012f48 !important; }

.bg-brainimals-dark-cyan {
  background-color: #0B8E90 !important; }

a.bg-brainimals-dark-cyan:hover, a.bg-brainimals-dark-cyan:focus,
button.bg-brainimals-dark-cyan:hover,
button.bg-brainimals-dark-cyan:focus {
  background-color: #075f61 !important; }

.bg-brainimals-darker-cyan {
  background-color: #045E61 !important; }

a.bg-brainimals-darker-cyan:hover, a.bg-brainimals-darker-cyan:focus,
button.bg-brainimals-darker-cyan:hover,
button.bg-brainimals-darker-cyan:focus {
  background-color: #022f30 !important; }

.bg-brainimals-green-light {
  background-color: #CFE676 !important; }

a.bg-brainimals-green-light:hover, a.bg-brainimals-green-light:focus,
button.bg-brainimals-green-light:hover,
button.bg-brainimals-green-light:focus {
  background-color: #c0de4b !important; }

.bg-brainimals-green {
  background-color: #4CA325 !important; }

a.bg-brainimals-green:hover, a.bg-brainimals-green:focus,
button.bg-brainimals-green:hover,
button.bg-brainimals-green:focus {
  background-color: #39791c !important; }

.bg-brainimals-orange {
  background-color: #FDBD39 !important; }

a.bg-brainimals-orange:hover, a.bg-brainimals-orange:focus,
button.bg-brainimals-orange:hover,
button.bg-brainimals-orange:focus {
  background-color: #fcac07 !important; }

.bg-brainimals-red {
  background-color: #E86B64 !important; }

a.bg-brainimals-red:hover, a.bg-brainimals-red:focus,
button.bg-brainimals-red:hover,
button.bg-brainimals-red:focus {
  background-color: #e14138 !important; }

.bg-brainimals-dark-grey-blue {
  background-color: #314251 !important; }

a.bg-brainimals-dark-grey-blue:hover, a.bg-brainimals-dark-grey-blue:focus,
button.bg-brainimals-dark-grey-blue:hover,
button.bg-brainimals-dark-grey-blue:focus {
  background-color: #1e2831 !important; }

.bg-brainimals-pale-grey-two {
  background-color: #DEE6EB !important; }

a.bg-brainimals-pale-grey-two:hover, a.bg-brainimals-pale-grey-two:focus,
button.bg-brainimals-pale-grey-two:hover,
button.bg-brainimals-pale-grey-two:focus {
  background-color: #beced8 !important; }

.bg-brainimals-pale-grey {
  background-color: #EDF2F4 !important; }

a.bg-brainimals-pale-grey:hover, a.bg-brainimals-pale-grey:focus,
button.bg-brainimals-pale-grey:hover,
button.bg-brainimals-pale-grey:focus {
  background-color: #cddbe1 !important; }

.bg-brainimals-bluey-grey {
  background-color: #8CA2B5 !important; }

a.bg-brainimals-bluey-grey:hover, a.bg-brainimals-bluey-grey:focus,
button.bg-brainimals-bluey-grey:hover,
button.bg-brainimals-bluey-grey:focus {
  background-color: #6d89a1 !important; }

.bg-brainimals-cloudy-blue {
  background-color: #C2CFDB !important; }

a.bg-brainimals-cloudy-blue:hover, a.bg-brainimals-cloudy-blue:focus,
button.bg-brainimals-cloudy-blue:hover,
button.bg-brainimals-cloudy-blue:focus {
  background-color: #a2b6c8 !important; }

.bg-brainimals-blue-grey {
  background-color: #60768A !important; }

a.bg-brainimals-blue-grey:hover, a.bg-brainimals-blue-grey:focus,
button.bg-brainimals-blue-grey:hover,
button.bg-brainimals-blue-grey:focus {
  background-color: #4b5c6c !important; }

.bg-brainimals-dark {
  background-color: #17232C !important; }

a.bg-brainimals-dark:hover, a.bg-brainimals-dark:focus,
button.bg-brainimals-dark:hover,
button.bg-brainimals-dark:focus {
  background-color: #05080b !important; }

.bg-white {
  background-color: #FFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-brainimals-deep-sea-blue--faded {
  background-color: rgba(11, 142, 144, 0.1) !important; }

a.bg-brainimals-deep-sea-blue--faded:hover, a.bg-brainimals-deep-sea-blue--faded:focus,
button.bg-brainimals-deep-sea-blue--faded:hover,
button.bg-brainimals-deep-sea-blue--faded:focus {
  background-color: rgba(7, 95, 97, 0.1) !important; }

.bg-gray-100 {
  background-color: #EBECEC !important; }

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #d1d3d3 !important; }

.bg-gray-200 {
  background-color: #D8D9DA !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #bec0c1 !important; }

.bg-gray-300 {
  background-color: #C4C6C7 !important; }

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #aaadae !important; }

.bg-gray-400 {
  background-color: #B1B3B5 !important; }

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #979a9c !important; }

.bg-gray-500 {
  background-color: #9DA0A2 !important; }

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #838789 !important; }

.bg-gray-600 {
  background-color: #898D8F !important; }

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #707375 !important; }

.bg-gray-700 {
  background-color: #757A7C !important; }

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #5c6062 !important; }

.bg-gray-800 {
  background-color: #62676A !important; }

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #4a4d50 !important; }

.bg-gray-900 {
  background-color: #4E5457 !important; }

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #363a3c !important; }

.border {
  border: 1px solid #C4C6C7 !important; }

.border-top {
  border-top: 1px solid #C4C6C7 !important; }

.border-right {
  border-right: 1px solid #C4C6C7 !important; }

.border-bottom {
  border-bottom: 1px solid #C4C6C7 !important; }

.border-left {
  border-left: 1px solid #C4C6C7 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #01507B !important; }

.border-secondary {
  border-color: #CFE676 !important; }

.border-success {
  border-color: #4CA325 !important; }

.border-info {
  border-color: #8CA2B5 !important; }

.border-warning {
  border-color: #FDBD39 !important; }

.border-danger {
  border-color: #E86B64 !important; }

.border-light {
  border-color: #C2CFDB !important; }

.border-dark {
  border-color: #17232C !important; }

.border-brainimals-aqua {
  border-color: #11D1D2 !important; }

.border-brainimals-topaz {
  border-color: #0EB0B1 !important; }

.border-brainimals-blue-light {
  border-color: #6FCEED !important; }

.border-brainimals-deep-sea-blue {
  border-color: #01507B !important; }

.border-brainimals-dark-cyan {
  border-color: #0B8E90 !important; }

.border-brainimals-darker-cyan {
  border-color: #045E61 !important; }

.border-brainimals-green-light {
  border-color: #CFE676 !important; }

.border-brainimals-green {
  border-color: #4CA325 !important; }

.border-brainimals-orange {
  border-color: #FDBD39 !important; }

.border-brainimals-red {
  border-color: #E86B64 !important; }

.border-brainimals-dark-grey-blue {
  border-color: #314251 !important; }

.border-brainimals-pale-grey-two {
  border-color: #DEE6EB !important; }

.border-brainimals-pale-grey {
  border-color: #EDF2F4 !important; }

.border-brainimals-bluey-grey {
  border-color: #8CA2B5 !important; }

.border-brainimals-cloudy-blue {
  border-color: #C2CFDB !important; }

.border-brainimals-blue-grey {
  border-color: #60768A !important; }

.border-brainimals-dark {
  border-color: #17232C !important; }

.border-white {
  border-color: #FFF !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.b-0 {
  border-width: 0px !important; }

.b-0 {
  border-width: 0px !important; }

.bt-0,
.by-0 {
  border-top-width: 0px !important; }

.br-0,
.bx-0 {
  border-right-width: 0px !important; }

.bb-0,
.by-0 {
  border-bottom-width: 0px !important; }

.bl-0,
.bx-0 {
  border-left-width: 0px !important; }

.b-1 {
  border-width: 1px !important; }

.b-1 {
  border-width: 1px !important; }

.bt-1,
.by-1 {
  border-top-width: 1px !important; }

.br-1,
.bx-1 {
  border-right-width: 1px !important; }

.bb-1,
.by-1 {
  border-bottom-width: 1px !important; }

.bl-1,
.bx-1 {
  border-left-width: 1px !important; }

.b-2 {
  border-width: 2px !important; }

.b-2 {
  border-width: 2px !important; }

.bt-2,
.by-2 {
  border-top-width: 2px !important; }

.br-2,
.bx-2 {
  border-right-width: 2px !important; }

.bb-2,
.by-2 {
  border-bottom-width: 2px !important; }

.bl-2,
.bx-2 {
  border-left-width: 2px !important; }

.b-3 {
  border-width: 3px !important; }

.b-3 {
  border-width: 3px !important; }

.bt-3,
.by-3 {
  border-top-width: 3px !important; }

.br-3,
.bx-3 {
  border-right-width: 3px !important; }

.bb-3,
.by-3 {
  border-bottom-width: 3px !important; }

.bl-3,
.bx-3 {
  border-left-width: 3px !important; }

.b-4 {
  border-width: 4px !important; }

.b-4 {
  border-width: 4px !important; }

.bt-4,
.by-4 {
  border-top-width: 4px !important; }

.br-4,
.bx-4 {
  border-right-width: 4px !important; }

.bb-4,
.by-4 {
  border-bottom-width: 4px !important; }

.bl-4,
.bx-4 {
  border-left-width: 4px !important; }

.b-5 {
  border-width: 5px !important; }

.b-5 {
  border-width: 5px !important; }

.bt-5,
.by-5 {
  border-top-width: 5px !important; }

.br-5,
.bx-5 {
  border-right-width: 5px !important; }

.bb-5,
.by-5 {
  border-bottom-width: 5px !important; }

.bl-5,
.bx-5 {
  border-left-width: 5px !important; }

@media (min-width: 576px) {
  .b-0 {
    border-width: 0px !important; }
  .b-sm-0 {
    border-width: 0px !important; }
  .bt-sm-0,
  .by-sm-0 {
    border-top-width: 0px !important; }
  .br-sm-0,
  .bx-sm-0 {
    border-right-width: 0px !important; }
  .bb-sm-0,
  .by-sm-0 {
    border-bottom-width: 0px !important; }
  .bl-sm-0,
  .bx-sm-0 {
    border-left-width: 0px !important; }
  .b-1 {
    border-width: 1px !important; }
  .b-sm-1 {
    border-width: 1px !important; }
  .bt-sm-1,
  .by-sm-1 {
    border-top-width: 1px !important; }
  .br-sm-1,
  .bx-sm-1 {
    border-right-width: 1px !important; }
  .bb-sm-1,
  .by-sm-1 {
    border-bottom-width: 1px !important; }
  .bl-sm-1,
  .bx-sm-1 {
    border-left-width: 1px !important; }
  .b-2 {
    border-width: 2px !important; }
  .b-sm-2 {
    border-width: 2px !important; }
  .bt-sm-2,
  .by-sm-2 {
    border-top-width: 2px !important; }
  .br-sm-2,
  .bx-sm-2 {
    border-right-width: 2px !important; }
  .bb-sm-2,
  .by-sm-2 {
    border-bottom-width: 2px !important; }
  .bl-sm-2,
  .bx-sm-2 {
    border-left-width: 2px !important; }
  .b-3 {
    border-width: 3px !important; }
  .b-sm-3 {
    border-width: 3px !important; }
  .bt-sm-3,
  .by-sm-3 {
    border-top-width: 3px !important; }
  .br-sm-3,
  .bx-sm-3 {
    border-right-width: 3px !important; }
  .bb-sm-3,
  .by-sm-3 {
    border-bottom-width: 3px !important; }
  .bl-sm-3,
  .bx-sm-3 {
    border-left-width: 3px !important; }
  .b-4 {
    border-width: 4px !important; }
  .b-sm-4 {
    border-width: 4px !important; }
  .bt-sm-4,
  .by-sm-4 {
    border-top-width: 4px !important; }
  .br-sm-4,
  .bx-sm-4 {
    border-right-width: 4px !important; }
  .bb-sm-4,
  .by-sm-4 {
    border-bottom-width: 4px !important; }
  .bl-sm-4,
  .bx-sm-4 {
    border-left-width: 4px !important; }
  .b-5 {
    border-width: 5px !important; }
  .b-sm-5 {
    border-width: 5px !important; }
  .bt-sm-5,
  .by-sm-5 {
    border-top-width: 5px !important; }
  .br-sm-5,
  .bx-sm-5 {
    border-right-width: 5px !important; }
  .bb-sm-5,
  .by-sm-5 {
    border-bottom-width: 5px !important; }
  .bl-sm-5,
  .bx-sm-5 {
    border-left-width: 5px !important; } }

@media (min-width: 768px) {
  .b-0 {
    border-width: 0px !important; }
  .b-md-0 {
    border-width: 0px !important; }
  .bt-md-0,
  .by-md-0 {
    border-top-width: 0px !important; }
  .br-md-0,
  .bx-md-0 {
    border-right-width: 0px !important; }
  .bb-md-0,
  .by-md-0 {
    border-bottom-width: 0px !important; }
  .bl-md-0,
  .bx-md-0 {
    border-left-width: 0px !important; }
  .b-1 {
    border-width: 1px !important; }
  .b-md-1 {
    border-width: 1px !important; }
  .bt-md-1,
  .by-md-1 {
    border-top-width: 1px !important; }
  .br-md-1,
  .bx-md-1 {
    border-right-width: 1px !important; }
  .bb-md-1,
  .by-md-1 {
    border-bottom-width: 1px !important; }
  .bl-md-1,
  .bx-md-1 {
    border-left-width: 1px !important; }
  .b-2 {
    border-width: 2px !important; }
  .b-md-2 {
    border-width: 2px !important; }
  .bt-md-2,
  .by-md-2 {
    border-top-width: 2px !important; }
  .br-md-2,
  .bx-md-2 {
    border-right-width: 2px !important; }
  .bb-md-2,
  .by-md-2 {
    border-bottom-width: 2px !important; }
  .bl-md-2,
  .bx-md-2 {
    border-left-width: 2px !important; }
  .b-3 {
    border-width: 3px !important; }
  .b-md-3 {
    border-width: 3px !important; }
  .bt-md-3,
  .by-md-3 {
    border-top-width: 3px !important; }
  .br-md-3,
  .bx-md-3 {
    border-right-width: 3px !important; }
  .bb-md-3,
  .by-md-3 {
    border-bottom-width: 3px !important; }
  .bl-md-3,
  .bx-md-3 {
    border-left-width: 3px !important; }
  .b-4 {
    border-width: 4px !important; }
  .b-md-4 {
    border-width: 4px !important; }
  .bt-md-4,
  .by-md-4 {
    border-top-width: 4px !important; }
  .br-md-4,
  .bx-md-4 {
    border-right-width: 4px !important; }
  .bb-md-4,
  .by-md-4 {
    border-bottom-width: 4px !important; }
  .bl-md-4,
  .bx-md-4 {
    border-left-width: 4px !important; }
  .b-5 {
    border-width: 5px !important; }
  .b-md-5 {
    border-width: 5px !important; }
  .bt-md-5,
  .by-md-5 {
    border-top-width: 5px !important; }
  .br-md-5,
  .bx-md-5 {
    border-right-width: 5px !important; }
  .bb-md-5,
  .by-md-5 {
    border-bottom-width: 5px !important; }
  .bl-md-5,
  .bx-md-5 {
    border-left-width: 5px !important; } }

@media (min-width: 992px) {
  .b-0 {
    border-width: 0px !important; }
  .b-lg-0 {
    border-width: 0px !important; }
  .bt-lg-0,
  .by-lg-0 {
    border-top-width: 0px !important; }
  .br-lg-0,
  .bx-lg-0 {
    border-right-width: 0px !important; }
  .bb-lg-0,
  .by-lg-0 {
    border-bottom-width: 0px !important; }
  .bl-lg-0,
  .bx-lg-0 {
    border-left-width: 0px !important; }
  .b-1 {
    border-width: 1px !important; }
  .b-lg-1 {
    border-width: 1px !important; }
  .bt-lg-1,
  .by-lg-1 {
    border-top-width: 1px !important; }
  .br-lg-1,
  .bx-lg-1 {
    border-right-width: 1px !important; }
  .bb-lg-1,
  .by-lg-1 {
    border-bottom-width: 1px !important; }
  .bl-lg-1,
  .bx-lg-1 {
    border-left-width: 1px !important; }
  .b-2 {
    border-width: 2px !important; }
  .b-lg-2 {
    border-width: 2px !important; }
  .bt-lg-2,
  .by-lg-2 {
    border-top-width: 2px !important; }
  .br-lg-2,
  .bx-lg-2 {
    border-right-width: 2px !important; }
  .bb-lg-2,
  .by-lg-2 {
    border-bottom-width: 2px !important; }
  .bl-lg-2,
  .bx-lg-2 {
    border-left-width: 2px !important; }
  .b-3 {
    border-width: 3px !important; }
  .b-lg-3 {
    border-width: 3px !important; }
  .bt-lg-3,
  .by-lg-3 {
    border-top-width: 3px !important; }
  .br-lg-3,
  .bx-lg-3 {
    border-right-width: 3px !important; }
  .bb-lg-3,
  .by-lg-3 {
    border-bottom-width: 3px !important; }
  .bl-lg-3,
  .bx-lg-3 {
    border-left-width: 3px !important; }
  .b-4 {
    border-width: 4px !important; }
  .b-lg-4 {
    border-width: 4px !important; }
  .bt-lg-4,
  .by-lg-4 {
    border-top-width: 4px !important; }
  .br-lg-4,
  .bx-lg-4 {
    border-right-width: 4px !important; }
  .bb-lg-4,
  .by-lg-4 {
    border-bottom-width: 4px !important; }
  .bl-lg-4,
  .bx-lg-4 {
    border-left-width: 4px !important; }
  .b-5 {
    border-width: 5px !important; }
  .b-lg-5 {
    border-width: 5px !important; }
  .bt-lg-5,
  .by-lg-5 {
    border-top-width: 5px !important; }
  .br-lg-5,
  .bx-lg-5 {
    border-right-width: 5px !important; }
  .bb-lg-5,
  .by-lg-5 {
    border-bottom-width: 5px !important; }
  .bl-lg-5,
  .bx-lg-5 {
    border-left-width: 5px !important; } }

@media (min-width: 1200px) {
  .b-0 {
    border-width: 0px !important; }
  .b-xl-0 {
    border-width: 0px !important; }
  .bt-xl-0,
  .by-xl-0 {
    border-top-width: 0px !important; }
  .br-xl-0,
  .bx-xl-0 {
    border-right-width: 0px !important; }
  .bb-xl-0,
  .by-xl-0 {
    border-bottom-width: 0px !important; }
  .bl-xl-0,
  .bx-xl-0 {
    border-left-width: 0px !important; }
  .b-1 {
    border-width: 1px !important; }
  .b-xl-1 {
    border-width: 1px !important; }
  .bt-xl-1,
  .by-xl-1 {
    border-top-width: 1px !important; }
  .br-xl-1,
  .bx-xl-1 {
    border-right-width: 1px !important; }
  .bb-xl-1,
  .by-xl-1 {
    border-bottom-width: 1px !important; }
  .bl-xl-1,
  .bx-xl-1 {
    border-left-width: 1px !important; }
  .b-2 {
    border-width: 2px !important; }
  .b-xl-2 {
    border-width: 2px !important; }
  .bt-xl-2,
  .by-xl-2 {
    border-top-width: 2px !important; }
  .br-xl-2,
  .bx-xl-2 {
    border-right-width: 2px !important; }
  .bb-xl-2,
  .by-xl-2 {
    border-bottom-width: 2px !important; }
  .bl-xl-2,
  .bx-xl-2 {
    border-left-width: 2px !important; }
  .b-3 {
    border-width: 3px !important; }
  .b-xl-3 {
    border-width: 3px !important; }
  .bt-xl-3,
  .by-xl-3 {
    border-top-width: 3px !important; }
  .br-xl-3,
  .bx-xl-3 {
    border-right-width: 3px !important; }
  .bb-xl-3,
  .by-xl-3 {
    border-bottom-width: 3px !important; }
  .bl-xl-3,
  .bx-xl-3 {
    border-left-width: 3px !important; }
  .b-4 {
    border-width: 4px !important; }
  .b-xl-4 {
    border-width: 4px !important; }
  .bt-xl-4,
  .by-xl-4 {
    border-top-width: 4px !important; }
  .br-xl-4,
  .bx-xl-4 {
    border-right-width: 4px !important; }
  .bb-xl-4,
  .by-xl-4 {
    border-bottom-width: 4px !important; }
  .bl-xl-4,
  .bx-xl-4 {
    border-left-width: 4px !important; }
  .b-5 {
    border-width: 5px !important; }
  .b-xl-5 {
    border-width: 5px !important; }
  .bt-xl-5,
  .by-xl-5 {
    border-top-width: 5px !important; }
  .br-xl-5,
  .bx-xl-5 {
    border-right-width: 5px !important; }
  .bb-xl-5,
  .by-xl-5 {
    border-bottom-width: 5px !important; }
  .bl-xl-5,
  .bx-xl-5 {
    border-left-width: 5px !important; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .swiper-container .swiper-pagination {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -ms-flex-direction: row !important;
      flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
      flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
      flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
      flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
      justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
      justify-content: flex-end !important; }

.justify-content-center, .swiper-container .swiper-pagination {
  -ms-flex-pack: center !important;
      justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
      justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
      align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
      align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
      align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
      align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
      align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important; }

.align-self-start, .bubble {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.align-self-end, .form-control-search, .bubble__sent,
.bubble__interactive {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

.flex-basis-0 {
  -ms-flex-preferred-size: 0 !important;
      flex-basis: 0 !important; }

.flex-basis-auto {
  -ms-flex-preferred-size: auto !important;
      flex-basis: auto !important; }

@media (min-width: 576px) {
  .flex-sm-basis-0 {
    -ms-flex-preferred-size: 0 !important;
        flex-basis: 0 !important; }
  .flex-sm-basis-auto {
    -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important; } }

@media (min-width: 768px) {
  .flex-md-basis-0 {
    -ms-flex-preferred-size: 0 !important;
        flex-basis: 0 !important; }
  .flex-md-basis-auto {
    -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important; } }

@media (min-width: 992px) {
  .flex-lg-basis-0 {
    -ms-flex-preferred-size: 0 !important;
        flex-basis: 0 !important; }
  .flex-lg-basis-auto {
    -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important; } }

@media (min-width: 1200px) {
  .flex-xl-basis-0 {
    -ms-flex-preferred-size: 0 !important;
        flex-basis: 0 !important; }
  .flex-xl-basis-auto {
    -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1) !important;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1) !important; }

.shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2) !important; }

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0, .form-control + label {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2, .form-control-search,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3, .swiper-container .swiper-pagination,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.m-n0 {
  margin: 0 !important; }

.mt-n0,
.my-n0 {
  margin-top: 0 !important; }

.mr-n0,
.mx-n0 {
  margin-right: 0 !important; }

.mb-n0,
.my-n0 {
  margin-bottom: 0 !important; }

.ml-n0,
.mx-n0 {
  margin-left: 0 !important; }

.m-n1 {
  margin: 0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: 0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: 1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: 1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: 3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.p-n0 {
  padding: 0 !important; }

.pt-n0,
.py-n0 {
  padding-top: 0 !important; }

.pr-n0,
.px-n0 {
  padding-right: 0 !important; }

.pb-n0,
.py-n0 {
  padding-bottom: 0 !important; }

.pl-n0,
.px-n0 {
  padding-left: 0 !important; }

.p-n1 {
  padding: 0.25rem !important; }

.pt-n1,
.py-n1 {
  padding-top: -0.25rem !important; }

.pr-n1,
.px-n1 {
  padding-right: -0.25rem !important; }

.pb-n1,
.py-n1 {
  padding-bottom: -0.25rem !important; }

.pl-n1,
.px-n1 {
  padding-left: -0.25rem !important; }

.p-n2 {
  padding: 0.5rem !important; }

.pt-n2,
.py-n2 {
  padding-top: -0.5rem !important; }

.pr-n2,
.px-n2 {
  padding-right: -0.5rem !important; }

.pb-n2,
.py-n2 {
  padding-bottom: -0.5rem !important; }

.pl-n2,
.px-n2 {
  padding-left: -0.5rem !important; }

.p-n3 {
  padding: 1rem !important; }

.pt-n3,
.py-n3 {
  padding-top: -1rem !important; }

.pr-n3,
.px-n3 {
  padding-right: -1rem !important; }

.pb-n3,
.py-n3 {
  padding-bottom: -1rem !important; }

.pl-n3,
.px-n3 {
  padding-left: -1rem !important; }

.p-n4 {
  padding: 1.5rem !important; }

.pt-n4,
.py-n4 {
  padding-top: -1.5rem !important; }

.pr-n4,
.px-n4 {
  padding-right: -1.5rem !important; }

.pb-n4,
.py-n4 {
  padding-bottom: -1.5rem !important; }

.pl-n4,
.px-n4 {
  padding-left: -1.5rem !important; }

.p-n5 {
  padding: 3rem !important; }

.pt-n5,
.py-n5 {
  padding-top: -3rem !important; }

.pr-n5,
.px-n5 {
  padding-right: -3rem !important; }

.pb-n5,
.py-n5 {
  padding-bottom: -3rem !important; }

.pl-n5,
.px-n5 {
  padding-left: -3rem !important; }

@media (min-width: 576px) {
  .m-sm-n0 {
    margin: 0 !important; }
  .mt-sm-n0,
  .my-sm-n0 {
    margin-top: 0 !important; }
  .mr-sm-n0,
  .mx-sm-n0 {
    margin-right: 0 !important; }
  .mb-sm-n0,
  .my-sm-n0 {
    margin-bottom: 0 !important; }
  .ml-sm-n0,
  .mx-sm-n0 {
    margin-left: 0 !important; }
  .m-sm-n1 {
    margin: 0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: 0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: 1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: 1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: 3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .p-sm-n0 {
    padding: 0 !important; }
  .pt-sm-n0,
  .py-sm-n0 {
    padding-top: 0 !important; }
  .pr-sm-n0,
  .px-sm-n0 {
    padding-right: 0 !important; }
  .pb-sm-n0,
  .py-sm-n0 {
    padding-bottom: 0 !important; }
  .pl-sm-n0,
  .px-sm-n0 {
    padding-left: 0 !important; }
  .p-sm-n1 {
    padding: 0.25rem !important; }
  .pt-sm-n1,
  .py-sm-n1 {
    padding-top: -0.25rem !important; }
  .pr-sm-n1,
  .px-sm-n1 {
    padding-right: -0.25rem !important; }
  .pb-sm-n1,
  .py-sm-n1 {
    padding-bottom: -0.25rem !important; }
  .pl-sm-n1,
  .px-sm-n1 {
    padding-left: -0.25rem !important; }
  .p-sm-n2 {
    padding: 0.5rem !important; }
  .pt-sm-n2,
  .py-sm-n2 {
    padding-top: -0.5rem !important; }
  .pr-sm-n2,
  .px-sm-n2 {
    padding-right: -0.5rem !important; }
  .pb-sm-n2,
  .py-sm-n2 {
    padding-bottom: -0.5rem !important; }
  .pl-sm-n2,
  .px-sm-n2 {
    padding-left: -0.5rem !important; }
  .p-sm-n3 {
    padding: 1rem !important; }
  .pt-sm-n3,
  .py-sm-n3 {
    padding-top: -1rem !important; }
  .pr-sm-n3,
  .px-sm-n3 {
    padding-right: -1rem !important; }
  .pb-sm-n3,
  .py-sm-n3 {
    padding-bottom: -1rem !important; }
  .pl-sm-n3,
  .px-sm-n3 {
    padding-left: -1rem !important; }
  .p-sm-n4 {
    padding: 1.5rem !important; }
  .pt-sm-n4,
  .py-sm-n4 {
    padding-top: -1.5rem !important; }
  .pr-sm-n4,
  .px-sm-n4 {
    padding-right: -1.5rem !important; }
  .pb-sm-n4,
  .py-sm-n4 {
    padding-bottom: -1.5rem !important; }
  .pl-sm-n4,
  .px-sm-n4 {
    padding-left: -1.5rem !important; }
  .p-sm-n5 {
    padding: 3rem !important; }
  .pt-sm-n5,
  .py-sm-n5 {
    padding-top: -3rem !important; }
  .pr-sm-n5,
  .px-sm-n5 {
    padding-right: -3rem !important; }
  .pb-sm-n5,
  .py-sm-n5 {
    padding-bottom: -3rem !important; }
  .pl-sm-n5,
  .px-sm-n5 {
    padding-left: -3rem !important; } }

@media (min-width: 768px) {
  .m-md-n0 {
    margin: 0 !important; }
  .mt-md-n0,
  .my-md-n0 {
    margin-top: 0 !important; }
  .mr-md-n0,
  .mx-md-n0 {
    margin-right: 0 !important; }
  .mb-md-n0,
  .my-md-n0 {
    margin-bottom: 0 !important; }
  .ml-md-n0,
  .mx-md-n0 {
    margin-left: 0 !important; }
  .m-md-n1 {
    margin: 0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: 0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: 1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: 1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: 3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .p-md-n0 {
    padding: 0 !important; }
  .pt-md-n0,
  .py-md-n0 {
    padding-top: 0 !important; }
  .pr-md-n0,
  .px-md-n0 {
    padding-right: 0 !important; }
  .pb-md-n0,
  .py-md-n0 {
    padding-bottom: 0 !important; }
  .pl-md-n0,
  .px-md-n0 {
    padding-left: 0 !important; }
  .p-md-n1 {
    padding: 0.25rem !important; }
  .pt-md-n1,
  .py-md-n1 {
    padding-top: -0.25rem !important; }
  .pr-md-n1,
  .px-md-n1 {
    padding-right: -0.25rem !important; }
  .pb-md-n1,
  .py-md-n1 {
    padding-bottom: -0.25rem !important; }
  .pl-md-n1,
  .px-md-n1 {
    padding-left: -0.25rem !important; }
  .p-md-n2 {
    padding: 0.5rem !important; }
  .pt-md-n2,
  .py-md-n2 {
    padding-top: -0.5rem !important; }
  .pr-md-n2,
  .px-md-n2 {
    padding-right: -0.5rem !important; }
  .pb-md-n2,
  .py-md-n2 {
    padding-bottom: -0.5rem !important; }
  .pl-md-n2,
  .px-md-n2 {
    padding-left: -0.5rem !important; }
  .p-md-n3 {
    padding: 1rem !important; }
  .pt-md-n3,
  .py-md-n3 {
    padding-top: -1rem !important; }
  .pr-md-n3,
  .px-md-n3 {
    padding-right: -1rem !important; }
  .pb-md-n3,
  .py-md-n3 {
    padding-bottom: -1rem !important; }
  .pl-md-n3,
  .px-md-n3 {
    padding-left: -1rem !important; }
  .p-md-n4 {
    padding: 1.5rem !important; }
  .pt-md-n4,
  .py-md-n4 {
    padding-top: -1.5rem !important; }
  .pr-md-n4,
  .px-md-n4 {
    padding-right: -1.5rem !important; }
  .pb-md-n4,
  .py-md-n4 {
    padding-bottom: -1.5rem !important; }
  .pl-md-n4,
  .px-md-n4 {
    padding-left: -1.5rem !important; }
  .p-md-n5 {
    padding: 3rem !important; }
  .pt-md-n5,
  .py-md-n5 {
    padding-top: -3rem !important; }
  .pr-md-n5,
  .px-md-n5 {
    padding-right: -3rem !important; }
  .pb-md-n5,
  .py-md-n5 {
    padding-bottom: -3rem !important; }
  .pl-md-n5,
  .px-md-n5 {
    padding-left: -3rem !important; } }

@media (min-width: 992px) {
  .m-lg-n0 {
    margin: 0 !important; }
  .mt-lg-n0,
  .my-lg-n0 {
    margin-top: 0 !important; }
  .mr-lg-n0,
  .mx-lg-n0 {
    margin-right: 0 !important; }
  .mb-lg-n0,
  .my-lg-n0 {
    margin-bottom: 0 !important; }
  .ml-lg-n0,
  .mx-lg-n0 {
    margin-left: 0 !important; }
  .m-lg-n1 {
    margin: 0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: 0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: 1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: 1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: 3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .p-lg-n0 {
    padding: 0 !important; }
  .pt-lg-n0,
  .py-lg-n0 {
    padding-top: 0 !important; }
  .pr-lg-n0,
  .px-lg-n0 {
    padding-right: 0 !important; }
  .pb-lg-n0,
  .py-lg-n0 {
    padding-bottom: 0 !important; }
  .pl-lg-n0,
  .px-lg-n0 {
    padding-left: 0 !important; }
  .p-lg-n1 {
    padding: 0.25rem !important; }
  .pt-lg-n1,
  .py-lg-n1 {
    padding-top: -0.25rem !important; }
  .pr-lg-n1,
  .px-lg-n1 {
    padding-right: -0.25rem !important; }
  .pb-lg-n1,
  .py-lg-n1 {
    padding-bottom: -0.25rem !important; }
  .pl-lg-n1,
  .px-lg-n1 {
    padding-left: -0.25rem !important; }
  .p-lg-n2 {
    padding: 0.5rem !important; }
  .pt-lg-n2,
  .py-lg-n2 {
    padding-top: -0.5rem !important; }
  .pr-lg-n2,
  .px-lg-n2 {
    padding-right: -0.5rem !important; }
  .pb-lg-n2,
  .py-lg-n2 {
    padding-bottom: -0.5rem !important; }
  .pl-lg-n2,
  .px-lg-n2 {
    padding-left: -0.5rem !important; }
  .p-lg-n3 {
    padding: 1rem !important; }
  .pt-lg-n3,
  .py-lg-n3 {
    padding-top: -1rem !important; }
  .pr-lg-n3,
  .px-lg-n3 {
    padding-right: -1rem !important; }
  .pb-lg-n3,
  .py-lg-n3 {
    padding-bottom: -1rem !important; }
  .pl-lg-n3,
  .px-lg-n3 {
    padding-left: -1rem !important; }
  .p-lg-n4 {
    padding: 1.5rem !important; }
  .pt-lg-n4,
  .py-lg-n4 {
    padding-top: -1.5rem !important; }
  .pr-lg-n4,
  .px-lg-n4 {
    padding-right: -1.5rem !important; }
  .pb-lg-n4,
  .py-lg-n4 {
    padding-bottom: -1.5rem !important; }
  .pl-lg-n4,
  .px-lg-n4 {
    padding-left: -1.5rem !important; }
  .p-lg-n5 {
    padding: 3rem !important; }
  .pt-lg-n5,
  .py-lg-n5 {
    padding-top: -3rem !important; }
  .pr-lg-n5,
  .px-lg-n5 {
    padding-right: -3rem !important; }
  .pb-lg-n5,
  .py-lg-n5 {
    padding-bottom: -3rem !important; }
  .pl-lg-n5,
  .px-lg-n5 {
    padding-left: -3rem !important; } }

@media (min-width: 1200px) {
  .m-xl-n0 {
    margin: 0 !important; }
  .mt-xl-n0,
  .my-xl-n0 {
    margin-top: 0 !important; }
  .mr-xl-n0,
  .mx-xl-n0 {
    margin-right: 0 !important; }
  .mb-xl-n0,
  .my-xl-n0 {
    margin-bottom: 0 !important; }
  .ml-xl-n0,
  .mx-xl-n0 {
    margin-left: 0 !important; }
  .m-xl-n1 {
    margin: 0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: 0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: 1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: 1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: 3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .p-xl-n0 {
    padding: 0 !important; }
  .pt-xl-n0,
  .py-xl-n0 {
    padding-top: 0 !important; }
  .pr-xl-n0,
  .px-xl-n0 {
    padding-right: 0 !important; }
  .pb-xl-n0,
  .py-xl-n0 {
    padding-bottom: 0 !important; }
  .pl-xl-n0,
  .px-xl-n0 {
    padding-left: 0 !important; }
  .p-xl-n1 {
    padding: 0.25rem !important; }
  .pt-xl-n1,
  .py-xl-n1 {
    padding-top: -0.25rem !important; }
  .pr-xl-n1,
  .px-xl-n1 {
    padding-right: -0.25rem !important; }
  .pb-xl-n1,
  .py-xl-n1 {
    padding-bottom: -0.25rem !important; }
  .pl-xl-n1,
  .px-xl-n1 {
    padding-left: -0.25rem !important; }
  .p-xl-n2 {
    padding: 0.5rem !important; }
  .pt-xl-n2,
  .py-xl-n2 {
    padding-top: -0.5rem !important; }
  .pr-xl-n2,
  .px-xl-n2 {
    padding-right: -0.5rem !important; }
  .pb-xl-n2,
  .py-xl-n2 {
    padding-bottom: -0.5rem !important; }
  .pl-xl-n2,
  .px-xl-n2 {
    padding-left: -0.5rem !important; }
  .p-xl-n3 {
    padding: 1rem !important; }
  .pt-xl-n3,
  .py-xl-n3 {
    padding-top: -1rem !important; }
  .pr-xl-n3,
  .px-xl-n3 {
    padding-right: -1rem !important; }
  .pb-xl-n3,
  .py-xl-n3 {
    padding-bottom: -1rem !important; }
  .pl-xl-n3,
  .px-xl-n3 {
    padding-left: -1rem !important; }
  .p-xl-n4 {
    padding: 1.5rem !important; }
  .pt-xl-n4,
  .py-xl-n4 {
    padding-top: -1.5rem !important; }
  .pr-xl-n4,
  .px-xl-n4 {
    padding-right: -1.5rem !important; }
  .pb-xl-n4,
  .py-xl-n4 {
    padding-bottom: -1.5rem !important; }
  .pl-xl-n4,
  .px-xl-n4 {
    padding-left: -1.5rem !important; }
  .p-xl-n5 {
    padding: 3rem !important; }
  .pt-xl-n5,
  .py-xl-n5 {
    padding-top: -3rem !important; }
  .pr-xl-n5,
  .px-xl-n5 {
    padding-right: -3rem !important; }
  .pb-xl-n5,
  .py-xl-n5 {
    padding-bottom: -3rem !important; }
  .pl-xl-n5,
  .px-xl-n5 {
    padding-left: -3rem !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 400 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 500 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFF !important; }

.text-primary {
  color: #01507B !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #001f2f !important; }

.text-secondary {
  color: #CFE676 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #b8da35 !important; }

.text-success {
  color: #4CA325 !important; }

a.text-success:hover, a.text-success:focus {
  color: #2f6517 !important; }

.text-info {
  color: #8CA2B5 !important; }

a.text-info:hover, a.text-info:focus {
  color: #607c95 !important; }

.text-warning {
  color: #FDBD39 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #e79c02 !important; }

.text-danger {
  color: #E86B64 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #de2b21 !important; }

.text-light {
  color: #C2CFDB !important; }

a.text-light:hover, a.text-light:focus {
  color: #92a9bf !important; }

.text-dark {
  color: #17232C !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-brainimals-aqua {
  color: #11D1D2 !important; }

a.text-brainimals-aqua:hover, a.text-brainimals-aqua:focus {
  color: #0b8b8b !important; }

.text-brainimals-topaz {
  color: #0EB0B1 !important; }

a.text-brainimals-topaz:hover, a.text-brainimals-topaz:focus {
  color: #086a6a !important; }

.text-brainimals-blue-light {
  color: #6FCEED !important; }

a.text-brainimals-blue-light:hover, a.text-brainimals-blue-light:focus {
  color: #2bb7e5 !important; }

.text-brainimals-deep-sea-blue {
  color: #01507B !important; }

a.text-brainimals-deep-sea-blue:hover, a.text-brainimals-deep-sea-blue:focus {
  color: #001f2f !important; }

.text-brainimals-dark-cyan {
  color: #0B8E90 !important; }

a.text-brainimals-dark-cyan:hover, a.text-brainimals-dark-cyan:focus {
  color: #064849 !important; }

.text-brainimals-darker-cyan {
  color: #045E61 !important; }

a.text-brainimals-darker-cyan:hover, a.text-brainimals-darker-cyan:focus {
  color: #011718 !important; }

.text-brainimals-green-light {
  color: #CFE676 !important; }

a.text-brainimals-green-light:hover, a.text-brainimals-green-light:focus {
  color: #b8da35 !important; }

.text-brainimals-green {
  color: #4CA325 !important; }

a.text-brainimals-green:hover, a.text-brainimals-green:focus {
  color: #2f6517 !important; }

.text-brainimals-orange {
  color: #FDBD39 !important; }

a.text-brainimals-orange:hover, a.text-brainimals-orange:focus {
  color: #e79c02 !important; }

.text-brainimals-red {
  color: #E86B64 !important; }

a.text-brainimals-red:hover, a.text-brainimals-red:focus {
  color: #de2b21 !important; }

.text-brainimals-dark-grey-blue {
  color: #314251 !important; }

a.text-brainimals-dark-grey-blue:hover, a.text-brainimals-dark-grey-blue:focus {
  color: #141b21 !important; }

.text-brainimals-pale-grey-two {
  color: #DEE6EB !important; }

a.text-brainimals-pale-grey-two:hover, a.text-brainimals-pale-grey-two:focus {
  color: #aec2ce !important; }

.text-brainimals-pale-grey {
  color: #EDF2F4 !important; }

a.text-brainimals-pale-grey:hover, a.text-brainimals-pale-grey:focus {
  color: #bed0d7 !important; }

.text-brainimals-bluey-grey {
  color: #8CA2B5 !important; }

a.text-brainimals-bluey-grey:hover, a.text-brainimals-bluey-grey:focus {
  color: #607c95 !important; }

.text-brainimals-cloudy-blue {
  color: #C2CFDB !important; }

a.text-brainimals-cloudy-blue:hover, a.text-brainimals-cloudy-blue:focus {
  color: #92a9bf !important; }

.text-brainimals-blue-grey {
  color: #60768A !important; }

a.text-brainimals-blue-grey:hover, a.text-brainimals-blue-grey:focus {
  color: #414f5d !important; }

.text-brainimals-dark {
  color: #17232C !important; }

a.text-brainimals-dark:hover, a.text-brainimals-dark:focus {
  color: black !important; }

.text-body {
  color: #4E5457 !important; }

.text-muted {
  color: #898D8F !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.text-gray-100 {
  color: #EBECEC !important; }

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #c4c7c7 !important; }

.text-gray-200 {
  color: #D8D9DA !important; }

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #b1b3b5 !important; }

.text-gray-300 {
  color: #C4C6C7 !important; }

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #9da0a2 !important; }

.text-gray-400 {
  color: #B1B3B5 !important; }

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #8a8d90 !important; }

.text-gray-500 {
  color: #9DA0A2 !important; }

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #767a7c !important; }

.text-gray-600 {
  color: #898D8F !important; }

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #636768 !important; }

.text-gray-700 {
  color: #757A7C !important; }

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #505355 !important; }

.text-gray-800 {
  color: #62676A !important; }

a.text-gray-800:hover, a.text-gray-800:focus {
  color: #3d4042 !important; }

.text-gray-900 {
  color: #4E5457 !important; }

a.text-gray-900:hover, a.text-gray-900:focus {
  color: #2a2d2f !important; }

.font-weight-300 {
  font-weight: 300 !important; }

.font-weight-400 {
  font-weight: 400 !important; }

.font-weight-500 {
  font-weight: 500 !important; }

.font-weight-600 {
  font-weight: 600 !important; }

.font-weight-700 {
  font-weight: 700 !important; }

.font-weight-800 {
  font-weight: 800 !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important; }
  a:not(.btn):not(.keyboard-button):not(.keyboard-button__dragging::after):not(.exercise-card-action) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #9DA0A2;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #C4C6C7 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #C4C6C7; }
  .table .thead-dark th {
    color: inherit;
    border-color: #C4C6C7; } }

html, body {
  -webkit-tap-highlight-color: transparent; }

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  background-color: #11D1D2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision; }
  html *:not(input), html *:not(label),
  body *:not(input),
  body *:not(label) {
    cursor: default; }
  html *:not(input),
  body *:not(input) {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed; }
  #root > .invisible {
    display: none !important; }
  #root > div:not(.invisible) {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed; }

canvas {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.env-padding {
  padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left) !important;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important; }

.env-padding-left {
  padding-left: constant(safe-area-inset-left) !important;
  padding-left: env(safe-area-inset-left) !important; }

.env-padding-top {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important; }

.env-padding-right {
  padding-right: constant(safe-area-inset-right) !important;
  padding-right: env(safe-area-inset-right) !important; }

.env-padding-bottom, .swiper-container .swiper-pagination {
  padding-bottom: constant(safe-area-inset-bottom) !important;
  padding-bottom: env(safe-area-inset-bottom) !important; }

.env-margin {
  margin: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left) !important;
  margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important; }

.env-margin-left {
  margin-left: constant(safe-area-inset-left) !important;
  margin-left: env(safe-area-inset-left) !important; }

.env-margin-top {
  margin-top: 1rem;
  margin-top: constant(safe-area-inset-top) !important;
  margin-top: env(safe-area-inset-top) !important; }

.env-margin-right {
  margin-right: constant(safe-area-inset-right) !important;
  margin-right: env(safe-area-inset-right) !important; }

.env-margin-bottom {
  margin-bottom: constant(safe-area-inset-bottom) !important;
  margin-bottom: env(safe-area-inset-bottom) !important; }

.hr-main--top {
  border-top: 0.33333rem solid #045E61; }

.main-title {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3C4145), color-stop(99%, rgba(60, 65, 69, 0.8)));
  background-image: -webkit-linear-gradient(top, #3C4145 0%, rgba(60, 65, 69, 0.8) 99%);
  background-image: -o-linear-gradient(top, #3C4145 0%, rgba(60, 65, 69, 0.8) 99%);
  background-image: linear-gradient(-180deg, #3C4145 0%, rgba(60, 65, 69, 0.8) 99%); }

.injected-svg {
  height: 100%;
  width: 100%; }

.cursor-auto, .cursor-auto * {
  cursor: auto !important; }

.cursor-default, .exercise-card__disabled, .cursor-default *, .exercise-card__disabled * {
  cursor: default !important; }

.cursor-none, .cursor-none * {
  cursor: none !important; }

.cursor-context-menu, .cursor-context-menu * {
  cursor: context-menu !important; }

.cursor-help, .cursor-help * {
  cursor: help !important; }

.cursor-pointer, .exercise-card:not(.exercise-card__disabled), .cursor-pointer *, .exercise-card:not(.exercise-card__disabled) * {
  cursor: pointer !important; }

.cursor-progress, .cursor-progress * {
  cursor: progress !important; }

.cursor-wait, .cursor-wait * {
  cursor: wait !important; }

.cursor-cell, .cursor-cell * {
  cursor: cell !important; }

.cursor-crosshair, .cursor-crosshair * {
  cursor: crosshair !important; }

.cursor-text, .cursor-text * {
  cursor: text !important; }

.cursor-vertical-text, .cursor-vertical-text * {
  cursor: vertical-text !important; }

.cursor-alias, .cursor-alias * {
  cursor: alias !important; }

.cursor-copy, .cursor-copy * {
  cursor: copy !important; }

.cursor-move, .cursor-move * {
  cursor: move !important; }

.cursor-no-drop, .cursor-no-drop * {
  cursor: no-drop !important; }

.cursor-not-allowed, .cursor-not-allowed * {
  cursor: not-allowed !important; }

.cursor-e-resize, .cursor-e-resize * {
  cursor: e-resize !important; }

.cursor-n-resize, .cursor-n-resize * {
  cursor: n-resize !important; }

.cursor-ne-resize, .cursor-ne-resize * {
  cursor: ne-resize !important; }

.cursor-nw-resize, .cursor-nw-resize * {
  cursor: nw-resize !important; }

.cursor-s-resize, .cursor-s-resize * {
  cursor: s-resize !important; }

.cursor-se-resize, .cursor-se-resize * {
  cursor: se-resize !important; }

.cursor-sw-resize, .cursor-sw-resize * {
  cursor: sw-resize !important; }

.cursor-w-resize, .cursor-w-resize * {
  cursor: w-resize !important; }

.cursor-ew-resize, .cursor-ew-resize * {
  cursor: ew-resize !important; }

.cursor-ns-resize, .cursor-ns-resize * {
  cursor: ns-resize !important; }

.cursor-nesw-resize, .cursor-nesw-resize * {
  cursor: nesw-resize !important; }

.cursor-nwse-resize, .cursor-nwse-resize * {
  cursor: nwse-resize !important; }

.cursor-col-resize, .cursor-col-resize * {
  cursor: col-resize !important; }

.cursor-row-resize, .cursor-row-resize * {
  cursor: row-resize !important; }

.cursor-all-scroll, .cursor-all-scroll * {
  cursor: all-scroll !important; }

.cursor-zoom-in, .cursor-zoom-in * {
  cursor: -webkit-zoom-in !important;
  cursor: zoom-in !important; }

.cursor-zoom-out, .cursor-zoom-out * {
  cursor: -webkit-zoom-out !important;
  cursor: zoom-out !important; }

.cursor-grab, .cursor-grab * {
  cursor: -webkit-grab !important;
  cursor: grab !important; }

.cursor-grabbing, .cursor-grabbing * {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important; }

::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem; }

::-webkit-scrollbar-thumb {
  background: rgba(14, 176, 177, 0); }

::-webkit-scrollbar-track {
  background: rgba(17, 209, 210, 0); }

body {
  scrollbar-face-color: rgba(14, 176, 177, 0);
  scrollbar-track-color: rgba(17, 209, 210, 0); }

.overflow-visible {
  overflow: visible !important;
  -webkit-overflow-scrolling: touch; }

.overflow-x-visible {
  overflow-x: visible !important;
  -webkit-overflow-scrolling: touch; }

.overflow-y-visible {
  overflow-y: visible !important;
  -webkit-overflow-scrolling: touch; }

.overflow-hidden {
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch; }

.overflow-x-hidden {
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch; }

.overflow-y-hidden {
  overflow-y: hidden !important;
  -webkit-overflow-scrolling: touch; }

.overflow-scroll {
  overflow: scroll !important;
  -webkit-overflow-scrolling: touch; }

.overflow-x-scroll {
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch; }

.overflow-y-scroll {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch; }

.overflow-auto {
  overflow: auto !important;
  -webkit-overflow-scrolling: touch; }

.overflow-x-auto {
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch; }

.overflow-y-auto {
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch; }

.overflow-visible {
  overflow: visible !important;
  -webkit-overflow-scrolling: touch; }

.overflow-x-visible {
  overflow-x: visible !important;
  -webkit-overflow-scrolling: touch; }

.overflow-y-visible {
  overflow-y: visible !important;
  -webkit-overflow-scrolling: touch; }

.overflow-inherit {
  overflow: inherit !important;
  -webkit-overflow-scrolling: touch; }

.overflow-x-inherit {
  overflow-x: inherit !important;
  -webkit-overflow-scrolling: touch; }

.overflow-y-inherit {
  overflow-y: inherit !important;
  -webkit-overflow-scrolling: touch; }

.overflow-initial {
  overflow: visible !important;
  overflow: initial !important;
  -webkit-overflow-scrolling: touch; }

.overflow-x-initial {
  overflow-x: visible !important;
  overflow-x: initial !important;
  -webkit-overflow-scrolling: touch; }

.overflow-y-initial {
  overflow-y: visible !important;
  overflow-y: initial !important;
  -webkit-overflow-scrolling: touch; }

.overflow-unset {
  overflow: unset !important;
  -webkit-overflow-scrolling: touch; }

.overflow-x-unset {
  overflow-x: unset !important;
  -webkit-overflow-scrolling: touch; }

.overflow-y-unset {
  overflow-y: unset !important;
  -webkit-overflow-scrolling: touch; }

.squircle {
  width: 150px;
  height: 150px;
  background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='150' height='150' viewBox='0 0 150 150' enable-background='new 0 0 150 150' xml:space='preserve'%3E%3Cpath fill='%23ED2F5B' stroke='none' d='M 0,75 C 0,0 0,0 75,0 S 150,0 150,75 150,150 75,150 0,150 0,75'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 150px 150px; }

.no-shadow {
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.bg-backdrop {
  background-color: rgba(1, 80, 123, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop ::-webkit-scrollbar-thumb {
    background: rgba(1, 80, 123, 0.75); }
  .bg-backdrop ::-webkit-scrollbar-track {
    background: rgba(1, 80, 123, 0.25); }
  .bg-backdrop body {
    scrollbar-face-color: rgba(1, 80, 123, 0.75);
    scrollbar-track-color: rgba(1, 80, 123, 0.25); }

.bg-backdrop-primary {
  background-color: rgba(1, 80, 123, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-primary ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-primary ::-webkit-scrollbar-thumb {
    background: rgba(1, 80, 123, 0.75); }
  .bg-backdrop-primary ::-webkit-scrollbar-track {
    background: rgba(1, 80, 123, 0.25); }
  .bg-backdrop-primary body {
    scrollbar-face-color: rgba(1, 80, 123, 0.75);
    scrollbar-track-color: rgba(1, 80, 123, 0.25); }

.bg-backdrop-primary__pale {
  background-color: rgba(1, 80, 123, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-primary__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-primary__pale ::-webkit-scrollbar-thumb {
    background: rgba(1, 80, 123, 0.75); }
  .bg-backdrop-primary__pale ::-webkit-scrollbar-track {
    background: rgba(1, 80, 123, 0.25); }
  .bg-backdrop-primary__pale body {
    scrollbar-face-color: rgba(1, 80, 123, 0.75);
    scrollbar-track-color: rgba(1, 80, 123, 0.25); }

.bg-backdrop-secondary {
  background-color: rgba(207, 230, 118, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-secondary ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-secondary ::-webkit-scrollbar-thumb {
    background: rgba(207, 230, 118, 0.75); }
  .bg-backdrop-secondary ::-webkit-scrollbar-track {
    background: rgba(207, 230, 118, 0.25); }
  .bg-backdrop-secondary body {
    scrollbar-face-color: rgba(207, 230, 118, 0.75);
    scrollbar-track-color: rgba(207, 230, 118, 0.25); }

.bg-backdrop-secondary__pale {
  background-color: rgba(207, 230, 118, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-secondary__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-secondary__pale ::-webkit-scrollbar-thumb {
    background: rgba(207, 230, 118, 0.75); }
  .bg-backdrop-secondary__pale ::-webkit-scrollbar-track {
    background: rgba(207, 230, 118, 0.25); }
  .bg-backdrop-secondary__pale body {
    scrollbar-face-color: rgba(207, 230, 118, 0.75);
    scrollbar-track-color: rgba(207, 230, 118, 0.25); }

.bg-backdrop-success {
  background-color: rgba(76, 163, 37, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-success ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-success ::-webkit-scrollbar-thumb {
    background: rgba(76, 163, 37, 0.75); }
  .bg-backdrop-success ::-webkit-scrollbar-track {
    background: rgba(76, 163, 37, 0.25); }
  .bg-backdrop-success body {
    scrollbar-face-color: rgba(76, 163, 37, 0.75);
    scrollbar-track-color: rgba(76, 163, 37, 0.25); }

.bg-backdrop-success__pale {
  background-color: rgba(76, 163, 37, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-success__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-success__pale ::-webkit-scrollbar-thumb {
    background: rgba(76, 163, 37, 0.75); }
  .bg-backdrop-success__pale ::-webkit-scrollbar-track {
    background: rgba(76, 163, 37, 0.25); }
  .bg-backdrop-success__pale body {
    scrollbar-face-color: rgba(76, 163, 37, 0.75);
    scrollbar-track-color: rgba(76, 163, 37, 0.25); }

.bg-backdrop-info {
  background-color: rgba(140, 162, 181, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-info ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-info ::-webkit-scrollbar-thumb {
    background: rgba(140, 162, 181, 0.75); }
  .bg-backdrop-info ::-webkit-scrollbar-track {
    background: rgba(140, 162, 181, 0.25); }
  .bg-backdrop-info body {
    scrollbar-face-color: rgba(140, 162, 181, 0.75);
    scrollbar-track-color: rgba(140, 162, 181, 0.25); }

.bg-backdrop-info__pale {
  background-color: rgba(140, 162, 181, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-info__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-info__pale ::-webkit-scrollbar-thumb {
    background: rgba(140, 162, 181, 0.75); }
  .bg-backdrop-info__pale ::-webkit-scrollbar-track {
    background: rgba(140, 162, 181, 0.25); }
  .bg-backdrop-info__pale body {
    scrollbar-face-color: rgba(140, 162, 181, 0.75);
    scrollbar-track-color: rgba(140, 162, 181, 0.25); }

.bg-backdrop-warning {
  background-color: rgba(253, 189, 57, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-warning ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-warning ::-webkit-scrollbar-thumb {
    background: rgba(253, 189, 57, 0.75); }
  .bg-backdrop-warning ::-webkit-scrollbar-track {
    background: rgba(253, 189, 57, 0.25); }
  .bg-backdrop-warning body {
    scrollbar-face-color: rgba(253, 189, 57, 0.75);
    scrollbar-track-color: rgba(253, 189, 57, 0.25); }

.bg-backdrop-warning__pale {
  background-color: rgba(253, 189, 57, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-warning__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-warning__pale ::-webkit-scrollbar-thumb {
    background: rgba(253, 189, 57, 0.75); }
  .bg-backdrop-warning__pale ::-webkit-scrollbar-track {
    background: rgba(253, 189, 57, 0.25); }
  .bg-backdrop-warning__pale body {
    scrollbar-face-color: rgba(253, 189, 57, 0.75);
    scrollbar-track-color: rgba(253, 189, 57, 0.25); }

.bg-backdrop-danger {
  background-color: rgba(232, 107, 100, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-danger ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-danger ::-webkit-scrollbar-thumb {
    background: rgba(232, 107, 100, 0.75); }
  .bg-backdrop-danger ::-webkit-scrollbar-track {
    background: rgba(232, 107, 100, 0.25); }
  .bg-backdrop-danger body {
    scrollbar-face-color: rgba(232, 107, 100, 0.75);
    scrollbar-track-color: rgba(232, 107, 100, 0.25); }

.bg-backdrop-danger__pale {
  background-color: rgba(232, 107, 100, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-danger__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-danger__pale ::-webkit-scrollbar-thumb {
    background: rgba(232, 107, 100, 0.75); }
  .bg-backdrop-danger__pale ::-webkit-scrollbar-track {
    background: rgba(232, 107, 100, 0.25); }
  .bg-backdrop-danger__pale body {
    scrollbar-face-color: rgba(232, 107, 100, 0.75);
    scrollbar-track-color: rgba(232, 107, 100, 0.25); }

.bg-backdrop-light {
  background-color: rgba(194, 207, 219, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-light ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-light ::-webkit-scrollbar-thumb {
    background: rgba(194, 207, 219, 0.75); }
  .bg-backdrop-light ::-webkit-scrollbar-track {
    background: rgba(194, 207, 219, 0.25); }
  .bg-backdrop-light body {
    scrollbar-face-color: rgba(194, 207, 219, 0.75);
    scrollbar-track-color: rgba(194, 207, 219, 0.25); }

.bg-backdrop-light__pale {
  background-color: rgba(194, 207, 219, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-light__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-light__pale ::-webkit-scrollbar-thumb {
    background: rgba(194, 207, 219, 0.75); }
  .bg-backdrop-light__pale ::-webkit-scrollbar-track {
    background: rgba(194, 207, 219, 0.25); }
  .bg-backdrop-light__pale body {
    scrollbar-face-color: rgba(194, 207, 219, 0.75);
    scrollbar-track-color: rgba(194, 207, 219, 0.25); }

.bg-backdrop-dark {
  background-color: rgba(23, 35, 44, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-dark ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-dark ::-webkit-scrollbar-thumb {
    background: rgba(23, 35, 44, 0.75); }
  .bg-backdrop-dark ::-webkit-scrollbar-track {
    background: rgba(23, 35, 44, 0.25); }
  .bg-backdrop-dark body {
    scrollbar-face-color: rgba(23, 35, 44, 0.75);
    scrollbar-track-color: rgba(23, 35, 44, 0.25); }

.bg-backdrop-dark__pale {
  background-color: rgba(23, 35, 44, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-dark__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-dark__pale ::-webkit-scrollbar-thumb {
    background: rgba(23, 35, 44, 0.75); }
  .bg-backdrop-dark__pale ::-webkit-scrollbar-track {
    background: rgba(23, 35, 44, 0.25); }
  .bg-backdrop-dark__pale body {
    scrollbar-face-color: rgba(23, 35, 44, 0.75);
    scrollbar-track-color: rgba(23, 35, 44, 0.25); }

.bg-backdrop-brainimals-aqua {
  background-color: rgba(17, 209, 210, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-aqua ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-aqua ::-webkit-scrollbar-thumb {
    background: rgba(17, 209, 210, 0.75); }
  .bg-backdrop-brainimals-aqua ::-webkit-scrollbar-track {
    background: rgba(17, 209, 210, 0.25); }
  .bg-backdrop-brainimals-aqua body {
    scrollbar-face-color: rgba(17, 209, 210, 0.75);
    scrollbar-track-color: rgba(17, 209, 210, 0.25); }

.bg-backdrop-brainimals-aqua__pale {
  background-color: rgba(17, 209, 210, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-aqua__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-aqua__pale ::-webkit-scrollbar-thumb {
    background: rgba(17, 209, 210, 0.75); }
  .bg-backdrop-brainimals-aqua__pale ::-webkit-scrollbar-track {
    background: rgba(17, 209, 210, 0.25); }
  .bg-backdrop-brainimals-aqua__pale body {
    scrollbar-face-color: rgba(17, 209, 210, 0.75);
    scrollbar-track-color: rgba(17, 209, 210, 0.25); }

.bg-backdrop-brainimals-topaz {
  background-color: rgba(14, 176, 177, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-topaz ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-topaz ::-webkit-scrollbar-thumb {
    background: rgba(14, 176, 177, 0.75); }
  .bg-backdrop-brainimals-topaz ::-webkit-scrollbar-track {
    background: rgba(14, 176, 177, 0.25); }
  .bg-backdrop-brainimals-topaz body {
    scrollbar-face-color: rgba(14, 176, 177, 0.75);
    scrollbar-track-color: rgba(14, 176, 177, 0.25); }

.bg-backdrop-brainimals-topaz__pale {
  background-color: rgba(14, 176, 177, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-topaz__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-topaz__pale ::-webkit-scrollbar-thumb {
    background: rgba(14, 176, 177, 0.75); }
  .bg-backdrop-brainimals-topaz__pale ::-webkit-scrollbar-track {
    background: rgba(14, 176, 177, 0.25); }
  .bg-backdrop-brainimals-topaz__pale body {
    scrollbar-face-color: rgba(14, 176, 177, 0.75);
    scrollbar-track-color: rgba(14, 176, 177, 0.25); }

.bg-backdrop-brainimals-blue-light {
  background-color: rgba(111, 206, 237, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-blue-light ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-blue-light ::-webkit-scrollbar-thumb {
    background: rgba(111, 206, 237, 0.75); }
  .bg-backdrop-brainimals-blue-light ::-webkit-scrollbar-track {
    background: rgba(111, 206, 237, 0.25); }
  .bg-backdrop-brainimals-blue-light body {
    scrollbar-face-color: rgba(111, 206, 237, 0.75);
    scrollbar-track-color: rgba(111, 206, 237, 0.25); }

.bg-backdrop-brainimals-blue-light__pale {
  background-color: rgba(111, 206, 237, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-blue-light__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-blue-light__pale ::-webkit-scrollbar-thumb {
    background: rgba(111, 206, 237, 0.75); }
  .bg-backdrop-brainimals-blue-light__pale ::-webkit-scrollbar-track {
    background: rgba(111, 206, 237, 0.25); }
  .bg-backdrop-brainimals-blue-light__pale body {
    scrollbar-face-color: rgba(111, 206, 237, 0.75);
    scrollbar-track-color: rgba(111, 206, 237, 0.25); }

.bg-backdrop-brainimals-deep-sea-blue {
  background-color: rgba(1, 80, 123, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-deep-sea-blue ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-deep-sea-blue ::-webkit-scrollbar-thumb {
    background: rgba(1, 80, 123, 0.75); }
  .bg-backdrop-brainimals-deep-sea-blue ::-webkit-scrollbar-track {
    background: rgba(1, 80, 123, 0.25); }
  .bg-backdrop-brainimals-deep-sea-blue body {
    scrollbar-face-color: rgba(1, 80, 123, 0.75);
    scrollbar-track-color: rgba(1, 80, 123, 0.25); }

.bg-backdrop-brainimals-deep-sea-blue__pale {
  background-color: rgba(1, 80, 123, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-deep-sea-blue__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-deep-sea-blue__pale ::-webkit-scrollbar-thumb {
    background: rgba(1, 80, 123, 0.75); }
  .bg-backdrop-brainimals-deep-sea-blue__pale ::-webkit-scrollbar-track {
    background: rgba(1, 80, 123, 0.25); }
  .bg-backdrop-brainimals-deep-sea-blue__pale body {
    scrollbar-face-color: rgba(1, 80, 123, 0.75);
    scrollbar-track-color: rgba(1, 80, 123, 0.25); }

.bg-backdrop-brainimals-dark-cyan {
  background-color: rgba(11, 142, 144, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-dark-cyan ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-dark-cyan ::-webkit-scrollbar-thumb {
    background: rgba(11, 142, 144, 0.75); }
  .bg-backdrop-brainimals-dark-cyan ::-webkit-scrollbar-track {
    background: rgba(11, 142, 144, 0.25); }
  .bg-backdrop-brainimals-dark-cyan body {
    scrollbar-face-color: rgba(11, 142, 144, 0.75);
    scrollbar-track-color: rgba(11, 142, 144, 0.25); }

.bg-backdrop-brainimals-dark-cyan__pale {
  background-color: rgba(11, 142, 144, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-dark-cyan__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-dark-cyan__pale ::-webkit-scrollbar-thumb {
    background: rgba(11, 142, 144, 0.75); }
  .bg-backdrop-brainimals-dark-cyan__pale ::-webkit-scrollbar-track {
    background: rgba(11, 142, 144, 0.25); }
  .bg-backdrop-brainimals-dark-cyan__pale body {
    scrollbar-face-color: rgba(11, 142, 144, 0.75);
    scrollbar-track-color: rgba(11, 142, 144, 0.25); }

.bg-backdrop-brainimals-darker-cyan {
  background-color: rgba(4, 94, 97, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-darker-cyan ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-darker-cyan ::-webkit-scrollbar-thumb {
    background: rgba(4, 94, 97, 0.75); }
  .bg-backdrop-brainimals-darker-cyan ::-webkit-scrollbar-track {
    background: rgba(4, 94, 97, 0.25); }
  .bg-backdrop-brainimals-darker-cyan body {
    scrollbar-face-color: rgba(4, 94, 97, 0.75);
    scrollbar-track-color: rgba(4, 94, 97, 0.25); }

.bg-backdrop-brainimals-darker-cyan__pale {
  background-color: rgba(4, 94, 97, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-darker-cyan__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-darker-cyan__pale ::-webkit-scrollbar-thumb {
    background: rgba(4, 94, 97, 0.75); }
  .bg-backdrop-brainimals-darker-cyan__pale ::-webkit-scrollbar-track {
    background: rgba(4, 94, 97, 0.25); }
  .bg-backdrop-brainimals-darker-cyan__pale body {
    scrollbar-face-color: rgba(4, 94, 97, 0.75);
    scrollbar-track-color: rgba(4, 94, 97, 0.25); }

.bg-backdrop-brainimals-green-light {
  background-color: rgba(207, 230, 118, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-green-light ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-green-light ::-webkit-scrollbar-thumb {
    background: rgba(207, 230, 118, 0.75); }
  .bg-backdrop-brainimals-green-light ::-webkit-scrollbar-track {
    background: rgba(207, 230, 118, 0.25); }
  .bg-backdrop-brainimals-green-light body {
    scrollbar-face-color: rgba(207, 230, 118, 0.75);
    scrollbar-track-color: rgba(207, 230, 118, 0.25); }

.bg-backdrop-brainimals-green-light__pale {
  background-color: rgba(207, 230, 118, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-green-light__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-green-light__pale ::-webkit-scrollbar-thumb {
    background: rgba(207, 230, 118, 0.75); }
  .bg-backdrop-brainimals-green-light__pale ::-webkit-scrollbar-track {
    background: rgba(207, 230, 118, 0.25); }
  .bg-backdrop-brainimals-green-light__pale body {
    scrollbar-face-color: rgba(207, 230, 118, 0.75);
    scrollbar-track-color: rgba(207, 230, 118, 0.25); }

.bg-backdrop-brainimals-green {
  background-color: rgba(76, 163, 37, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-green ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-green ::-webkit-scrollbar-thumb {
    background: rgba(76, 163, 37, 0.75); }
  .bg-backdrop-brainimals-green ::-webkit-scrollbar-track {
    background: rgba(76, 163, 37, 0.25); }
  .bg-backdrop-brainimals-green body {
    scrollbar-face-color: rgba(76, 163, 37, 0.75);
    scrollbar-track-color: rgba(76, 163, 37, 0.25); }

.bg-backdrop-brainimals-green__pale {
  background-color: rgba(76, 163, 37, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-green__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-green__pale ::-webkit-scrollbar-thumb {
    background: rgba(76, 163, 37, 0.75); }
  .bg-backdrop-brainimals-green__pale ::-webkit-scrollbar-track {
    background: rgba(76, 163, 37, 0.25); }
  .bg-backdrop-brainimals-green__pale body {
    scrollbar-face-color: rgba(76, 163, 37, 0.75);
    scrollbar-track-color: rgba(76, 163, 37, 0.25); }

.bg-backdrop-brainimals-orange {
  background-color: rgba(253, 189, 57, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-orange ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-orange ::-webkit-scrollbar-thumb {
    background: rgba(253, 189, 57, 0.75); }
  .bg-backdrop-brainimals-orange ::-webkit-scrollbar-track {
    background: rgba(253, 189, 57, 0.25); }
  .bg-backdrop-brainimals-orange body {
    scrollbar-face-color: rgba(253, 189, 57, 0.75);
    scrollbar-track-color: rgba(253, 189, 57, 0.25); }

.bg-backdrop-brainimals-orange__pale {
  background-color: rgba(253, 189, 57, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-orange__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-orange__pale ::-webkit-scrollbar-thumb {
    background: rgba(253, 189, 57, 0.75); }
  .bg-backdrop-brainimals-orange__pale ::-webkit-scrollbar-track {
    background: rgba(253, 189, 57, 0.25); }
  .bg-backdrop-brainimals-orange__pale body {
    scrollbar-face-color: rgba(253, 189, 57, 0.75);
    scrollbar-track-color: rgba(253, 189, 57, 0.25); }

.bg-backdrop-brainimals-red {
  background-color: rgba(232, 107, 100, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-red ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-red ::-webkit-scrollbar-thumb {
    background: rgba(232, 107, 100, 0.75); }
  .bg-backdrop-brainimals-red ::-webkit-scrollbar-track {
    background: rgba(232, 107, 100, 0.25); }
  .bg-backdrop-brainimals-red body {
    scrollbar-face-color: rgba(232, 107, 100, 0.75);
    scrollbar-track-color: rgba(232, 107, 100, 0.25); }

.bg-backdrop-brainimals-red__pale {
  background-color: rgba(232, 107, 100, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-red__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-red__pale ::-webkit-scrollbar-thumb {
    background: rgba(232, 107, 100, 0.75); }
  .bg-backdrop-brainimals-red__pale ::-webkit-scrollbar-track {
    background: rgba(232, 107, 100, 0.25); }
  .bg-backdrop-brainimals-red__pale body {
    scrollbar-face-color: rgba(232, 107, 100, 0.75);
    scrollbar-track-color: rgba(232, 107, 100, 0.25); }

.bg-backdrop-brainimals-dark-grey-blue {
  background-color: rgba(49, 66, 81, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-dark-grey-blue ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-dark-grey-blue ::-webkit-scrollbar-thumb {
    background: rgba(49, 66, 81, 0.75); }
  .bg-backdrop-brainimals-dark-grey-blue ::-webkit-scrollbar-track {
    background: rgba(49, 66, 81, 0.25); }
  .bg-backdrop-brainimals-dark-grey-blue body {
    scrollbar-face-color: rgba(49, 66, 81, 0.75);
    scrollbar-track-color: rgba(49, 66, 81, 0.25); }

.bg-backdrop-brainimals-dark-grey-blue__pale {
  background-color: rgba(49, 66, 81, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-dark-grey-blue__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-dark-grey-blue__pale ::-webkit-scrollbar-thumb {
    background: rgba(49, 66, 81, 0.75); }
  .bg-backdrop-brainimals-dark-grey-blue__pale ::-webkit-scrollbar-track {
    background: rgba(49, 66, 81, 0.25); }
  .bg-backdrop-brainimals-dark-grey-blue__pale body {
    scrollbar-face-color: rgba(49, 66, 81, 0.75);
    scrollbar-track-color: rgba(49, 66, 81, 0.25); }

.bg-backdrop-brainimals-pale-grey-two {
  background-color: rgba(222, 230, 235, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-pale-grey-two ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-pale-grey-two ::-webkit-scrollbar-thumb {
    background: rgba(222, 230, 235, 0.75); }
  .bg-backdrop-brainimals-pale-grey-two ::-webkit-scrollbar-track {
    background: rgba(222, 230, 235, 0.25); }
  .bg-backdrop-brainimals-pale-grey-two body {
    scrollbar-face-color: rgba(222, 230, 235, 0.75);
    scrollbar-track-color: rgba(222, 230, 235, 0.25); }

.bg-backdrop-brainimals-pale-grey-two__pale {
  background-color: rgba(222, 230, 235, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-pale-grey-two__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-pale-grey-two__pale ::-webkit-scrollbar-thumb {
    background: rgba(222, 230, 235, 0.75); }
  .bg-backdrop-brainimals-pale-grey-two__pale ::-webkit-scrollbar-track {
    background: rgba(222, 230, 235, 0.25); }
  .bg-backdrop-brainimals-pale-grey-two__pale body {
    scrollbar-face-color: rgba(222, 230, 235, 0.75);
    scrollbar-track-color: rgba(222, 230, 235, 0.25); }

.bg-backdrop-brainimals-pale-grey {
  background-color: rgba(237, 242, 244, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-pale-grey ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-pale-grey ::-webkit-scrollbar-thumb {
    background: rgba(237, 242, 244, 0.75); }
  .bg-backdrop-brainimals-pale-grey ::-webkit-scrollbar-track {
    background: rgba(237, 242, 244, 0.25); }
  .bg-backdrop-brainimals-pale-grey body {
    scrollbar-face-color: rgba(237, 242, 244, 0.75);
    scrollbar-track-color: rgba(237, 242, 244, 0.25); }

.bg-backdrop-brainimals-pale-grey__pale {
  background-color: rgba(237, 242, 244, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-pale-grey__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-pale-grey__pale ::-webkit-scrollbar-thumb {
    background: rgba(237, 242, 244, 0.75); }
  .bg-backdrop-brainimals-pale-grey__pale ::-webkit-scrollbar-track {
    background: rgba(237, 242, 244, 0.25); }
  .bg-backdrop-brainimals-pale-grey__pale body {
    scrollbar-face-color: rgba(237, 242, 244, 0.75);
    scrollbar-track-color: rgba(237, 242, 244, 0.25); }

.bg-backdrop-brainimals-bluey-grey {
  background-color: rgba(140, 162, 181, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-bluey-grey ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-bluey-grey ::-webkit-scrollbar-thumb {
    background: rgba(140, 162, 181, 0.75); }
  .bg-backdrop-brainimals-bluey-grey ::-webkit-scrollbar-track {
    background: rgba(140, 162, 181, 0.25); }
  .bg-backdrop-brainimals-bluey-grey body {
    scrollbar-face-color: rgba(140, 162, 181, 0.75);
    scrollbar-track-color: rgba(140, 162, 181, 0.25); }

.bg-backdrop-brainimals-bluey-grey__pale {
  background-color: rgba(140, 162, 181, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-bluey-grey__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-bluey-grey__pale ::-webkit-scrollbar-thumb {
    background: rgba(140, 162, 181, 0.75); }
  .bg-backdrop-brainimals-bluey-grey__pale ::-webkit-scrollbar-track {
    background: rgba(140, 162, 181, 0.25); }
  .bg-backdrop-brainimals-bluey-grey__pale body {
    scrollbar-face-color: rgba(140, 162, 181, 0.75);
    scrollbar-track-color: rgba(140, 162, 181, 0.25); }

.bg-backdrop-brainimals-cloudy-blue {
  background-color: rgba(194, 207, 219, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-cloudy-blue ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-cloudy-blue ::-webkit-scrollbar-thumb {
    background: rgba(194, 207, 219, 0.75); }
  .bg-backdrop-brainimals-cloudy-blue ::-webkit-scrollbar-track {
    background: rgba(194, 207, 219, 0.25); }
  .bg-backdrop-brainimals-cloudy-blue body {
    scrollbar-face-color: rgba(194, 207, 219, 0.75);
    scrollbar-track-color: rgba(194, 207, 219, 0.25); }

.bg-backdrop-brainimals-cloudy-blue__pale {
  background-color: rgba(194, 207, 219, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-cloudy-blue__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-cloudy-blue__pale ::-webkit-scrollbar-thumb {
    background: rgba(194, 207, 219, 0.75); }
  .bg-backdrop-brainimals-cloudy-blue__pale ::-webkit-scrollbar-track {
    background: rgba(194, 207, 219, 0.25); }
  .bg-backdrop-brainimals-cloudy-blue__pale body {
    scrollbar-face-color: rgba(194, 207, 219, 0.75);
    scrollbar-track-color: rgba(194, 207, 219, 0.25); }

.bg-backdrop-brainimals-blue-grey {
  background-color: rgba(96, 118, 138, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-blue-grey ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-blue-grey ::-webkit-scrollbar-thumb {
    background: rgba(96, 118, 138, 0.75); }
  .bg-backdrop-brainimals-blue-grey ::-webkit-scrollbar-track {
    background: rgba(96, 118, 138, 0.25); }
  .bg-backdrop-brainimals-blue-grey body {
    scrollbar-face-color: rgba(96, 118, 138, 0.75);
    scrollbar-track-color: rgba(96, 118, 138, 0.25); }

.bg-backdrop-brainimals-blue-grey__pale {
  background-color: rgba(96, 118, 138, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-blue-grey__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-blue-grey__pale ::-webkit-scrollbar-thumb {
    background: rgba(96, 118, 138, 0.75); }
  .bg-backdrop-brainimals-blue-grey__pale ::-webkit-scrollbar-track {
    background: rgba(96, 118, 138, 0.25); }
  .bg-backdrop-brainimals-blue-grey__pale body {
    scrollbar-face-color: rgba(96, 118, 138, 0.75);
    scrollbar-track-color: rgba(96, 118, 138, 0.25); }

.bg-backdrop-brainimals-dark {
  background-color: rgba(23, 35, 44, 0.85);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%); }
  .bg-backdrop-brainimals-dark ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-dark ::-webkit-scrollbar-thumb {
    background: rgba(23, 35, 44, 0.75); }
  .bg-backdrop-brainimals-dark ::-webkit-scrollbar-track {
    background: rgba(23, 35, 44, 0.25); }
  .bg-backdrop-brainimals-dark body {
    scrollbar-face-color: rgba(23, 35, 44, 0.75);
    scrollbar-track-color: rgba(23, 35, 44, 0.25); }

.bg-backdrop-brainimals-dark__pale {
  background-color: rgba(23, 35, 44, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
          backdrop-filter: blur(20px) saturate(150%); }
  .bg-backdrop-brainimals-dark__pale ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem; }
  .bg-backdrop-brainimals-dark__pale ::-webkit-scrollbar-thumb {
    background: rgba(23, 35, 44, 0.75); }
  .bg-backdrop-brainimals-dark__pale ::-webkit-scrollbar-track {
    background: rgba(23, 35, 44, 0.25); }
  .bg-backdrop-brainimals-dark__pale body {
    scrollbar-face-color: rgba(23, 35, 44, 0.75);
    scrollbar-track-color: rgba(23, 35, 44, 0.25); }

.zindex-dropdown {
  z-index: 1000; }

.zindex-sticky {
  z-index: 1020; }

.zindex-fixed {
  z-index: 1030; }

.zindex-backdrop {
  z-index: 1800; }

.zindex-action {
  z-index: 1800; }

.zindex-modal {
  z-index: 1050; }

.zindex-popover {
  z-index: 1060; }

.zindex-tooltip {
  z-index: 1070; }

.font-size-base {
  font-size: 1rem; }

.font-size-lg {
  font-size: 1.4rem; }

.font-size-md {
  font-size: 1.3rem; }

.font-size-sm {
  font-size: 0.875rem; }

.swiper-container .swiper-wrapper .swiper-slide {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  width: 70%; }
  @media (prefers-reduced-motion: reduce) {
    .swiper-container .swiper-wrapper .swiper-slide {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  @media (min-width: 576px) {
    .swiper-container .swiper-wrapper .swiper-slide {
      width: 60%; } }
  @media (min-width: 768px) {
    .swiper-container .swiper-wrapper .swiper-slide {
      width: 50%; } }
  .swiper-container .swiper-wrapper .swiper-slide-active {
    -webkit-transform: scale(1);
            transform: scale(1); }

.swiper-container .swiper-pagination-bullets {
  bottom: 1.5rem; }
  .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin-left: 9px;
    margin-right: 9px;
    border: none;
    background-color: rgba(237, 242, 244, 0.7);
    padding: 0;
    -webkit-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out; }
    .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet:hover {
      cursor: pointer;
      border: none;
      background-color: #FFF; }
    .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet:focus {
      outline: 0; }
    .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background-color: #FFF;
      width: 18px; }

.datepicker-popper[data-placement^="bottom"] .datepicker__triangle,
.datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .datepicker-popper[data-placement^="top"] .datepicker__triangle,
.datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .datepicker__year-read-vie--down-arrow,
.react-datepicker__year-read-view--down-arrow,
.datepicker__month-read-vie--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.datepicker__month-year-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  position: absolute;
  margin-left: -8px; }
  .datepicker-popper[data-placement^="bottom"] .datepicker__triangle,
  .datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"] .datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .datepicker-popper[data-placement^="top"] .datepicker__triangle,
  .datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="top"] .datepicker__triangle,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .datepicker__year-read-vie--down-arrow,
  .react-datepicker__year-read-view--down-arrow,
  .datepicker__month-read-vie--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .datepicker__month-year-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .datepicker-popper[data-placement^="bottom"] .datepicker__triangle::before,
  .datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .datepicker-popper[data-placement^="top"] .datepicker__triangle::before,
  .datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"] .datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .datepicker__year-read-vie--down-arrow::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .datepicker__month-read-vie--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .datepicker__month-year-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    position: absolute;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    width: 1px;
    height: 0;
    border: 8px solid transparent; }
  .datepicker-popper[data-placement^="bottom"] .datepicker__triangle::before,
  .datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .datepicker-popper[data-placement^="top"] .datepicker__triangle::before,
  .datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"] .datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .datepicker__year-read-vie--down-arrow::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .datepicker__month-read-vie--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .datepicker__month-year-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    left: -8px;
    z-index: -1;
    content: "";
    border-width: 8px;
    border-bottom-color: #C4C6C7; }

.datepicker-popper[data-placement^="bottom"] .datepicker__triangle,
.datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }
  .datepicker-popper[data-placement^="bottom"] .datepicker__triangle,
  .datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"] .datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .datepicker-popper[data-placement^="bottom"] .datepicker__triangle::before,
  .datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #FFF; }
  .datepicker-popper[data-placement^="bottom"] .datepicker__triangle::before,
  .datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #C4C6C7; }

.datepicker-popper[data-placement^="top"] .datepicker__triangle,
.datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .datepicker__year-read-vie--down-arrow,
.react-datepicker__year-read-view--down-arrow,
.datepicker__month-read-vie--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.datepicker__month-year-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px; }
  .datepicker-popper[data-placement^="top"] .datepicker__triangle,
  .datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="top"] .datepicker__triangle,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .datepicker__year-read-vie--down-arrow,
  .react-datepicker__year-read-view--down-arrow,
  .datepicker__month-read-vie--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .datepicker__month-year-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .datepicker-popper[data-placement^="top"] .datepicker__triangle::before,
  .datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"] .datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .datepicker__year-read-vie--down-arrow::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .datepicker__month-read-vie--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .datepicker__month-year-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-top-color: #FFF;
    border-bottom: none; }
  .datepicker-popper[data-placement^="top"] .datepicker__triangle::before,
  .datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"] .datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .datepicker__year-read-vie--down-arrow::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .datepicker__month-read-vie--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .datepicker__month-year-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #C4C6C7; }

.datepicker-wrapper,
.react-datepicker-wrapper {
  display: block; }

.datepicker,
.react-datepicker {
  position: relative;
  display: inline-block;
  font-family: "Quicksand", "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.8rem;
  color: #314251;
  background-color: #FFF;
  border: 1px solid #C4C6C7;
  border-radius: 0.25rem; }

@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
  .datepicker,
  .react-datepicker {
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(20px) brightness(115%) saturate(150%);
            backdrop-filter: blur(20px) brightness(115%) saturate(150%); } }

.datepicker--time-only .datepicker__triangle,
.datepicker--time-only .react-datepicker__triangle,
.react-datepicker--time-only .datepicker__triangle,
.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.datepicker--time-only .datepicker__time-container,
.datepicker--time-only .react-datepicker__time-container,
.react-datepicker--time-only .datepicker__time-container,
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.datepicker--time-only .datepicker__time,
.datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .datepicker__time,
.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem; }

.datepicker--time-only .datepicker__time-box,
.datepicker--time-only .react-datepicker__time-box,
.react-datepicker--time-only .datepicker__time-box,
.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem; }

.datepicker__triangle,
.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.datepicker-popper,
.react-datepicker-popper {
  z-index: 1; }
  .datepicker-popper[data-placement^="bottom"],
  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px; }
  .datepicker-popper[data-placement^="top"],
  .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px; }
  .datepicker-popper[data-placement^="right"],
  .react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px; }
    .datepicker-popper[data-placement^="right"] .datepicker__triangle,
    .datepicker-popper[data-placement^="right"] .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^="right"] .datepicker__triangle,
    .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
      right: 42px;
      left: auto; }
  .datepicker-popper[data-placement^="left"],
  .react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px; }
    .datepicker-popper[data-placement^="left"] .datepicker__triangle,
    .datepicker-popper[data-placement^="left"] .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^="left"] .datepicker__triangle,
    .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
      right: auto;
      left: 42px; }

.datepicker__header,
.react-datepicker__header {
  position: relative;
  padding-top: 8px;
  text-align: center;
  background-color: #FFF;
  border-bottom: 1px solid #C4C6C7;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }
  .datepicker__header--time,
  .react-datepicker__header--time {
    padding-right: 5px;
    padding-bottom: 8px;
    padding-left: 5px; }

.datepicker__year-dropdown-container--selec,
.react-datepicker__year-dropdown-container--select,
.datepicker__month-dropdown-container--selec,
.react-datepicker__month-dropdown-container--select,
.datepicker__month-year-dropdown-container--selec,
.react-datepicker__month-year-dropdown-container--select,
.datepicker__year-dropdown-container--scrol,
.react-datepicker__year-dropdown-container--scroll,
.datepicker__month-dropdown-container--scrol,
.react-datepicker__month-dropdown-container--scroll,
.datepicker__month-year-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.datepicker__current-mont,
.react-datepicker__current-month,
.datepicker-time__header,
.react-datepicker-time__header {
  margin-top: 0;
  font-size: 0.944rem;
  font-weight: 700;
  color: #01507B; }

.datepicker-time__header,
.react-datepicker-time__header {
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap; }

.datepicker__navigation,
.react-datepicker__navigation {
  position: absolute;
  top: 10px;
  z-index: 1;
  width: 0;
  width: 10px;
  height: 10px;
  padding: 0;
  overflow: hidden;
  line-height: 1.7rem;
  text-align: center;
  text-indent: -999em;
  cursor: pointer;
  background: none;
  border: 0.45rem solid transparent; }
  .datepicker__navigation--previous,
  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #01507B; }
    .datepicker__navigation--previous:hover,
    .react-datepicker__navigation--previous:hover {
      border-right-color: #012f48; }
    .datepicker__navigation--previous--disabled, .datepicker__navigation--previous--disabled:hover,
    .react-datepicker__navigation--previous--disabled,
    .react-datepicker__navigation--previous--disabled:hover {
      cursor: default;
      border-right-color: #0171ae; }
  .datepicker__navigation--next,
  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #01507B; }
    .datepicker__navigation--next--with-time ,
    .react-datepicker__navigation--next--with-time {
      right: 80px; }
    .datepicker__navigation--next:hover,
    .react-datepicker__navigation--next:hover {
      border-left-color: #012f48; }
    .datepicker__navigation--next--disabled, .datepicker__navigation--next--disabled:hover,
    .react-datepicker__navigation--next--disabled,
    .react-datepicker__navigation--next--disabled:hover {
      cursor: default;
      border-left-color: #0171ae; }
  .datepicker__navigation--years,
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-right: auto;
    margin-left: auto; }
    .datepicker__navigation--years-previous,
    .react-datepicker__navigation--years-previous {
      top: 4px;
      border-top-color: #01507B; }
      .datepicker__navigation--years-previous:hover,
      .react-datepicker__navigation--years-previous:hover {
        border-top-color: #012f48; }
    .datepicker__navigation--years-upcoming,
    .react-datepicker__navigation--years-upcoming {
      top: -4px;
      border-bottom-color: #01507B; }
      .datepicker__navigation--years-upcoming:hover,
      .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: #012f48; }

.datepicker__month-container,
.react-datepicker__month-container {
  float: left; }

.datepicker__month,
.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }

.datepicker__time-container,
.react-datepicker__time-container {
  float: right;
  width: 70px;
  border-left: 1px solid #C4C6C7; }
  .datepicker__time-container--with-today-button,
  .react-datepicker__time-container--with-today-button {
    position: absolute;
    top: 0;
    right: -72px;
    display: inline;
    border: 1px solid #0B8E90;
    border-radius: 0.3rem; }
  .datepicker__time-container .datepicker__time,
  .datepicker__time-container .react-datepicker__time,
  .react-datepicker__time-container .datepicker__time,
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: #FFF; }
    .datepicker__time-container .datepicker__time .datepicker__time-box,
    .datepicker__time-container .datepicker__time .react-datepicker__time-box,
    .datepicker__time-container .react-datepicker__time .datepicker__time-box,
    .datepicker__time-container .react-datepicker__time .react-datepicker__time-box,
    .react-datepicker__time-container .datepicker__time .datepicker__time-box,
    .react-datepicker__time-container .datepicker__time .react-datepicker__time-box,
    .react-datepicker__time-container .react-datepicker__time .datepicker__time-box,
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 70px;
      margin: 0 auto;
      overflow-x: hidden;
      text-align: center; }
      .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list,
      .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list,
      .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list,
      .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list,
      .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list,
      .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list,
      .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list,
      .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list,
      .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list,
      .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list,
      .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list,
      .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list,
      .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list,
      .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list,
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list,
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list {
        -webkit-box-sizing: content-box;
                box-sizing: content-box;
        width: 100%;
        height: calc(195px + (1.7rem / 2));
        padding-right: 30px;
        margin: 0;
        overflow-y: scroll;
        list-style: none;
        /* stylelint-disable selector-max-compound-selectors, selector-max-class */
        /* stylelint-enable selector-max-compound-selectors, selector-max-class */ }
        .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item,
        .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item,
        .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item,
        .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item,
        .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item,
        .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item,
        .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item,
        .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item,
        .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item,
        .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item,
        .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item,
        .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item,
        .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item,
        .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item,
        .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item,
        .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item,
        .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item,
        .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item,
        .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item,
        .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item,
        .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item,
        .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item,
        .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item,
        .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item,
        .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item,
        .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item,
        .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item,
        .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item,
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item,
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item,
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item,
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item {
          padding: 5px 10px; }
          .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item:hover,
          .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item:hover,
          .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item:hover,
          .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item:hover,
          .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item:hover,
          .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item:hover,
          .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item:hover,
          .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item:hover,
          .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item:hover,
          .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item:hover,
          .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item:hover,
          .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item:hover,
          .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item:hover,
          .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item:hover,
          .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item:hover,
          .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item:hover,
          .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item:hover,
          .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item:hover,
          .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item:hover,
          .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item:hover,
          .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item:hover,
          .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item:hover,
          .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item:hover,
          .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item:hover,
          .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item:hover,
          .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item:hover,
          .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item:hover,
          .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item:hover,
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item:hover,
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item:hover,
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item:hover,
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item:hover {
            cursor: pointer;
            background-color: rgba(1, 80, 123, 0.2); }
          .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected,
          .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected,
          .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected,
          .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected,
          .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected,
          .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected,
          .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected,
          .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected,
          .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected,
          .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected,
          .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected,
          .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected,
          .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected,
          .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected,
          .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected,
          .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected,
          .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected,
          .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected,
          .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected,
          .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected,
          .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected,
          .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected,
          .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected,
          .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected,
          .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected,
          .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected,
          .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected,
          .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected,
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected,
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected,
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected,
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected {
            font-weight: 700;
            color: #FFF;
            background-color: #01507B; }
            .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected:hover,
            .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected:hover,
            .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected:hover,
            .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected:hover,
            .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected:hover,
            .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected:hover,
            .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected:hover,
            .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected:hover,
            .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--selected:hover,
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected:hover {
              background-color: #01507B; }
          .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled,
          .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled,
          .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled,
          .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled,
          .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled,
          .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled,
          .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled,
          .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled,
          .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled,
          .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled,
          .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled,
          .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled,
          .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled,
          .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled,
          .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled,
          .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled,
          .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled,
          .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled,
          .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled,
          .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled,
          .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled,
          .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled,
          .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled,
          .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled,
          .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled,
          .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled,
          .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled,
          .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled,
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled,
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled,
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled,
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled {
            color: #01507B; }
            .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .react-datepicker__time .datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .datepicker__time-list .react-datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .datepicker__time-list-item--disabled:hover,
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled:hover {
              cursor: default;
              background-color: transparent; }

.datepicker__week-number,
.react-datepicker__week-number {
  display: inline-block;
  width: 1.7rem;
  margin: 0.166rem;
  line-height: 1.7rem;
  color: #01507B;
  text-align: center; }
  .datepicker__week-number.datepicker__week-number--clickable, .datepicker__week-number.react-datepicker__week-number--clickable,
  .react-datepicker__week-number.datepicker__week-number--clickable,
  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer; }
    .datepicker__week-number.datepicker__week-number--clickable:hover, .datepicker__week-number.react-datepicker__week-number--clickable:hover,
    .react-datepicker__week-number.datepicker__week-number--clickable:hover,
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
      background-color: #FFF;
      border-radius: 0.25rem; }

.datepicker__day-name,
.react-datepicker__day-names,
.datepicker__week,
.react-datepicker__week {
  white-space: nowrap; }

.datepicker__day-nam,
.react-datepicker__day-name,
.datepicker__da,
.react-datepicker__day,
.datepicker__time-name,
.react-datepicker__time-name {
  display: inline-block;
  width: 1.7rem;
  margin: 0.166rem;
  line-height: 1.7rem;
  color: #314251;
  text-align: center; }

.datepicker__day,
.react-datepicker__day {
  cursor: pointer; }
  .datepicker__day:hover,
  .react-datepicker__day:hover {
    background-color: #FFF;
    border-radius: 0.25rem; }
  .datepicker__day--today,
  .react-datepicker__day--today {
    font-weight: 700; }
  .datepicker__day--highlighted,
  .react-datepicker__day--highlighted {
    color: #FFF;
    background-color: #4CA325;
    border-radius: 0.25rem; }
    .datepicker__day--highlighted:hover,
    .react-datepicker__day--highlighted:hover {
      background-color: #428e20; }
    .datepicker__day--highlighted-custom-1,
    .react-datepicker__day--highlighted-custom-1 {
      color: #60768A; }
    .datepicker__day--highlighted-custom-2,
    .react-datepicker__day--highlighted-custom-2 {
      color: brainimals-green-light; }
  .datepicker__day--selected, .datepicker__day--in-selecting-range, .datepicker__day--in-range,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    color: #FFF;
    background-color: #01507B;
    border-radius: 0.25rem; }
    .datepicker__day--selected:hover, .datepicker__day--in-selecting-range:hover, .datepicker__day--in-range:hover,
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover {
      background-color: #014062; }
  .datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected {
    color: #FFF;
    background-color: #0171ae;
    border-radius: 0.25rem; }
    .datepicker__day--keyboard-selected:hover,
    .react-datepicker__day--keyboard-selected:hover {
      background-color: #014062; }
  .datepicker__day--in-selecting-range ,
  .react-datepicker__day--in-selecting-range {
    background-color: rgba(1, 80, 123, 0.5); }
  .datepicker__month--selecting-range .datepicker__day--in-range ,
  .react-datepicker__month--selecting-range .datepicker__day--in-range , .datepicker__month--selecting-range
  .react-datepicker__day--in-range ,
  .react-datepicker__month--selecting-range
  .react-datepicker__day--in-range {
    color: #314251;
    background-color: #FFF; }
  .datepicker__day--disabled,
  .react-datepicker__day--disabled {
    color: #01507B;
    cursor: default; }
    .datepicker__day--disabled:hover,
    .react-datepicker__day--disabled:hover {
      background-color: transparent; }

.datepicker__input-container,
.react-datepicker__input-container {
  position: relative;
  display: block; }
  .datepicker__input-container .form-control, .datepicker__input-container .form-control + label,
  .react-datepicker__input-container .form-control,
  .react-datepicker__input-container .form-control + label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.datepicker__year-read-vie,
.react-datepicker__year-read-view,
.datepicker__month-read-vie,
.react-datepicker__month-read-view,
.datepicker__month-year-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .datepicker__year-read-vie:hover,
  .react-datepicker__year-read-view:hover,
  .datepicker__month-read-vie:hover,
  .react-datepicker__month-read-view:hover,
  .datepicker__month-year-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer; }
    .datepicker__year-read-vie:hover .datepicker__year-read-view--down-arro,
    .datepicker__year-read-vie:hover .react-datepicker__year-read-view--down-arrow,
    .datepicker__year-read-vie:hover .datepicker__month-read-view--down-arrow,
    .datepicker__year-read-vie:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .datepicker__year-read-view--down-arro,
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .datepicker__month-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .datepicker__month-read-vie:hover .datepicker__year-read-view--down-arro,
    .datepicker__month-read-vie:hover .react-datepicker__year-read-view--down-arrow,
    .datepicker__month-read-vie:hover .datepicker__month-read-view--down-arrow,
    .datepicker__month-read-vie:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .datepicker__year-read-view--down-arro,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .datepicker__month-year-read-view:hover .datepicker__year-read-view--down-arro,
    .datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .datepicker__month-year-read-view:hover .datepicker__month-read-view--down-arrow,
    .datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .datepicker__year-read-view--down-arro,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: #012f48; }
  .datepicker__year-read-vie--down-arrow,
  .react-datepicker__year-read-view--down-arrow,
  .datepicker__month-read-vie--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .datepicker__month-year-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    position: relative;
    top: 8px;
    float: right;
    margin-left: 20px;
    border-width: 0.45rem;
    border-top-color: #01507B; }

.datepicker__year-dropdow,
.react-datepicker__year-dropdown,
.datepicker__month-dropdow,
.react-datepicker__month-dropdown,
.datepicker__month-year-dropdown,
.react-datepicker__month-year-dropdown {
  position: absolute;
  top: 30px;
  left: 25%;
  z-index: 1;
  width: 50%;
  text-align: center;
  background-color: #FFF;
  border: 1px solid #C4C6C7;
  border-radius: 0.25rem; }
  .datepicker__year-dropdow:hover,
  .react-datepicker__year-dropdown:hover,
  .datepicker__month-dropdow:hover,
  .react-datepicker__month-dropdown:hover,
  .datepicker__month-year-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer; }
  .datepicker__year-dropdow--scrollable,
  .react-datepicker__year-dropdown--scrollable,
  .datepicker__month-dropdow--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .datepicker__month-year-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll; }

.datepicker__year-optio,
.react-datepicker__year-option,
.datepicker__month-optio,
.react-datepicker__month-option,
.datepicker__month-year-option,
.react-datepicker__month-year-option {
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  line-height: 20px; }
  .datepicker__year-optio:first-of-type,
  .react-datepicker__year-option:first-of-type,
  .datepicker__month-optio:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .datepicker__month-year-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .datepicker__year-optio:last-of-type,
  .react-datepicker__year-option:last-of-type,
  .datepicker__month-optio:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .datepicker__month-year-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .datepicker__year-optio:hover,
  .react-datepicker__year-option:hover,
  .datepicker__month-optio:hover,
  .react-datepicker__month-option:hover,
  .datepicker__month-year-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #01507B; }
    .datepicker__year-optio:hover .datepicker__navigation--years-upcoming,
    .datepicker__year-optio:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__year-option:hover .datepicker__navigation--years-upcoming,
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .datepicker__month-optio:hover .datepicker__navigation--years-upcoming,
    .datepicker__month-optio:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .datepicker__month-year-option:hover .datepicker__navigation--years-upcoming,
    .datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #012f48; }
    .datepicker__year-optio:hover .datepicker__navigation--years-previous,
    .datepicker__year-optio:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__year-option:hover .datepicker__navigation--years-previous,
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .datepicker__month-optio:hover .datepicker__navigation--years-previous,
    .datepicker__month-optio:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .datepicker__month-year-option:hover .datepicker__navigation--years-previous,
    .datepicker__month-year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
      border-top-color: #012f48; }
  .datepicker__year-optio--selected,
  .react-datepicker__year-option--selected,
  .datepicker__month-optio--selected,
  .react-datepicker__month-option--selected,
  .datepicker__month-year-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px; }

.datepicker__close-icon,
.react-datepicker__close-icon {
  position: absolute;
  top: 25%;
  right: 7px;
  width: 16px;
  height: 16px;
  padding: 0;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0; }
  .datepicker__close-icon::after,
  .react-datepicker__close-icon::after {
    position: absolute;
    right: 0;
    bottom: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 16px;
    height: 16px;
    padding: 2px;
    margin: -8px auto 0;
    font-size: 12px;
    line-height: 1;
    color: #FFF;
    text-align: center;
    cursor: pointer;
    content: "\D7";
    background-color: #01507B;
    border-radius: 50%; }

.datepicker__today-button,
.react-datepicker__today-button {
  padding: 5px 0;
  clear: left;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  background: #FFF;
  border-top: 1px solid #C4C6C7; }

.datepicker__portal,
.react-datepicker__portal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483647;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); }
  .datepicker__portal .datepicker__day-nam,
  .datepicker__portal .react-datepicker__day-name,
  .datepicker__portal .datepicker__da,
  .datepicker__portal .react-datepicker__day,
  .datepicker__portal .datepicker__time-name,
  .datepicker__portal .react-datepicker__time-name,
  .react-datepicker__portal .datepicker__day-nam,
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .datepicker__da,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .datepicker__time-name,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem; }
  @media (max-width: 400px), (max-height: 550px) {
    .datepicker__portal .datepicker__day-nam,
    .datepicker__portal .react-datepicker__day-name,
    .datepicker__portal .datepicker__da,
    .datepicker__portal .react-datepicker__day,
    .datepicker__portal .datepicker__time-name,
    .datepicker__portal .react-datepicker__time-name,
    .react-datepicker__portal .datepicker__day-nam,
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .datepicker__da,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .datepicker__time-name,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem; } }
  .datepicker__portal .datepicker__current-mont,
  .datepicker__portal .react-datepicker__current-month,
  .datepicker__portal .datepicker-time__header,
  .datepicker__portal .react-datepicker-time__header,
  .react-datepicker__portal .datepicker__current-mont,
  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .datepicker-time__header,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem; }
  .datepicker__portal .datepicker__navigation,
  .datepicker__portal .react-datepicker__navigation,
  .react-datepicker__portal .datepicker__navigation,
  .react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent; }
  .datepicker__portal .datepicker__navigation--previous,
  .datepicker__portal .react-datepicker__navigation--previous,
  .react-datepicker__portal .datepicker__navigation--previous,
  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #01507B; }
    .datepicker__portal .datepicker__navigation--previous:hover,
    .datepicker__portal .react-datepicker__navigation--previous:hover,
    .react-datepicker__portal .datepicker__navigation--previous:hover,
    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
      border-right-color: #012f48; }
    .datepicker__portal .datepicker__navigation--previous--disabled, .datepicker__portal .datepicker__navigation--previous--disabled:hover,
    .datepicker__portal .react-datepicker__navigation--previous--disabled,
    .datepicker__portal .react-datepicker__navigation--previous--disabled:hover,
    .react-datepicker__portal .datepicker__navigation--previous--disabled,
    .react-datepicker__portal .datepicker__navigation--previous--disabled:hover,
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled,
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
      cursor: default;
      border-right-color: #0171ae; }
  .datepicker__portal .datepicker__navigation--next,
  .datepicker__portal .react-datepicker__navigation--next,
  .react-datepicker__portal .datepicker__navigation--next,
  .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #01507B; }
    .datepicker__portal .datepicker__navigation--next:hover,
    .datepicker__portal .react-datepicker__navigation--next:hover,
    .react-datepicker__portal .datepicker__navigation--next:hover,
    .react-datepicker__portal .react-datepicker__navigation--next:hover {
      border-left-color: #012f48; }
    .datepicker__portal .datepicker__navigation--next--disabled, .datepicker__portal .datepicker__navigation--next--disabled:hover,
    .datepicker__portal .react-datepicker__navigation--next--disabled,
    .datepicker__portal .react-datepicker__navigation--next--disabled:hover,
    .react-datepicker__portal .datepicker__navigation--next--disabled,
    .react-datepicker__portal .datepicker__navigation--next--disabled:hover,
    .react-datepicker__portal .react-datepicker__navigation--next--disabled,
    .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
      cursor: default;
      border-left-color: #0171ae; }

@charset "UTF-8";
.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.main-container {
  height: 100%;
  overflow: hidden;
  padding: 0; }

.main-container .progression,
.main-container h1,
.main-container h2 {
  position: relative;
  z-index: 2;
  width: 100%; }

.main-container .progression {
  max-width: 60%; }

* {
  font-family: "Quicksand", "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 500 !important; }

strong, b  {
  font-weight: 700 !important; }

.chat-character {
  position: absolute;
  display: none;
  width: 7rem;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  z-index: 1030; }

@media (min-width: 768px) {
  .chat-character {
    display: block; } }

.svg-inline > div {
  display: inline; }

.svg-inline > div > div {
  display: inline; }


.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.background-ground-trees {
  bottom: 40px;
  width: 80vw;
  margin: 0 auto;
  z-index: -1; }

@media (min-width: 576px) {
  .background-ground-trees {
    bottom: 40px; } }

@media (min-width: 768px) {
  .background-ground-trees {
    bottom: 85px; } }

@media (min-width: 992px) {
  .background-ground-trees {
    bottom: 165px; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.close-button {
  position: fixed;
  top: 1rem;
  right: 1rem; }
  .close-button,
  .close-button * {
    cursor: pointer !important; }
  .close-button svg {
    max-height: 3rem;
    max-width: 3rem;
    margin: 0; }

@media (min-width: 576px) {
  .close-button {
    top: 1rem;
    right: 1rem; }
    .close-button svg {
      max-height: 2.5rem;
      max-width: 2.5rem; } }

@media (min-width: 768px) {
  .close-button {
    top: 2.5rem;
    right: 2.5rem; }
    .close-button svg {
      max-height: 3rem;
      max-width: 3rem; } }

.help-button {
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 20px;
  height: 12vw;
  width: 12vw; }

@media (min-width: 576px) {
  .help-button {
    top: 10px;
    left: 10px;
    height: 10vw;
    width: 10vw; } }

@media (min-width: 768px) {
  .help-button {
    display: none;
    top: 40px;
    left: 40px;
    height: 60px;
    width: 60px; } }

@media (min-width: 992px) {
  .help-button {
    top: 40px;
    left: 40px;
    height: 80px;
    width: 80px; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.logout-button,
.logout-button * {
  cursor: pointer !important; }

.logout-button svg {
  max-height: 3rem;
  max-width: 3rem;
  margin: 0; }

@media (min-width: 576px) {
  .logout-button svg {
    max-height: 2.5rem;
    max-width: 2.5rem; } }

@media (min-width: 768px) {
  .logout-button svg {
    max-height: 3rem;
    max-width: 3rem; } }

@media (min-width: 992px) {
  .logout-button svg {
    max-height: 3rem;
    max-width: 3rem; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.back-button,
.back-button * {
  cursor: pointer !important; }

.back-button svg {
  max-height: 3rem;
  max-width: 3rem;
  margin: 0; }

@media (min-width: 576px) {
  .back-button svg {
    max-height: 2.5rem;
    max-width: 2.5rem; } }

@media (min-width: 768px) {
  .back-button svg {
    max-height: 3rem;
    max-width: 3rem; } }

@media (min-width: 992px) {
  .back-button svg {
    max-height: 3rem;
    max-width: 3rem; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.footer {
  background-color: #0B8E90;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  width: 100%;
  z-index: 1; }
  .footer__link {
    display: inline-block;
    padding: 4px 0; }
  .footer__divider {
    margin: 0 20px; }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.progress-header {
  display: -ms-flexbox;
  display: flex;
  background: #11D1D2;
  left: 0;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5; }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.notification {
  position: fixed;
  height: auto;
  padding: 0.375rem 0.625rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .notification .notification-icon {
    max-height: 3rem;
    max-width: 3rem; }
  .notification h6 {
    margin-bottom: 0; }
  .notification .close-button {
    top: 1.5rem;
    right: 0.8rem;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }

@media (min-width: 576px) {
  .notification {
    padding: 0.75rem 1.25rem; }
    .notification .notification-icon {
      max-height: 5rem;
      max-width: 5rem; }
    .notification h6 {
      font-size: 1.25rem;
      margin-top: 0.3rem; }
    .notification p {
      font-size: 1.25rem; }
    .notification .close-button {
      top: 1.8rem;
      right: 1.8rem;
      -webkit-transform: scale(1);
              transform: scale(1); } }

.notification-heading {
  color: inherit; }

.notification-link {
  font-weight: 700; }

.notification-primary {
  color: #012a40;
  background-color: rgba(123, 164, 186, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #5288a5; }
  .notification-primary .alert-link {
    color: #00090e; }

.notification-secondary {
  color: #6c783d;
  background-color: rgba(230, 242, 184, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #deeea2; }
  .notification-secondary .alert-link {
    color: #4e562c; }

.notification-success {
  color: #285513;
  background-color: rgba(162, 207, 142, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #85c06b; }
  .notification-success .alert-link {
    color: #142b0a; }

.notification-info {
  color: #49545e;
  background-color: rgba(195, 207, 217, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #b1c0cd; }
  .notification-info .alert-link {
    color: #333a41; }

.notification-warning {
  color: #84621e;
  background-color: rgba(254, 221, 152, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #fed278; }
  .notification-warning .alert-link {
    color: #5a4315; }

.notification-danger {
  color: #793834;
  background-color: rgba(243, 178, 174, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #ef9a96; }
  .notification-danger .alert-link {
    color: #552725; }

.notification-light {
  color: #656c72;
  background-color: rgba(223, 230, 236, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #d6dee7; }
  .notification-light .alert-link {
    color: #4d5257; }

.notification-dark {
  color: #0c1217;
  background-color: rgba(134, 141, 145, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #616970; }
  .notification-dark .alert-link {
    color: black; }

.notification-brainimals-aqua {
  color: #096d6d;
  background-color: rgba(131, 231, 232, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #5de0e0; }
  .notification-brainimals-aqua .alert-link {
    color: #053e3e; }

.notification-brainimals-topaz {
  color: #075c5c;
  background-color: rgba(130, 214, 214, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #5bc9ca; }
  .notification-brainimals-topaz .alert-link {
    color: #032d2d; }

.notification-brainimals-blue-light {
  color: #3a6b7b;
  background-color: rgba(180, 230, 246, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #9ddef3; }
  .notification-brainimals-blue-light .alert-link {
    color: #2a4d58; }

.notification-brainimals-deep-sea-blue {
  color: #012a40;
  background-color: rgba(123, 164, 186, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #5288a5; }
  .notification-brainimals-deep-sea-blue .alert-link {
    color: #00090e; }

.notification-brainimals-dark-cyan {
  color: #064a4b;
  background-color: rgba(128, 196, 197, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #59b2b4; }
  .notification-brainimals-dark-cyan .alert-link {
    color: #021b1c; }

.notification-brainimals-darker-cyan {
  color: #023132;
  background-color: rgba(124, 171, 173, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #549294; }
  .notification-brainimals-darker-cyan .alert-link {
    color: #000101; }

.notification-brainimals-green-light {
  color: #6c783d;
  background-color: rgba(230, 242, 184, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #deeea2; }
  .notification-brainimals-green-light .alert-link {
    color: #4e562c; }

.notification-brainimals-green {
  color: #285513;
  background-color: rgba(162, 207, 142, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #85c06b; }
  .notification-brainimals-green .alert-link {
    color: #142b0a; }

.notification-brainimals-orange {
  color: #84621e;
  background-color: rgba(254, 221, 152, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #fed278; }
  .notification-brainimals-orange .alert-link {
    color: #5a4315; }

.notification-brainimals-red {
  color: #793834;
  background-color: rgba(243, 178, 174, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #ef9a96; }
  .notification-brainimals-red .alert-link {
    color: #552725; }

.notification-brainimals-dark-grey-blue {
  color: #19222a;
  background-color: rgba(148, 157, 165, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #737e89; }
  .notification-brainimals-dark-grey-blue .alert-link {
    color: #06080a; }

.notification-brainimals-pale-grey-two {
  color: #73787a;
  background-color: rgba(238, 242, 245, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #e9eef1; }
  .notification-brainimals-pale-grey-two .alert-link {
    color: #5a5e60; }

.notification-brainimals-pale-grey {
  color: #7b7e7f;
  background-color: rgba(246, 248, 249, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #f3f6f8; }
  .notification-brainimals-pale-grey .alert-link {
    color: #626465; }

.notification-brainimals-bluey-grey {
  color: #49545e;
  background-color: rgba(195, 207, 217, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #b1c0cd; }
  .notification-brainimals-bluey-grey .alert-link {
    color: #333a41; }

.notification-brainimals-cloudy-blue {
  color: #656c72;
  background-color: rgba(223, 230, 236, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #d6dee7; }
  .notification-brainimals-cloudy-blue .alert-link {
    color: #4d5257; }

.notification-brainimals-blue-grey {
  color: #323d48;
  background-color: rgba(172, 184, 194, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #93a2af; }
  .notification-brainimals-blue-grey .alert-link {
    color: #1d242a; }

.notification-brainimals-dark {
  color: #0c1217;
  background-color: rgba(134, 141, 145, 0.75);
  -webkit-backdrop-filter: blur(20px) brightness(125%) saturate(150%);
          backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: #616970; }
  .notification-brainimals-dark .alert-link {
    color: black; }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }


.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.exercise-finished__character-badge {
  width: 100%; }
  .exercise-finished__character-badge div,
  .exercise-finished__character-badge svg {
    height: 100%;
    width: 100%; }

.exercise-finished__character-power {
  width: 80%; }
  .exercise-finished__character-power div,
  .exercise-finished__character-power svg {
    height: 100%;
    width: 100%; }

@media (min-width: 768px) {
  .exercise-finished__character-badge {
    width: 100%;
    max-width: 400px; } }

@media (min-width: 768px) {
  .exercise-finished__character-power {
    width: 100%;
    max-width: 300px; } }

.exercise-finished__headline {
  text-align: center; }
  @media (max-width: 767.98px) {
    .exercise-finished__headline {
      font-size: 1.5rem; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.signed-in-landing__container {
  padding-top: 5rem;
  z-index: -5; }
  @media (min-width: 576px) {
    .signed-in-landing__container {
      padding-top: 6rem; } }

.signed-in-landing__wrapper {
  padding-bottom: 20px; }

.signed-in-landing__intro {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  margin-bottom: 1rem; }
  @media (min-width: 576px) {
    .signed-in-landing__intro {
      margin-bottom: 2rem;
      margin-top: 1rem; } }
  @media (min-width: 992px) {
    .signed-in-landing__intro {
      margin-bottom: 2rem;
      margin-top: 3rem; } }

.signed-in-landing__character {
  width: 100%;
  display: none; }
  @media (min-width: 576px) {
    .signed-in-landing__character {
      display: inherit; } }

.signed-in-landing__headline {
  color: #fff;
  margin-bottom: 1rem; }
  .signed-in-landing__headline:not(:first-of-type) {
    margin-top: 3rem; }

.signed-in-landing__more-text {
  font-size: 20px;
  margin: 2rem 0;
  text-align: center; }

.signed-in-landing__bottom-items {
  display: -ms-flexbox;
  display: flex; }
  .signed-in-landing__bottom-items > div {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.progress-item {
  padding: 0 0;
  font-size: 1rem;
  line-height: 1.6;
  border-radius: 1rem;
  background-color: rgba(4, 94, 97, 0.4);
  color: #fff;
  margin-bottom: 1rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .progress-item, .progress-item * {
    cursor: pointer; }
  .progress-item__title {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis; }
  .progress-item__icon {
    margin-right: 0.5rem;
    width: 100px;
    border-radius: 1rem 0 0 1rem; }
    @media (min-width: 576px) {
      .progress-item__icon {
        margin-right: 0.75rem;
        width: 100px; } }
    @media (min-width: 768px) {
      .progress-item__icon {
        margin-right: 1.25rem;
        width: 120px; } }
  .progress-item__start {
    display: none;
    -ms-flex-align: center;
        align-items: center; }
    @media (min-width: 992px) {
      .progress-item__start {
        display: -ms-flexbox;
        display: flex; } }
    .progress-item__start-icon {
      margin-left: 0.5rem;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .progress-item__text {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis; }
  .progress-item__recommendation {
    color: #ffc107; }
  .progress-item--active {
    background-color: #01507B; }
    .progress-item--active * {
      cursor: inherit; }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.onboarding,
.carousel-inner,
.carousel-item {
  height: 100% !important; }

.onboarding-background {
  position: absolute;
  padding-bottom: 15vh;
  height: 100%;
  width: 100%; }
  .onboarding-background * {
    height: 100%;
    width: 100%; }

.carousel-indicators li {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-left: 9px;
  margin-right: 9px;
  border: none;
  background-color: rgba(237, 242, 244, 0.7);
  padding: 0;
  -webkit-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out; }
  .carousel-indicators li:hover {
    cursor: pointer;
    border: none;
    background-color: #FFF; }
  .carousel-indicators li:focus {
    outline: 0; }
  .carousel-indicators li.active {
    background-color: #FFF;
    width: 18px; }

.onboarding-carousel-title {
  font-size: 1.5rem;
  margin: 5vh 0 1rem 0; }

.onboarding-carousel-description {
  font-size: 1rem;
  margin: 0; }

.onboarding-carousel-text {
  color: #FFF;
  font-size: 1rem;
  margin-top: 2rem; }

.onboarding-carousel {
  text-align: center;
  height: 80vh !important; }
  .onboarding-carousel .btn {
    z-index: 1000; }

.carousel-indicators {
  margin-bottom: 2.5vh; }

.onboarding-devices {
  max-height: 40vh; }

.onboarding-character,
.onboarding-badge,
.onboarding-trees {
  height: auto;
  max-width: 100%; }
  .onboarding-character *,
  .onboarding-badge *,
  .onboarding-trees * {
    height: 100%;
    width: auto; }
  .onboarding-character > div > div,
  .onboarding-badge > div > div,
  .onboarding-trees > div > div {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-direction: column !important;
        flex-direction: column !important;
    -ms-flex-line-pack: center !important;
        align-content: center !important;
    -ms-flex-pack: center !important;
        justify-content: center !important; }

.onboarding-character * svg {
  height: auto;
  width: 100%;
  max-height: 40vh; }

.onboarding-badge,
.onboarding-trees {
  margin: 0 auto; }
  .onboarding-badge * svg,
  .onboarding-trees * svg {
    width: 100%;
    height: auto; }

.onboarding-badge {
  margin-top: 2vh; }
  .onboarding-badge * svg {
    width: 100%;
    height: auto;
    max-height: 18vh; }

.onboarding-trees {
  margin-top: 5vh; }
  .onboarding-trees * svg {
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: 50vw; }

@media (min-width: 576px) {
  .carousel-indicators {
    margin-bottom: 3.5vh; }
  .onboarding-carousel-title {
    font-size: 1.25rem;
    margin: 5vh 0 1rem 0; }
  .onboarding-carousel-description {
    font-size: 1rem; }
  .onboarding-carousel-text {
    font-size: 1rem;
    margin-top: 2rem; }
  .onboarding-badge {
    margin-top: 0vh; }
    .onboarding-badge * svg {
      max-height: 18vh; }
  .onboarding-trees {
    margin-top: 20vh; }
    .onboarding-trees * svg {
      max-width: 50vw; } }

@media (min-width: 768px) {
  .onboarding-carousel-text {
    font-size: 1.5rem;
    margin-top: 2rem; }
  .onboarding-carousel-title {
    font-size: 1.5rem; }
  .onboarding-carousel-description {
    font-size: 1.25rem; }
  .onboarding-carousel-text {
    font-size: 1.25rem; }
  .onboarding-badge {
    margin-top: 4vh; }
    .onboarding-badge * svg {
      max-height: 18vh; }
  .onboarding-trees {
    margin-top: 6vh; }
    .onboarding-trees * svg {
      max-width: 50vw; } }

@media (min-width: 992px) {
  .carousel-indicators {
    margin-bottom: 4.5vh; }
  .onboarding-carousel-title {
    font-size: 1.75rem; }
  .onboarding-carousel-description {
    font-size: 1.25rem; }
  .onboarding-carousel-text {
    font-size: 1.25rem;
    margin-top: 2rem; }
  .onboarding-badge {
    margin-top: 4vh; }
    .onboarding-badge * svg {
      max-height: 18vh; }
  .onboarding-trees {
    margin-top: 5vh; }
    .onboarding-trees * svg {
      max-width: 50vh; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.bg-cover-ground {
  background-image: url("/assets/svg/ground.svg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain; }

.program-content {
  margin-top: 65px;
  height: calc(100% - 65px); }

.program-content-move {
  background-color: transparent;
  position: absolute;
  width: 9.5vw;
  z-index: 100; }
  .program-content-move__left {
    left: 0;
    top: 0;
    bottom: 0; }
  .program-content-move__right {
    right: 0;
    top: 0;
    bottom: 0; }

.exercises-background {
  position: absolute;
  padding-bottom: 15vh;
  height: 100%;
  width: 100%; }
  .exercises-background * {
    height: 100%;
    width: 100%; }

.exercises,
.carousel-inner,
.carousel-item {
  height: 100% !important; }

.carousel-wrapper {
  -webkit-transition: height 0.6s ease-in-out;
  -o-transition: height 0.6s ease-in-out;
  transition: height 0.6s ease-in-out; }

.exercises-carousel-title {
  font-size: 1.5rem;
  margin: 5vh 0 1rem 0; }

.exercises-carousel-description {
  font-size: 1rem;
  margin: 0; }

.exercises-carousel-text {
  color: #FFF;
  font-size: 1rem;
  margin-top: 2rem; }

.exercises-carousel {
  text-align: center;
  height: 80vh !important; }
  .exercises-carousel .btn {
    z-index: 1000; }

.carousel-indicators {
  margin-bottom: 2.5vh; }

.program-content {
  margin-top: 75px;
  height: calc(100% - 75px); }

@media (min-width: 576px) {
  .program-content {
    margin-top: 65px;
    height: calc(100% - 65px); }
  .program-content-move {
    width: 12vw; }
  .carousel-indicators {
    margin-bottom: 3.5vh; }
  .exercises-carousel-title {
    font-size: 1.25rem;
    margin: 5vh 0 1rem 0; }
  .exercises-carousel-description {
    font-size: 1rem; }
  .exercises-carousel-text {
    font-size: 1rem;
    margin-top: 2rem; } }

@media (min-width: 768px) {
  .program-content {
    margin-top: 75px;
    height: calc(100% - 75px); }
  .exercises-carousel-text {
    font-size: 1.5rem;
    margin-top: 2rem; }
  .exercises-carousel-title {
    font-size: 1.5rem; }
  .exercises-carousel-description {
    font-size: 1.25rem; }
  .exercises-carousel-text {
    font-size: 1.25rem; }
  .program-content-move {
    width: 20vw; } }

@media (min-width: 992px) {
  .carousel-indicators {
    margin-bottom: 4.5vh; }
  .exercises-carousel-title {
    font-size: 1.75rem; }
  .exercises-carousel-description {
    font-size: 1.25rem; }
  .exercises-carousel-text {
    font-size: 1.25rem;
    margin-top: 2rem; }
  .program-content-move {
    width: 25vw; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.program-done-celebration-title {
  margin-top: 5vh; }

.program-done-celebration-footer {
  margin-bottom: 5vh; }

.program-done-celebration {
  -webkit-transform: scale(1.5) translate3d(0, 0, 0);
          transform: scale(1.5) translate3d(0, 0, 0);
  z-index: -1; }

@media (min-width: 576px) {
  .program-done-celebration {
    -webkit-transform: scale(1.25) translate3d(0, 0, 0);
            transform: scale(1.25) translate3d(0, 0, 0); }
  .program-done-celebration-title {
    margin-top: 8vh; }
  .program-done-celebration-footer {
    margin-bottom: 8vh; } }

@media (min-width: 992px) {
  .program-done-celebration {
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0); }
    .program-done-celebration__level1, .program-done-celebration__level2 {
      width: 100% !important; }
  .program-done-celebration-title {
    margin-top: 10vh; }
  .program-done-celebration-footer {
    margin-bottom: 10vh; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.background-tree {
  position: absolute;
  bottom: 0;
  z-index: -1; }
  .background-tree div, .background-tree div svg {
    height: 100%;
    width: 100%; }

.background-tree__left {
  height: 30rem;
  left: -10rem; }

.background-tree__right {
  height: 25rem;
  right: -10rem; }

@media (min-width: 576px) {
  .background-tree__left {
    height: 35rem;
    left: -15rem; }
  .background-tree__right {
    height: 30rem;
    right: -15rem; } }

@media (min-width: 768px) {
  .background-tree__left {
    height: 50rem;
    left: -20rem; }
  .background-tree__right {
    height: 43rem;
    right: -20rem; } }

@media (min-width: 992px) {
  .background-tree__left {
    height: 50rem;
    left: -35rem; }
  .background-tree__right {
    height: 55rem;
    right: -25rem; } }

/* KEYFRAMES */
@-webkit-keyframes animateCloud1 {
  0% {
    -webkit-transform: translate3d(-1500vw, 0, 0), scale(0.65);
            transform: translate3d(-1500vw, 0, 0), scale(0.65); }
  100% {
    -webkit-transform: translate3d(200vw, 0, 0);
            transform: translate3d(200vw, 0, 0); } }
@keyframes animateCloud1 {
  0% {
    -webkit-transform: translate3d(-1500vw, 0, 0), scale(0.65);
            transform: translate3d(-1500vw, 0, 0), scale(0.65); }
  100% {
    -webkit-transform: translate3d(200vw, 0, 0);
            transform: translate3d(200vw, 0, 0); } }

@-webkit-keyframes animateCloud2 {
  0% {
    -webkit-transform: translate3d(-80vw, 0, 0), scale(0.3);
            transform: translate3d(-80vw, 0, 0), scale(0.3); }
  100% {
    -webkit-transform: translate3d(200vw, 0, 0);
            transform: translate3d(200vw, 0, 0); } }

@keyframes animateCloud2 {
  0% {
    -webkit-transform: translate3d(-80vw, 0, 0), scale(0.3);
            transform: translate3d(-80vw, 0, 0), scale(0.3); }
  100% {
    -webkit-transform: translate3d(200vw, 0, 0);
            transform: translate3d(200vw, 0, 0); } }

@-webkit-keyframes animateCloud3 {
  0% {
    -webkit-transform: translate3d(-100vw, 0, 0), scale(0.5);
            transform: translate3d(-100vw, 0, 0), scale(0.5); }
  100% {
    -webkit-transform: translate3d(200vw, 0, 0);
            transform: translate3d(200vw, 0, 0); } }

@keyframes animateCloud3 {
  0% {
    -webkit-transform: translate3d(-100vw, 0, 0), scale(0.5);
            transform: translate3d(-100vw, 0, 0), scale(0.5); }
  100% {
    -webkit-transform: translate3d(200vw, 0, 0);
            transform: translate3d(200vw, 0, 0); } }

@-webkit-keyframes animateCloud4 {
  0% {
    -webkit-transform: translate3d(-110vw, 0, 0), scale(0.4);
            transform: translate3d(-110vw, 0, 0), scale(0.4); }
  100% {
    -webkit-transform: translate3d(200vw, 0, 0);
            transform: translate3d(200vw, 0, 0); } }

@keyframes animateCloud4 {
  0% {
    -webkit-transform: translate3d(-110vw, 0, 0), scale(0.4);
            transform: translate3d(-110vw, 0, 0), scale(0.4); }
  100% {
    -webkit-transform: translate3d(200vw, 0, 0);
            transform: translate3d(200vw, 0, 0); } }

@-webkit-keyframes animateCloud5 {
  0% {
    -webkit-transform: translate3d(-60vw, 0, 0), scale(0.55);
            transform: translate3d(-60vw, 0, 0), scale(0.55); }
  100% {
    -webkit-transform: translate3d(200vw, 0, 0);
            transform: translate3d(200vw, 0, 0); } }

@keyframes animateCloud5 {
  0% {
    -webkit-transform: translate3d(-60vw, 0, 0), scale(0.55);
            transform: translate3d(-60vw, 0, 0), scale(0.55); }
  100% {
    -webkit-transform: translate3d(200vw, 0, 0);
            transform: translate3d(200vw, 0, 0); } }

/* ANIMATIONS */
.clouds-wrap {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  margin-left: -50%;
  z-index: -1; }

.cloud-x1 {
  -webkit-animation: animateCloud1 50s ease-in-out infinite;
          animation: animateCloud1 50s ease-in-out infinite;
  -webkit-transform: scale(0.2);
          transform: scale(0.2); }

.cloud-x2 {
  -webkit-animation: animateCloud2 100s ease-in-out infinite;
          animation: animateCloud2 100s ease-in-out infinite;
  -webkit-transform: scale(0.35);
          transform: scale(0.35); }

.cloud-x3 {
  -webkit-animation: animateCloud3 60s ease-in-out infinite;
          animation: animateCloud3 60s ease-in-out infinite;
  -webkit-transform: scale(0.6);
          transform: scale(0.6); }

.cloud-x4 {
  -webkit-animation: animateCloud4 80s ease-in-out infinite;
          animation: animateCloud4 80s ease-in-out infinite;
  -webkit-transform: scale(0.3);
          transform: scale(0.3); }

.cloud-x5 {
  -webkit-animation: animateCloud5 70s ease-in-out infinite;
          animation: animateCloud5 70s ease-in-out infinite;
  -webkit-transform: scale(0.45);
          transform: scale(0.45); }

/* OBJECTS */
[class*="cloud-x"] {
  will-change: transform; }

.cloud {
  background: #11d6d7;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #11d6d7), to(#11d6d7));
  background: -webkit-linear-gradient(top, #11d6d7 5%, #11d6d7 100%);
  background: -o-linear-gradient(top, #11d6d7 5%, #11d6d7 100%);
  background: linear-gradient(to bottom, #11d6d7 5%, #11d6d7 100%);
  border-radius: 100px;
  height: 120px;
  position: relative;
  width: 350px; }

.cloud:after, .cloud:before {
  background: #11d6d7;
  content: "";
  position: absolute;
  z-indeX: -1; }

.cloud:after {
  border-radius: 100px;
  height: 100px;
  left: 50px;
  top: -50px;
  width: 100px; }

.cloud:before {
  border-radius: 200px;
  width: 180px;
  height: 180px;
  right: 50px;
  top: -90px; }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.help-character {
  position: absolute;
  display: none;
  width: 7rem;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  z-index: 1030; }

@media (min-width: 768px) {
  .help-character {
    display: block;
    bottom: 7rem; } }

@media (min-width: 992px) {
  .help-character {
    bottom: 5rem; } }

.container-help-bubble {
  margin-top: -20vh;
  max-width: 50vw; }

.container-help-close {
  position: relative;
  bottom: 10vh; }

.container-help-owl {
  bottom: -10vh;
  left: -20vw;
  height: 50%;
  width: 50%;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
  position: absolute; }

.container-help-lightbulb {
  height: 10vh;
  width: auto; }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.question-dialog-container {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important; }

@media (min-width: 576px) {
  .question-dialog-container {
    margin: 0 15% !important; } }

@media (min-width: 992px) {
  .question-dialog-container {
    margin: 0 24% !important; } }

.question-dialog-container:before {
  content: "";
  height: 5vh;
  position: absolute;
  width: 100%;
  display: block;
  z-index: 10;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #11D1D2), to(rgba(17, 209, 210, 0)));
  background: -webkit-linear-gradient(top, #11D1D2 10%, rgba(17, 209, 210, 0) 100%);
  background: -o-linear-gradient(top, #11D1D2 10%, rgba(17, 209, 210, 0) 100%);
  background: linear-gradient(to bottom, #11D1D2 10%, rgba(17, 209, 210, 0) 100%); }

.question-dialog-footer {
  min-height: 15vh;
  margin-bottom: 1rem; }

.keyboard-button__erase.keyboard-button__multiple {
  height: 58px;
  border-radius: 10rem;
  padding: 6px; }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.question-footer {
  min-height: 112px;
  position: relative;
  -webkit-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  padding-bottom: 0.5rem;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 0.5rem) !important;
  padding-bottom: calc(env(safe-area-inset-bottom) + 0.5rem) !important; }

@media (min-width: 576px) {
  .question-footer {
    min-height: 125px; } }

@media (min-width: 768px) {
  .question-footer {
    min-height: 150px; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.main-header {
  z-index: 1700;
  font-size: 1rem;
  padding-top: 1.5rem;
  padding-top: calc(constant(safe-area-inset-top) + 0.5rem) !important;
  padding-top: calc(env(safe-area-inset-top) + 0.5rem) !important; }
  .main-header .header-logo svg {
    height: 1.25rem;
    margin: 0.75rem 0; }
  .main-header .header-icon svg {
    max-height: 3rem;
    max-width: 3rem;
    margin: 0; }
  .main-header #OLLIS {
    fill: #0EB0B1; }

@media (min-width: 576px) {
  .main-header .header-icon svg {
    max-height: 2.5rem;
    max-width: 2.5rem; } }

@media (min-width: 768px) {
  .main-header .header-icon svg {
    max-height: 3rem;
    max-width: 3rem; } }

@media (min-width: 992px) {
  .main-header .header-icon svg {
    max-height: 3rem;
    max-width: 3rem; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.landing-form {
  padding: 2rem; }

.landing-header {
  margin: 0.5rem auto; }
  .landing-header svg {
    max-width: 50vw; }

.landing-display {
  color: #314251;
  font-size: 1.4rem;
  margin: 0.5rem 0; }

.landing-character {
  text-align: center; }
  .landing-character svg {
    max-height: 30vh;
    max-width: 50%; }

@media (min-width: 576px) {
  .landing-header {
    margin-bottom: 1rem; }
    .landing-header svg {
      max-width: 35vw; }
  .landing-character svg {
    max-height: 75vh;
    max-width: 50%; } }

@media (min-width: 768px) {
  .landing-header {
    margin-bottom: 2rem; }
    .landing-header svg {
      max-width: 30vw; }
  .landing-character {
    margin-bottom: 1rem; }
    .landing-character svg {
      max-height: 45vh;
      max-width: 80%; } }

@media (min-width: 992px) {
  .landing-form {
    max-width: 500px; }
  .landing-header {
    margin-bottom: 3rem; }
    .landing-header svg {
      max-width: 25vw; }
  .landing-character {
    margin-bottom: 2rem; }
    .landing-character svg {
      max-height: 45vh;
      max-width: 75%; } }


.statsContainer {
  overflow: scroll;
  height: 100%;
}

.statsTable,
.statsTable tr,
.statsTable td,
.statsTable th {
  border: 1px solid grey;
  padding: 10px;
  font-weight: normal;
}

.statsTable {
  margin: 0 auto;
}

.statsContainer {
  overflow: scroll;
  height: 100%;
}

.statsTable,
.statsTable tr,
.statsTable td,
.statsTable th {
  border: 1px solid grey;
  padding: 10px;
  font-weight: normal;
}

.statsTable {
  margin: 0 auto;
}

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.character-1 {
  display: none;
  width: 100%;
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
  position: absolute; }

.character-2 {
  display: none;
  width: 100%;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
  position: absolute; }

.overlay-title {
  text-align: left;
  font-size: 1.5rem; }

.parental-gate-overlay {
  overflow-y: scroll !important;
  overflow-x: hidden !important; }

.parental-gate-overlay__content {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 100%;
  padding: 0; }
  .parental-gate-overlay__content form {
    width: 90%; }

.parental-gate-overlay {
  overflow-y: hidden !important; }

.overlay-title {
  text-align: center;
  font-size: 2rem; }

@media (min-width: 576px) {
  .parental-gate-overlay__content {
    height: auto;
    border-radius: 0.3rem;
    padding: 1.5rem; }
  .character-1 {
    display: block;
    bottom: -5rem;
    left: -29rem;
    height: 25rem; }
  .character-2 {
    display: block;
    bottom: -5rem;
    right: -28rem;
    height: 50rem; } }

@media (min-width: 768px) {
  .overlay-title {
    text-align: center;
    font-size: 2rem; }
  .character-1 {
    bottom: -4rem;
    left: -25rem;
    height: 22rem; }
  .character-2 {
    bottom: -10rem;
    right: -30rem;
    height: 50rem; } }

@media (min-width: 992px) {
  .character-1 {
    bottom: -6rem;
    left: -30rem;
    height: 27rem; }
  .character-2 {
    bottom: -12rem;
    right: -35rem;
    height: 63rem; } }

@media (min-width: 1200px) {
  .character-1 {
    bottom: -6rem;
    left: -46rem;
    height: 33rem; }
  .character-2 {
    bottom: -15rem;
    right: -45rem;
    height: 70rem; } }

.overlay-copy > div, .overlay-copy > div > span {
  display: block;
  height: 100% !important;
  width: 100% !important; }

.overlay-copy ol {
  padding-left: 1.5rem; }

.overlay-copy a {
  color: #CFE676;
  overflow-wrap: break-word; }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.orientation-warning {
  color: #17232C;
  text-align: center;
  font-size: 1rem;
  margin: 1rem 0 0 0; }

@media (min-width: 768px) {
  .orientation-warning {
    font-size: 1.25rem;
    margin: 2rem 0;
    max-width: 50vh; } }

@media (min-width: 992px) {
  .orientation-warning {
    max-width: 80vh; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.connection-warning {
  color: #17232C;
  text-align: center;
  font-size: 1rem;
  margin: 1rem 0 0 0; }

@media (min-width: 768px) {
  .connection-warning {
    font-size: 1.25rem;
    margin: 2rem 0;
    max-width: 50vh; } }

@media (min-width: 992px) {
  .connection-warning {
    max-width: 80vh; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.generic-warning {
  color: #17232C;
  text-align: center;
  font-size: 1rem;
  margin: 1rem 0 0 0; }

@media (min-width: 768px) {
  .generic-warning {
    font-size: 1.25rem;
    margin: 2rem 0;
    max-width: 50vh; } }

@media (min-width: 992px) {
  .generic-warning {
    max-width: 80vh; } }

.box-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.deprecated-version-warning {
  color: #17232C;
  text-align: center;
  font-size: 1rem;
  margin: 1rem 0 0 0; }

@media (min-width: 768px) {
  .deprecated-version-warning {
    font-size: 1.25rem;
    margin: 2rem 0;
    max-width: 50vh; } }

@media (min-width: 992px) {
  .deprecated-version-warning {
    max-width: 80vh; } }


// stylelint-disable selector-type-no-unknown
@import url("https://fonts.googleapis.com/css?family=Quicksand:400,500,700&subset=latin-ext");

html, body {
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;

  background-color: $brainimals-aqua;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;

  *:not(input), *:not(label) {
    cursor: default;
  }
  *:not(input) {
    user-select: none;
  }
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;

  > .invisible {
    display: none !important;
  }

  > div:not(.invisible) {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
  }
}

canvas {
  user-select: none;
}

.env {
  &-padding {
    & {
      padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left) !important;
      padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
    }

    &-left {
      padding-left: constant(safe-area-inset-left) !important;
      padding-left: env(safe-area-inset-left) !important;
    }

    &-top {
      padding-top: constant(safe-area-inset-top) !important;
      padding-top: env(safe-area-inset-top) !important;
    }

    &-right {
      padding-right: constant(safe-area-inset-right) !important;
      padding-right: env(safe-area-inset-right) !important;
    }

    &-bottom {
      padding-bottom: constant(safe-area-inset-bottom) !important;
      padding-bottom: env(safe-area-inset-bottom) !important;
    }
  }
  &-margin {
    & {
      margin: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left) !important;
      margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
    }

    &-left {
      margin-left: constant(safe-area-inset-left) !important;
      margin-left: env(safe-area-inset-left) !important;
    }

    &-top {
      margin-top: 1rem;
      margin-top: constant(safe-area-inset-top) !important;
      margin-top: env(safe-area-inset-top) !important;
    }

    &-right {
      margin-right: constant(safe-area-inset-right) !important;
      margin-right: env(safe-area-inset-right) !important;
    }

    &-bottom {
      margin-bottom: constant(safe-area-inset-bottom) !important;
      margin-bottom: env(safe-area-inset-bottom) !important;
    }
  }
}

.hr-main--top {
  border-top: ($grid-gutter-width / 3) solid
    theme-color("brainimals-darker-cyan");
}

.main-title {
  background-image: linear-gradient(
    -180deg,
    #3C4145 0%,
    rgba(60, 65, 69, 0.8) 99%
  );
}

.injected-svg {
  height: 100%;
  width: 100%;
}

@each $type
  in (
    auto,
    default,
    none,
    context-menu,
    help,
    pointer,
    progress,
    wait,
    cell,
    crosshair,
    text,
    vertical-text,
    alias,
    copy,
    move,
    no-drop,
    not-allowed,
    e-resize,
    n-resize,
    ne-resize,
    nw-resize,
    s-resize,
    se-resize,
    sw-resize,
    w-resize,
    ew-resize,
    ns-resize,
    nesw-resize,
    nwse-resize,
    col-resize,
    row-resize,
    all-scroll,
    zoom-in,
    zoom-out,
    grab,
    grabbing
  )
{
  .cursor-#{$type} {
    // stylelint-disable-next-line declaration-no-important
    &, & * {
      cursor: $type !important;
    }
  }
}

@include scrollbars(0.3rem, rgba($brainimals-topaz, 0), rgba($brainimals-aqua, 0));

@each $type in (visible, hidden, scroll, auto, visible, inherit, initial, unset)
{
  .overflow-#{$type} {
    // stylelint-disable-next-line declaration-no-important
    overflow: $type !important;
    -webkit-overflow-scrolling: touch;
  }

  .overflow-x-#{$type} {
    // stylelint-disable-next-line declaration-no-important
    overflow-x: $type !important;
    -webkit-overflow-scrolling: touch;
  }

  .overflow-y-#{$type} {
    // stylelint-disable-next-line declaration-no-important
    overflow-y: $type !important;
    -webkit-overflow-scrolling: touch;
  }
}

.squircle {
  $w: 150px;
  $h: 150px;
  $c: #ED2F5B;
  width: $w;
  height: $h;
  @include super-rectangle($w, $h, $c);
}

.no-shadow {
  @include box-shadow(none !important);
}

.bg-backdrop {
  background-color: rgba($brainimals-deep-sea-blue, 0.85);
  backdrop-filter: blur(20px) brightness(125%) saturate(150%);

  @include scrollbars(0.3rem, rgba($brainimals-deep-sea-blue, 0.75), rgba($brainimals-deep-sea-blue, 0.25));
}

@each $color, $value in $theme-colors {
  .bg-backdrop-#{$color} {
    background-color: rgba($value, 0.85);
    backdrop-filter: blur(20px) brightness(125%) saturate(150%);

    @include scrollbars(0.3rem, rgba($value, 0.75), rgba($value, 0.25));
  }
  .bg-backdrop-#{$color}__pale {
    background-color: rgba($value, 0.75);
    backdrop-filter: blur(20px) saturate(150%);

    @include scrollbars(0.3rem, rgba($value, 0.75), rgba($value, 0.25));
  }
}

@each $level, $index in $zindex-levels {
  .zindex-#{$level} {
    z-index: $index;
  }
}

@each $size, $value in $font-sizes {
  .font-size-#{$size} {
    font-size: $value;
  }
}

.swiper-container {
  .swiper-wrapper {
    .swiper-slide {
      @include transition();
      transform: scale(0.9);

      width: 70%;
      @include media-breakpoint-up(sm) { width: 60%; }
      @include media-breakpoint-up(md) { width: 50%; }

      &-active {
        transform: scale(1);
      }
    }
  }

  .swiper-pagination {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .pb-3;
    @extend .env-padding-bottom;

    &-bullets {
      bottom: 1.5rem;

      .swiper-pagination-bullet {
        $bullet_base_size: 6px;
        $bullet_base_margin: $bullet_base_size * 1.5;

        width: $bullet_base_size;
        height: $bullet_base_size;
        border-radius: $bullet_base_size;
        margin-left: $bullet_base_margin;
        margin-right: $bullet_base_margin;
        border: none;
        background-color: rgba($brainimals-pale-grey, 0.70´);
        padding: 0;

        transition: all 250ms ease-in-out;

        &:hover {
          cursor: pointer;
          border: none;
          background-color: $white;
        }

        &:focus {
          outline: 0;
        }

        &-active {
          background-color: $white;
          width: $bullet_base_size * 3;
        }
      }
    }
  }
}


@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.signed-in-landing {
  &__container {
    padding-top: 5rem;
    z-index: -5;

    @include media-breakpoint-up(sm) {
      padding-top: 6rem;
    }
  }

  &__wrapper {
    padding-bottom: 20px;
  }

  &__intro {
    display: flex;
    flex: 0 0 auto;
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
      margin-bottom: 2rem;
      margin-top: 1rem;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 2rem;
      margin-top: 3rem;
    }
  }

  &__character {
    width: 100%;

    display: none;

    @include media-breakpoint-up(sm) {
      display: inherit;
    }
  }

  &__headline {
    color: #fff;
    margin-bottom: 1rem;

    &:not(:first-of-type) {
      margin-top: 3rem;
    }
  }

  &__more-text {
    font-size: 20px;
    margin: 2rem 0;
    text-align: center;
  }

  &__bottom-items {
    display: flex;
    > div {
      flex: 1 1 auto;
    }
  }
}

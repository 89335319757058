.placeholder {
  cursor: default;
  color: $placeholder-color;
  display: inline;
  padding: 0.25rem;
  z-index: 1090;
  text-align: center;

  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, opacity 250ms ease-in-out;
}

.bubble__dragging-over .placeholder__bubble {
  transform: scale(1.1) !important;
}

.bubble span:not([class*="placeholder__underscore"]):not([class*="placeholder__bubble"]):not([class*="font-"]):not([class*="text-"]) {
  margin: auto 10px -10px;
}

.bubble span[class*="placeholder__underscore"] {
  margin: auto -1px -10px;
}

.placeholder__bubble {
  background-color: $placeholder-bubble-background-color;
  border-color: $brainimals-deep-sea-blue;
  border-radius: $placeholder-bubble-border-radius;
  border-width: $placeholder-bubble-border-width;
  border-style: $placeholder-bubble-border-style;
  text-align: center;
  padding: $placeholder-bubble-padding-y $placeholder-bubble-padding-x !important;
}
.placeholder__bubble:empty {
  padding: $placeholder-bubble-padding-y-empty $placeholder-bubble-padding-x-empty !important;
}

@include media-breakpoint-up(md) {
  .placeholder__bubble {
    padding: $placeholder-bubble-padding-y-md $placeholder-bubble-padding-x-md !important;
  }
  .placeholder__bubble:empty {
    padding: $placeholder-bubble-padding-y-md-empty $placeholder-bubble-padding-x-md-empty !important;
  }
}

@include media-breakpoint-up(lg) {
  .placeholder__bubble {
    padding: $placeholder-bubble-padding-y-lg $placeholder-bubble-padding-x-lg !important;
  }
  .placeholder__bubble:empty {
    padding: $placeholder-bubble-padding-y-lg-empty $placeholder-bubble-padding-x-lg-empty !important;
  }
}


.bubble__dragging-over .placeholder__bubble {
  background-color: $placeholder-bubble-background-color-dragging !important;
}

.placeholder__bubble__danger {
  border-color: $placeholder-color-danger;
}

.placeholder__bubble__success {
  border-color: $placeholder-color-success;
}

.placeholder__underscore {
  animation: pulseUnderscore 400ms alternate infinite;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: $placeholder-underscore-border-radius;
  border-style: $placeholder-underscore-border-style;
  border-width: $placeholder-border-width;
  border-color: transparent;
  padding: $placeholder-underscore-padding-y $placeholder-underscore-padding-x !important;
}
.placeholder__underscore:empty {
  padding: $placeholder-underscore-padding-y-empty $placeholder-underscore-padding-x-empty !important;
}

@include media-breakpoint-up(sm) {
  .placeholder__underscore {
    padding: $placeholder-underscore-padding-y-sm $placeholder-underscore-padding-x-sm !important;
  }
  .placeholder__underscore:empty {
    padding: $placeholder-underscore-padding-y-sm-empty $placeholder-underscore-padding-x-sm-empty !important;
  }
}

@include media-breakpoint-up(md) {
  .placeholder__underscore {
    padding: $placeholder-underscore-padding-y-md $placeholder-underscore-padding-x-md !important;
  }
  .placeholder__underscore:empty {
    padding: $placeholder-underscore-padding-y-md-empty $placeholder-underscore-padding-x-md-empty !important;
  }
}

@include media-breakpoint-up(lg) {
  .placeholder__underscore {
    padding: $placeholder-underscore-padding-y-lg $placeholder-underscore-padding-x-lg !important;
  }
  .placeholder__underscore:empty {
    padding: $placeholder-underscore-padding-y-lg-empty $placeholder-underscore-padding-x-lg-empty !important;
  }
}

.placeholder__underscore__danger,
.placeholder__underscore__success {
  border-style: $placeholder-underscore-border-style;
}

.placeholder__underscore__danger {
  animation: pulseUnderscoreWarning 400ms alternate infinite;
  border-color: RGBA(230, 108, 103, 1.00);
  color: RGBA(230, 108, 103, 1.00);
}

.placeholder__underscore__success {
  animation: pulseUnderscoreSuccess 400ms alternate infinite;
  border-color: RGBA(173, 231, 63, 1.00);
  color: RGBA(173, 231, 63, 1.00);
}

table .placeholder__underscore__danger,
table .placeholder__underscore__success {
  width: 100%;
}

@keyframes pulseUnderscore {
  0% {
    border-bottom-color: rgba(255, 255, 255, 0.6);
  }
  100% {
    border-bottom-color: rgba(255, 255, 255, 1.00);
  }
}

@keyframes pulseUnderscoreWarning {
  0% {
    border-color: RGBA(230, 108, 103, 0.6);
  }
  100% {
    border-color: RGBA(230, 108, 103, 1.00);
  }
}

@keyframes pulseUnderscoreSuccess {
  0% {
    border-color: RGBA(173, 231, 63, 0.6);
  }
  100% {
    border-color: RGBA(173, 231, 63, 1.00);
  }
}

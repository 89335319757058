@import "node_modules/bootstrap/scss/_type.scss";

.line-height-normal {
  line-height: normal;
}

@each $size in (1) {
  .line-height-#{$size} {
    // stylelint-disable-next-line declaration-no-important
    line-height: #{$size} !important;
  }
}

.text-highlight {
  background-color: $brainimals-blue-grey;
  padding: 0.2rem 0.5rem;
  margin: 0 !important;
  color: $white;
  border-radius: $border-radius;
}

.text-highlight:not(:last-of-type) {
  margin-right: 2px !important;
}

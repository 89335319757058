@charset "UTF-8";
.box-shadow {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.box-shadow-sm {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 0 35px 0 rgba(43, 90, 98, 0.1); }

.box-shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.box-shadow-soft {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 7px 0 rgba(0, 0, 0, 0.03); }

.main-container {
  height: 100%;
  overflow: hidden;
  padding: 0; }

.main-container .progression,
.main-container h1,
.main-container h2 {
  position: relative;
  z-index: 2;
  width: 100%; }

.main-container .progression {
  max-width: 60%; }

* {
  font-family: "Quicksand", "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 500 !important; }

strong, b  {
  font-weight: 700 !important; }

.chat-character {
  position: absolute;
  display: none;
  width: 7rem;
  transform: translateX(-100%);
  z-index: 1030; }

@media (min-width: 768px) {
  .chat-character {
    display: block; } }

.svg-inline > div {
  display: inline; }

.svg-inline > div > div {
  display: inline; }

@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.close-button {
  &,
  & * {
    cursor: pointer !important;
  }

  position: fixed;

  top: 1rem;
  right: 1rem;

  svg {
    max-height: 3rem;
    max-width: 3rem;
    margin: 0;
  }
}

@include media-breakpoint-up(sm) {
  .close-button {
    top: 1rem;
    right: 1rem;

    svg {
      max-height: 2.5rem;
      max-width: 2.5rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .close-button {
    top: 2.5rem;
    right: 2.5rem;

    svg {
      max-height: 3rem;
      max-width: 3rem;
    }
  }
}

.help-button {
	position: absolute;
  z-index: 10;

  top: 20px;
  left: 20px;
  height: 12vw;
  width: 12vw;
}

@include media-breakpoint-up(sm) {
  .help-button {
    top: 10px;
    left: 10px;
    height: 10vw;
    width: 10vw;
  }
}

@include media-breakpoint-up(md) {
  .help-button {
    display: none;
    top: 40px;
    left: 40px;
    height: 60px;
    width: 60px;
  }
}

@include media-breakpoint-up(lg) {
  .help-button {
    top: 40px;
    left: 40px;
    height: 80px;
    width: 80px;
  }
}

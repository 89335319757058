@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.logout-button {
  &,
  & * {
    cursor: pointer !important;
  }

  svg {
    max-height: 3rem;
    max-width: 3rem;
    margin: 0;
  }
}

@include media-breakpoint-up(sm) {
  .logout-button {
    svg {
      max-height: 2.5rem;
      max-width: 2.5rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .logout-button {
    svg {
      max-height: 3rem;
      max-width: 3rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .logout-button {
    svg {
      max-height: 3rem;
      max-width: 3rem;
    }
  }
}

@import "node_modules/bootstrap/scss/_buttons.scss";

.btn {
  object-fit: contain;
  min-height: fit-content;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @extend .btn-#{$color};

    @include box-shadow($box-shadow-raised);
  }
}

@each $color, $value in $grays {
  .btn-gray-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $grays {
  .btn-outline-gray-#{$color} {
    @include button-outline-variant($value);
  }
}

// Keyboard buttons

.keyboard-button {
  @extend .btn;

  &:hover {
    cursor: pointer;
    color: white;
  }

  &.disabled {
    cursor: default !important;
  }
}

@include media-breakpoint-up(md) {
  .keyboard-button {
    @include bubble-size($btn-padding-x-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, 3rem);

    &::after {
      @include bubble-size($btn-padding-x-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, 3rem);
    }
  }
}

.keyboard-button {
  min-width: 2.4rem;

  transition: all 250ms ease-in-out;
  position: relative;

  &::after {
    visibility: hidden;
    pointer-events: none;
    transition: all 250ms ease-in-out;
  }
}

.keyboard-button__dragging {
  background-color: transparent !important;
  color: transparent !important;

  &::after {
    @extend .keyboard-button;

    min-width: min-content;

    color: $white !important;
    visibility: visible;
    position: absolute;

    top: -1rem;
    right: 0;
    left: 0;

    content: attr(data-value);
    padding-left: 0;
    padding-right: 0;

    background-color: rgba($brainimals-deep-sea-blue, 0.5) !important;
    backdrop-filter: blur(10px) brightness(105%) saturate(150%);

    bottom: 1rem;
    margin-top: -$font-size-base * 2;
    //transform: scale(1.5);
  }
}

@include media-breakpoint-up(md) {
  .keyboard-button {
    min-width: 4.2rem;

    &::after {
      bottom: 1rem;
      margin-top: -$font-size-lg * 2;
    }
  }

  .keyboard-button__dragging {
    &::after {
      top: -1rem;
      transform: scale(1);
    }
  }
}

.keyboard-button__numpad.keyboard-button__dragging {
  &::after {
    border-radius: $btn-border-radius;
  }
}
.keyboard-button__multiple.keyboard-button__dragging {
  &::after {
    border-radius: $btn-border-radius;
  }
}

.keyboard-button__multiple {
  color: $white;
  background-color: $brainimals-deep-sea-blue;
}

.keyboard-button__numpad {
  background-color: rgba($brainimals-deep-sea-blue, 0.5);
}

.keyboard-button__confirm,
.keyboard-button__erase,
.keyboard-button__erase-floating {
  width: 40px;

  div {
    height: 100%;
    width: 100%;

    svg {
      height: 100% !important;
      width: 100% !important;
    }
  }
}

.keyboard-button__numpad,
.keyboard-button__confirm,
.keyboard-button__erase,
.keyboard-button__erase-floating {
  color: $white;
  object-fit: contain;

  margin: auto;
  padding: 0.45rem;

  border-radius: 100%;

  div {
    svg {
      height: 80% !important;
      width: 80% !important;
    }
  }
}

.keyboard-button__erase.keyboard-button__multiple,
.keyboard-button__erase-floating.keyboard-button__multiple,
.keyboard-button__multiple,
.keyboard-button__numpad,
.keyboard-button__confirm,
.keyboard-button__erase,
.keyboard-button__erase-floating {
  height: 40px;
  min-width: 40px;
  padding: 0.4rem 0.5rem;
  font-size: 1rem;
}

@include media-breakpoint-up(sm) {
  .keyboard-button__erase.keyboard-button__multiple,
  .keyboard-button__erase-floating.keyboard-button__multiple,
  .keyboard-button__multiple,
  .keyboard-button__numpad,
  .keyboard-button__confirm,
  .keyboard-button__erase,
  .keyboard-button__erase-floating {

    height: 46px;
    min-width: 46px;
    padding: 0.6rem 0.5rem;
    font-size: 0.9rem;

    div {
      svg {
        height: 85% !important;
        width: 85% !important;
      }
    }
  }

  .keyboard-button__confirm,
  .keyboard-button__erase,
  .keyboard-button__erase-floating {
    width: 46px;
  }
}
@include media-breakpoint-up(md) {
  .keyboard-button__erase.keyboard-button__multiple,
  .keyboard-button__erase-floating.keyboard-button__multiple,
  .keyboard-button__multiple,
  .keyboard-button__numpad,
  .keyboard-button__confirm,
  .keyboard-button__erase,
  .keyboard-button__erase-floating {

    height: 56px;
    min-width: 56px;
    padding: 0.75rem;
    font-size: 1.2rem;

    div {
      svg {
        height: 70% !important;
        width: 70% !important;
      }
    }
  }

  .keyboard-button__confirm,
  .keyboard-button__erase,
  .keyboard-button__erase-floating {
      width: 56px;
  }
}

.keyboard-button__confirm,
.keyboard-button__erase,
.keyboard-button__erase-floating {
  background-color: rgba(255, 255, 255, 0.15);
  cursor: default;
}


.keyboard-button__confirm.keyboard-button__enabled {
  background-color: $brainimals-green-light;
  &, & * {
    cursor: pointer;
  }
}
.keyboard-button__erase.keyboard-button__enabled,
.keyboard-button__erase-floating.keyboard-button__enabled {
  background-color: rgba(1, 80, 123, 0.3);
  &, & * {
    cursor: pointer;
  }
}
.keyboard-button__erase.keyboard-button__enabled:hover,
.keyboard-button__erase-floating.keyboard-button__enabled:hover {
  background-color: rgba(1, 80, 123, 0.6);
}

.keyboard-button__erase.keyboard-button__multiple,
.keyboard-button__erase-floating.keyboard-button__multiple {
  border-radius: 10rem;
  padding: 6px;
}

.keyboard-button .injected-svg {
  vertical-align: middle;
}

@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.question-footer {
  min-height: 112px;
  position: relative;

  transition: all 250ms ease-in-out;

  $base-padding-bottom: 0.5rem;
  padding-bottom: $base-padding-bottom;
  padding-bottom: calc(constant(safe-area-inset-bottom) + #{$base-padding-bottom}) !important;
  padding-bottom: calc(env(safe-area-inset-bottom) + #{$base-padding-bottom}) !important;
}

@include media-breakpoint-up(sm) {
  .question-footer {
    min-height: 125px;
  }
}

@include media-breakpoint-up(md) {
  .question-footer {
    min-height: 150px;
  }
}

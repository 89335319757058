@import "node_modules/bootstrap/scss/_button-group.scss";

.btn-group {
  .btn {
    box-shadow: none;
  }

  @include box-shadow($box-shadow-raised);

  border-radius: $border-radius;

  &.btn-group-lg {
    border-radius: $border-radius-lg;
  }

  &.btn-group-sm {
    border-radius: $border-radius-sm;
  }
}

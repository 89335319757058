@import "node_modules/bootstrap/scss/_list-group.scss";

// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

@each $color, $value in $theme-colors {
  @include list-group-item-plain-variant($color, $value);
}

.list-group-item {
  @extend .list-group-item;
  transition: $transition-base;

  &*[class*="list-group-item"] {
    @include transition();
  }
}

.alert {
  @extend .alert;
}

@each $color, $value in $theme-colors {
  @include list-group-item-variant(
    $color,
    theme-color-level($color, $alert-bg-level),
    theme-color-level($color, $alert-color-level)
  );
}

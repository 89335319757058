@import "node_modules/bootstrap/scss/_forms.scss";

.form-control-search {
  @extend .align-self-end;
  @extend .mr-2;

  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  pointer-events: none;
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTkuNzkzIDE5LjY4M2wtNC43NDEtNC43MDVhOC43NyA4Ljc3IDAgMCAwIC4yMy0xMi4xNDZBOC44MiA4LjgyIDAgMCAwIDMuMTI5IDIuMDdhOC43NyA4Ljc3IDAgMCAwLTEuMjk3IDEyLjA4YzIuODc4IDMuNzIzIDguMTg0IDQuNTI2IDEyLjA0IDEuODIxbDQuODM0IDQuODIzYS43NzEuNzcxIDAgMCAwIDEuMDY5LS4wMTkuNzY3Ljc2NyAwIDAgMCAuMDE4LTEuMDY2di0uMDI2ek0xLjY3MyA4LjgzNWMwLTMuOTU5IDMuMjE3LTcuMTY4IDcuMTg1LTcuMTY4IDMuOTY4IDAgNy4xODQgMy4yMSA3LjE4NCA3LjE2OHMtMy4yMTYgNy4xNjctNy4xODQgNy4xNjctNy4xODQtMy4yMDktNy4xODQtNy4xNjd6IiBmaWxsPSIjM0M0MTQ1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4K");
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: $input-padding-y $input-padding-x;
}

@include media-breakpoint-up(sm) {
  .form-label-group > input,
  .form-label-group > label {
    padding: $input-padding-y-sm $input-padding-x-sm;
  }
}

@include media-breakpoint-up(md) {
  .form-label-group > input,
  .form-label-group > label {
    padding: $input-padding-y-md $input-padding-x-md;
  }
}

@include media-breakpoint-up(lg) {
  .form-label-group > input,
  .form-label-group > label {
    padding: $input-padding-y-lg $input-padding-x-lg;
  }
}

.form-control + label {
  @extend .form-control;
  @extend .m-0;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  color: $input-placeholder-color;
  background-color: transparent;

  transition: $transition-bounce;
  pointer-events: none;
}

.form-control-sm + label {
  @extend .form-control-sm;
}

.form-control-lg + label {
  @extend .form-control-lg;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: $input-padding-y + $input-padding-y * (2 / 3);
  padding-bottom: $input-padding-y / 3;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: $input-padding-y / 3;
  padding-bottom: $input-padding-y / 3;
  font-size: 12px;
  color: $input-placeholder-color;
}

@include media-breakpoint-up(sm) {
  .form-label-group input:not(:placeholder-shown) {
    padding-top: $input-padding-y-sm + $input-padding-y-sm * (2 / 3);
    padding-bottom: $input-padding-y-sm / 3;
  }

  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: $input-padding-y-sm / 3;
    padding-bottom: $input-padding-y-sm / 3;
  }
}

@include media-breakpoint-up(md) {
  .form-label-group input:not(:placeholder-shown) {
    padding-top: $input-padding-y-md + $input-padding-y-md * (2 / 3);
    padding-bottom: $input-padding-y-md / 3;
  }

  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: $input-padding-y-md / 3;
    padding-bottom: $input-padding-y-md / 3;
  }
}

@include media-breakpoint-up(lg) {
  .form-label-group input:not(:placeholder-shown) {
    padding-top: $input-padding-y-lg + $input-padding-y-lg * (2 / 3);
    padding-bottom: $input-padding-y-lg / 3;
  }

  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: $input-padding-y-lg / 3;
    padding-bottom: $input-padding-y-lg / 3;
  }
}

@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.program-done-celebration-title {
  margin-top: 5vh;
}
.program-done-celebration-footer {
  margin-bottom: 5vh;
}

.program-done-celebration {
  transform: scale(1.5) translate3d(0, 0, 0);
  z-index: -1;
}

@include media-breakpoint-up(sm) {
  .program-done-celebration {
    transform: scale(1.25) translate3d(0, 0, 0);
  }

  .program-done-celebration-title {
    margin-top: 8vh;
  }
  .program-done-celebration-footer {
    margin-bottom: 8vh;
  }
}

@include media-breakpoint-up(lg) {
  .program-done-celebration {
    transform: scale(1) translate3d(0, 0, 0);

    &__level1,
    &__level2 {
      width: 100% !important;
    }
  }

  .program-done-celebration-title {
    margin-top: 10vh;
  }
  .program-done-celebration-footer {
    margin-bottom: 10vh;
  }
}

// Bubble variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all bubbles

@mixin bubble-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
  color: color-yiq($background);
  @include gradient-bg($background);
  //border-width: 0;
  @include box-shadow($btn-box-shadow);

  &:focus,
  &.focus {
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
    } @else {
      box-shadow: 0 0 0 $btn-focus-width rgba($border, .5);
    }
  }
}

// Bubble sizes
@mixin bubble-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @if $enable-rounded {
    border-radius: $border-radius;
  } @else {
    border-radius: 0;
  }
}

@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

@mixin notification-variant($background, $border, $color) {
  color: $color;
  background-color: rgba($background, 0.75);
  backdrop-filter: blur(20px) brightness(125%) saturate(150%);
  border-color: $border;

  .alert-link {
    color: darken($color, 10%);
  }
}

.notification {
  position: fixed;
  height: auto;
  padding: ($alert-padding-y * 0.5) ($alert-padding-x * 0.5);
  margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;
  @include border-radius($alert-border-radius);

  & .notification-icon {
    max-height: 3rem;
    max-width: 3rem;
  }

  h6 {
    margin-bottom: 0;
  }

  .close-button {
    top: 1.5rem;
    right: 0.8rem;
    transform: scale(0.8);
  }
}

@include media-breakpoint-up(sm) {
  .notification {
    padding: $alert-padding-y $alert-padding-x;

    & .notification-icon {
      max-height: 5rem;
      max-width: 5rem;
    }

    h6 {
      font-size: $h5-font-size;
      margin-top: 0.3rem;
    }

    p {
      font-size: $h5-font-size;
    }

    .close-button {
      top: 1.8rem;
      right: 1.8rem;
      transform: scale(1);
    }
  }
}


// Headings for larger notifications
.notification-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match notifications
.notification-link {
  font-weight: $alert-link-font-weight;
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the notification.

@each $color, $value in $theme-colors {
  .notification-#{$color} {
    @include notification-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
  }
}

@each $color, $value in $theme-colors {
  .notification-#{$color} {
    @extend .notification-#{$color};
  }
}

@import "node_modules/bootstrap/scss/_input-group.scss";

//
// Serves as a catch-all element for any text or radio/checkbox input you wish
// to prepend or append to an input.

.input-group-text {
  @extend .input-group-text;

  border: $input-group-addon-border;
}

// .input-group {
//   > *:first-child:not(input):not(select) {
//     flex-grow: 1;
//   }
// }

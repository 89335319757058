@import "node_modules/bootstrap/scss/utilities/spacing.scss";

// stylelint-disable declaration-no-important

// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-n#{$size} {
          #{$prop}: $length !important;
        }
        .#{$abbrev}t#{$infix}-n#{$size},
        .#{$abbrev}y#{$infix}-n#{$size} {
          #{$prop}-top: -($length) !important;
        }
        .#{$abbrev}r#{$infix}-n#{$size},
        .#{$abbrev}x#{$infix}-n#{$size} {
          #{$prop}-right: -($length) !important;
        }
        .#{$abbrev}b#{$infix}-n#{$size},
        .#{$abbrev}y#{$infix}-n#{$size} {
          #{$prop}-bottom: -($length) !important;
        }
        .#{$abbrev}l#{$infix}-n#{$size},
        .#{$abbrev}x#{$infix}-n#{$size} {
          #{$prop}-left: -($length) !important;
        }
      }
    }
  }
}

@import "node_modules/bootstrap/scss/mixins/list-group.scss";
@import "../utilities/color.scss";

@mixin list-group-item-plain-variant($variant, $color) {
  .list-group-item-variant-#{$variant} {
    color: contrasting-color($color, lighten($color, 35%), darken($color, 35%));
    background-color: transparent;

    &.list-group-item-variant-action {
      @include hover-focus {
        color: contrasting-color(
          $color,
          lighten($color, 35%),
          darken($color, 35%)
        );
        background-color: rgba($color, 0.2);
      }

      &.active {
        color: contrasting-color(
          $color,
          lighten($color, 35%),
          darken($color, 35%)
        );
        background-color: $color;
        border-color: $color;
      }
    }
  }
}

@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.connection-warning {
  color: $brainimals-dark;
  text-align: center;
  font-size: $h6-font-size;
  margin: 1rem 0 0 0;
}

@include media-breakpoint-up(md) {
  .connection-warning {
    font-size: $h5-font-size;
    margin: 2rem 0;
    max-width: 50vh;
  }
}

@include media-breakpoint-up(lg) {
  .connection-warning {
    max-width: 80vh;
  }
}

@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.progress-header {
  display: flex;
  background: $brainimals-aqua;
  left: 0;
  justify-content: space-between;
  padding: 0.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

@import "node_modules/bootstrap/scss/alert.scss";

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @extend .alert-#{$color};

    @include box-shadow(
      0 1px 3px 0 rgba($brainimals-dark-grey-blue, 0.05),
      0 4px 7px 0 rgba($brainimals-dark-grey-blue, 0.03),
      0 0 0.15rem rgba($brainimals-dark-grey-blue, 0.08),
      0 0.5rem 1rem rgba($brainimals-dark-grey-blue, 0.1)
    );
  }
}

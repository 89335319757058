@import "node_modules/bootstrap/scss/mixins/border-radius.scss";

@function trim($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0+1);
  }
  @return $number;
}

@function replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @return if(
    $index,
    str-slice($string, 1, $index - 1) + $replace +
    replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      ),
    $string
  );
}

@mixin super-rectangle($width, $height, $color) {
  $width: trim($width);
  $height: trim($height);
  $color: replace("" + $color + "", "#", "%23");
  $halfWidth: $width / 2;
  $halfHeight: $height / 2;
  background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='"+$width+"' height='"+$height+"' viewBox='0 0 "+$width+" "+$height+"' enable-background='new 0 0 "+$width+" "+$height+"' xml:space='preserve'%3E%3Cpath fill='"+$color+"' stroke='none' d='M 0,"+$halfHeight+" C 0,0 0,0 "+$halfWidth+",0 S "+$width+",0 "+$width+","+$halfHeight+" "+$width+","+$height+" "+$halfWidth+","+$height+" 0,"+$height+" 0,"+$halfHeight+"'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: #{$width + "px"} #{$height + "px"};
}
//don't work in Firefox and Edge
@mixin super-rectangle-gradient($width, $height, $color1, $color2) {
  $width: trim($width);
  $height: trim($height);
  $color1: replace("" + $color1 + "", "#", "%23");
  $color2: replace("" + $color2 + "", "#", "%23");
  $halfWidth: $width / 2;
  $halfHeight: $height / 2;
  background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='"+$width+"' height='"+$height+"' viewBox='0 0 "+$width+" "+$height+"' enable-background='new 0 0 "+$width+" "+$height+"' xml:space='preserve'%3E%3ClinearGradient gradientTransform='rotate(45
    )' id='linear-gradient'%3E%3Cstop offset='50%' stop-color='"+$color1+"' /%3E%3Cstop offset='100%' stop-color='"+$color2+"' /%3E%3C/linearGradient%3E%3Cpath fill='url(#linear-gradient)' stroke='none' d='M 0,"+$halfHeight+" C 0,0 0,0 "+$halfWidth+",0 S "+$width+",0 "+$width+","+$halfHeight+" "+$width+","+$height+" "+$halfWidth+","+$height+" 0,"+$height+" 0,"+$halfHeight+"'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: #{$width + "px"} #{$height + "px"};
}

@mixin super-circle($width, $height, $color) {
  $width: trim($width);
  $height: trim($height);
  $color: replace("" + $color + "", "#", "%23");
  $halfWidth: $width / 2;
  $halfHeight: $height / 2;
  background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='shape-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='"+$width+"' height='"+$height+"' viewBox='0 0 "+$width+" "+$height+"' enable-background='new 0 0 "+$width+" "+$height+"' xml:space='preserve'%3E%3Cpath fill='"+$color+"' stroke='none' d='M 0,"+$halfHeight+" Q 0,0 "+$halfWidth+",0 T "+$width+","+$halfHeight+" "+$halfWidth+","+$height+" 0,"+$halfHeight+" '%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: #{$width + "px"} #{$height + "px"};
}

//clip-path dont'work in Chrome
@mixin path($width, $height, $color) {
  background: $color;
  $width: trim($width);
  $height: trim($height);
  $color: replace("" + $color + "", "#", "%23");
  $halfWidth: $width / 2;
  $halfHeight: $height / 2;
  clip-path: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='"+$width+"' height='"+$height+"' viewBox='0 0 "+$width+" "+$height+"' enable-background='new 0 0 "+$width+" "+$height+"' xml:space='preserve'%3E%3Cdefs%3E%3CclipPath id='path'%3E%3Cpath  stroke='none' d='M 0,"+$halfHeight+" C 0,0 0,0 "+$halfWidth+",0 S "+$width+",0 "+$width+","+$halfHeight+" "+$width+","+$height+" "+$halfWidth+","+$height+" 0,"+$height+" 0,"+$halfHeight+"'%3E%3C/path%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E#path");
}

//don't work in Firefox and Edge
@mixin shadow-gradient($w, $h, $c) {
  position: relative;
  &::before {
    @include super-rectangle-gradient($w, $h, black, lightgray);
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: -1;
    width: $w;
    height: $h;
    content: "";
    opacity: 0.2;
  }
}
@mixin shadow-plain($w, $h, $c) {
  position: relative;
  &::before {
    @include super-rectangle($w, $h, black);
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: -1;
    width: $w;
    height: $h;
    content: "";
    opacity: 0.2;
  }
}

@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.exercise-finished__character-badge {
  width: 100%;

  div,
  svg {
    height: 100%;
    width: 100%;
  }
}

.exercise-finished__character-power {
  width: 80%;

  div,
  svg {
    height: 100%;
    width: 100%;
  }
}

.exercise-finished__character-badge {
  @include media-breakpoint-up(md) {
    width: 100%;
    max-width: 400px;
  }
}
.exercise-finished__character-power {
  @include media-breakpoint-up(md) {
    width: 100%;
    max-width: 300px;
  }
}

.exercise-finished__headline {
  text-align: center;
  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
  }
}

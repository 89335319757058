@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.character-1 {
  display: none;
  width: 100%;
  transform: rotate(10deg);
  position: absolute;
}
.character-2 {
  display: none;
  width: 100%;
  transform: rotate(-20deg);
  position: absolute;
}

.overlay-title {
  text-align: left;
  font-size: $h4-font-size;
}

.parental-gate-overlay {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.parental-gate-overlay__content {
  box-sizing: content-box;
  height: 100%;
  padding: 0;

  form {
    width: 90%;
  }
}

@include media-breakpoint-up(xs) {
  .parental-gate-overlay {
    overflow-y: hidden !important;
  }
  .overlay-title {
    text-align: center;
    font-size: $h2-font-size;
  }
}

@include media-breakpoint-up(sm) {
  .parental-gate-overlay__content {
    height: auto;
    border-radius: $border-radius-lg;
    padding: 1.5rem;
  }
  .character-1 {
    display: block;
    bottom: -5rem;
    left: -29rem;
    height: 25rem;
  }
  .character-2 {
    display: block;
    bottom: -5rem;
    right: -28rem;
    height: 50rem;
  }
}

@include media-breakpoint-up(md) {
  .overlay-title {
    text-align: center;
    font-size: $h2-font-size;
  }
  .character-1 {
    bottom: -4rem;
    left: -25rem;
    height: 22rem;
  }
  .character-2 {
    bottom: -10rem;
    right: -30rem;
    height: 50rem;
  }
}

@include media-breakpoint-up(lg) {
  .character-1 {
    bottom: -6rem;
    left: -30rem;
    height: 27rem;
  }
  .character-2 {
    bottom: -12rem;
    right: -35rem;
    height: 63rem;
  }
}

@include media-breakpoint-up(xl) {
  .character-1 {
    bottom: -6rem;
    left: -46rem;
    height: 33rem;
  }
  .character-2 {
    bottom: -15rem;
    right: -45rem;
    height: 70rem;
  }
}

.overlay-copy {
  > div {
    &, & > span {
      display: block;
      height: 100% !important;
      width: 100% !important;
    }
  }

  ol {
    padding-left: 1.5rem;
  }

  a {
    color: $brainimals-green-light;
    overflow-wrap: break-word;
  }
}

@import "./mixins/datepicker.scss";

.datepicker-wrapper,
.react-datepicker-wrapper {
  display: block;
}

.datepicker,
.react-datepicker {
  position: relative;
  display: inline-block;
  font-family: $datepicker__font-family;
  font-size: $datepicker__font-size;
  color: $datepicker__text-color;
  background-color: $datepicker__background-color;
  border: 1px solid $datepicker__border-color;
  border-radius: $datepicker__border-radius;
}

@supports (backdrop-filter: blur(10px)) {
  .datepicker,
  .react-datepicker {
    background-color: rgba($datepicker__background-color, 0.2);
    backdrop-filter: blur(20px) brightness(115%) saturate(150%);
  }
}

.datepicker--time-only,
.react-datepicker--time-only {
  .datepicker__triangle,
  .react-datepicker__triangle {
    left: 35px;
  }

  .datepicker__time-container,
  .react-datepicker__time-container {
    border-left: 0;
  }

  .datepicker__time,
  .react-datepicker__time {
    border-radius: 0.3rem;
  }

  .datepicker__time-box,
  .react-datepicker__time-box {
    border-radius: 0.3rem;
  }
}

.datepicker__triangle,
.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.datepicker-popper,
.react-datepicker-popper {
  z-index: 1;

  &[data-placement^="bottom"] {
    margin-top: $datepicker__triangle-size + 2px;

    .datepicker__triangle,
    .react-datepicker__triangle {
      @extend %triangle-arrow-up;
    }
  }

  &[data-placement^="top"] {
    margin-bottom: $datepicker__triangle-size + 2px;

    .datepicker__triangle,
    .react-datepicker__triangle {
      @extend %triangle-arrow-down;
    }
  }

  &[data-placement^="right"] {
    margin-left: $datepicker__triangle-size;

    .datepicker__triangle,
    .react-datepicker__triangle {
      right: 42px;
      left: auto;
    }
  }

  &[data-placement^="left"] {
    margin-right: $datepicker__triangle-size;

    .datepicker__triangle,
    .react-datepicker__triangle {
      right: auto;
      left: 42px;
    }
  }
}

.datepicker__header,
.react-datepicker__header {
  position: relative;
  padding-top: 8px;
  text-align: center;
  background-color: $datepicker__background-color;
  border-bottom: 1px solid $datepicker__border-color;
  border-top-left-radius: $datepicker__border-radius;
  border-top-right-radius: $datepicker__border-radius;

  &--time {
    padding-right: 5px;
    padding-bottom: 8px;
    padding-left: 5px;
  }
}

.datepicker__year-dropdown-container--selec,
.react-datepicker__year-dropdown-container--select,
.datepicker__month-dropdown-container--selec,
.react-datepicker__month-dropdown-container--select,
.datepicker__month-year-dropdown-container--selec,
.react-datepicker__month-year-dropdown-container--select,
.datepicker__year-dropdown-container--scrol,
.react-datepicker__year-dropdown-container--scroll,
.datepicker__month-dropdown-container--scrol,
.react-datepicker__month-dropdown-container--scroll,
.datepicker__month-year-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.datepicker__current-mont,
.react-datepicker__current-month,
.datepicker-time__header,
.react-datepicker-time__header {
  margin-top: 0;
  font-size: $datepicker__font-size * 1.18;
  font-weight: $font-weight-bold;
  color: $datepicker__header-color;
}

.datepicker-time__header,
.react-datepicker-time__header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.datepicker__navigation,
.react-datepicker__navigation {
  position: absolute;
  top: 10px;
  z-index: 1;
  width: 0;
  width: 10px;
  height: 10px;
  padding: 0;
  overflow: hidden;
  line-height: $datepicker__item-size;
  text-align: center;
  text-indent: -999em;
  cursor: pointer;
  background: none;
  border: $datepicker__navigation-size solid transparent;

  &--previous {
    left: 10px;
    border-right-color: $datepicker__muted-color;

    &:hover {
      border-right-color: darken($datepicker__muted-color, 10%);
    }

    &--disabled,
    &--disabled:hover {
      cursor: default;
      border-right-color: $datepicker__navigation-disabled-color;
    }
  }

  &--next {
    right: 10px;
    border-left-color: $datepicker__muted-color;
    &--with-time:not(&--with-today-button) {
      right: 80px;
    }

    &:hover {
      border-left-color: darken($datepicker__muted-color, 10%);
    }

    &--disabled,
    &--disabled:hover {
      cursor: default;
      border-left-color: $datepicker__navigation-disabled-color;
    }
  }

  &--years {
    position: relative;
    top: 0;
    display: block;
    margin-right: auto;
    margin-left: auto;

    &-previous {
      top: 4px;
      border-top-color: $datepicker__muted-color;

      &:hover {
        border-top-color: darken($datepicker__muted-color, 10%);
      }
    }

    &-upcoming {
      top: -4px;
      border-bottom-color: $datepicker__muted-color;

      &:hover {
        border-bottom-color: darken($datepicker__muted-color, 10%);
      }
    }
  }
}

.datepicker__month-container,
.react-datepicker__month-container {
  float: left;
}

.datepicker__month,
.react-datepicker__month {
  margin: $datepicker__margin;
  text-align: center;
}

.datepicker__time-container,
.react-datepicker__time-container {
  float: right;
  width: 70px;
  border-left: 1px solid $datepicker__border-color;

  &--with-today-button {
    position: absolute;
    top: 0;
    right: -72px;
    display: inline;
    border: 1px solid $brainimals-dark-cyan;
    border-radius: 0.3rem;
  }

  .datepicker__time,
  .react-datepicker__time {
    position: relative;
    background: $white;

    .datepicker__time-box,
    .react-datepicker__time-box {
      width: 70px;
      margin: 0 auto;
      overflow-x: hidden;
      text-align: center;

      .datepicker__time-list,
      .react-datepicker__time-list {
        box-sizing: content-box;
        width: 100%;
        height: calc(195px + (#{$datepicker__item-size} / 2));
        padding-right: 30px;
        margin: 0;
        overflow-y: scroll;
        list-style: none;

        /* stylelint-disable selector-max-compound-selectors, selector-max-class */
        .datepicker__time-list-item,
        .react-datepicker__time-list-item {
          padding: 5px 10px;

          &:hover {
            cursor: pointer;
            background-color: rgba($datepicker__selected-color, 0.2);
          }

          &--selected {
            font-weight: $font-weight-bold;
            color: $white;
            background-color: $datepicker__selected-color;

            &:hover {
              background-color: $datepicker__selected-color;
            }
          }

          &--disabled {
            color: $datepicker__muted-color;

            &:hover {
              cursor: default;
              background-color: transparent;
            }
          }
        }
        /* stylelint-enable selector-max-compound-selectors, selector-max-class */
      }
    }
  }
}

.datepicker__week-number,
.react-datepicker__week-number {
  display: inline-block;
  width: $datepicker__item-size;
  margin: $datepicker__day-margin;
  line-height: $datepicker__item-size;
  color: $datepicker__muted-color;
  text-align: center;
  &.datepicker__week-number--clickable,
  &.react-datepicker__week-number--clickable {
    cursor: pointer;
    &:hover {
      background-color: $datepicker__background-color;
      border-radius: $datepicker__border-radius;
    }
  }
}

.datepicker__day-name,
.react-datepicker__day-names,
.datepicker__week,
.react-datepicker__week {
  white-space: nowrap;
}

.datepicker__day-nam,
.react-datepicker__day-name,
.datepicker__da,
.react-datepicker__day,
.datepicker__time-name,
.react-datepicker__time-name {
  display: inline-block;
  width: $datepicker__item-size;
  margin: $datepicker__day-margin;
  line-height: $datepicker__item-size;
  color: $datepicker__text-color;
  text-align: center;
}

.datepicker__day,
.react-datepicker__day {
  cursor: pointer;

  &:hover {
    background-color: $datepicker__background-color;
    border-radius: $datepicker__border-radius;
  }

  &--today {
    font-weight: $font-weight-bold;
  }

  &--highlighted {
    color: #FFF;
    background-color: $datepicker__highlighted-color;
    border-radius: $datepicker__border-radius;

    &:hover {
      background-color: darken($datepicker__highlighted-color, 5%);
    }

    &-custom-1 {
      color: $brainimals-blue-grey;
    }

    &-custom-2 {
      color: brainimals-green-light;
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    color: $white;
    background-color: $datepicker__selected-color;
    border-radius: $datepicker__border-radius;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--keyboard-selected {
    color: #FFF;
    background-color: lighten($datepicker__selected-color, 10%);
    border-radius: $datepicker__border-radius;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($datepicker__selected-color, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .datepicker__month--selecting-range &,
    .react-datepicker__month--selecting-range & {
      color: $datepicker__text-color;
      background-color: $datepicker__background-color;
    }
  }

  &--disabled {
    color: $datepicker__muted-color;
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }
}

.datepicker__input-container,
.react-datepicker__input-container {
  position: relative;
  display: block;

  & .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.datepicker__year-read-vie,
.react-datepicker__year-read-view,
.datepicker__month-read-vie,
.react-datepicker__month-read-view,
.datepicker__month-year-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: $datepicker__border-radius;

  &:hover {
    cursor: pointer;

    .datepicker__year-read-view--down-arro,
    .react-datepicker__year-read-view--down-arrow,
    .datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--down-arrow {
    @extend %triangle-arrow-down;
    position: relative;
    top: 8px;
    float: right;
    margin-left: 20px;
    border-width: $datepicker__navigation-size;
    border-top-color: $datepicker__muted-color;
  }
}

.datepicker__year-dropdow,
.react-datepicker__year-dropdown,
.datepicker__month-dropdow,
.react-datepicker__month-dropdown,
.datepicker__month-year-dropdown,
.react-datepicker__month-year-dropdown {
  position: absolute;
  top: 30px;
  left: 25%;
  z-index: 1;
  width: 50%;
  text-align: center;
  background-color: $datepicker__background-color;
  border: 1px solid $datepicker__border-color;
  border-radius: $datepicker__border-radius;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.datepicker__year-optio,
.react-datepicker__year-option,
.datepicker__month-optio,
.react-datepicker__month-option,
.datepicker__month-year-option,
.react-datepicker__month-year-option {
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  line-height: 20px;

  &:first-of-type {
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
  }

  &:last-of-type {
    user-select: none;
    border-bottom-right-radius: $datepicker__border-radius;
    border-bottom-left-radius: $datepicker__border-radius;
  }

  &:hover {
    background-color: $datepicker__muted-color;

    .datepicker__navigation--years-upcoming,
    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($datepicker__muted-color, 10%);
    }

    .datepicker__navigation--years-previous,
    .react-datepicker__navigation--years-previous {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--selected {
    position: absolute;
    left: 15px;
  }
}

.datepicker__close-icon,
.react-datepicker__close-icon {
  position: absolute;
  top: 25%;
  right: 7px;
  width: 16px;
  height: 16px;
  padding: 0;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    padding: 2px;
    margin: -8px auto 0;
    font-size: 12px;
    line-height: 1;
    color: #FFF;
    text-align: center;
    cursor: pointer;
    content: "\00d7";
    background-color: $datepicker__selected-color;
    border-radius: 50%;
  }
}

.datepicker__today-button,
.react-datepicker__today-button {
  padding: 5px 0;
  clear: left;
  font-weight: $font-weight-bold;
  text-align: center;
  cursor: pointer;
  background: $datepicker__background-color;
  border-top: 1px solid $datepicker__border-color;
}

.datepicker__portal,
.react-datepicker__portal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483647;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);

  .datepicker__day-nam,
  .react-datepicker__day-name,
  .datepicker__da,
  .react-datepicker__day,
  .datepicker__time-name,
  .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }

  // Resize for small screens
  @media (max-width: 400px), (max-height: 550px) {
    .datepicker__day-nam,
    .react-datepicker__day-name,
    .datepicker__da,
    .react-datepicker__day,
    .datepicker__time-name,
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }

  .datepicker__current-mont,
  .react-datepicker__current-month,
  .datepicker-time__header,
  .react-datepicker-time__header {
    font-size: $datepicker__font-size * 1.8;
  }

  .datepicker__navigation,
  .react-datepicker__navigation {
    border: 1.8 * $datepicker__navigation-size solid transparent;
  }

  .datepicker__navigation--previous,
  .react-datepicker__navigation--previous {
    border-right-color: $datepicker__muted-color;

    &:hover {
      border-right-color: darken($datepicker__muted-color, 10%);
    }

    &--disabled,
    &--disabled:hover {
      cursor: default;
      border-right-color: $datepicker__navigation-disabled-color;
    }
  }

  .datepicker__navigation--next,
  .react-datepicker__navigation--next {
    border-left-color: $datepicker__muted-color;

    &:hover {
      border-left-color: darken($datepicker__muted-color, 10%);
    }

    &--disabled,
    &--disabled:hover {
      cursor: default;
      border-left-color: $datepicker__navigation-disabled-color;
    }
  }
}

@import "node_modules/bootstrap/scss/_card.scss";

$exercise-card-radius: 20px;

//.exercise-card,
//.exercise-card:before {
.exercise-card {
  position: relative;
  @include box-shadow($box-shadow-raised);
  transform: translateZ(3rem);
  transform-style: preserve-3d;

  display: block;
  margin: 0 auto;
  z-index: 1;

  background-color: $brainimals-deep-sea-blue;

  //&__disabled {
  //  background-color: $brainimals-dark-cyan;
  //}
  &__finished {
    background-color: darken($brainimals-deep-sea-blue, 3%);
  }

  min-height: 360px;
  min-width: 250px;
  max-width: 250px;

  &, &::after, &::before, svg {
    border-radius: $exercise-card-radius;
  }

  .js-tilt-glare {
    border-radius: $exercise-card-radius;
    transform-style: preserve-3d;
  }

  &__disabled {
    filter: contrast(1) brightness(0.7);
    @extend .cursor-default;

    .exercise-card-subtitle, .exercise-card-title {
      opacity: 0.5;
    }

    svg {
      opacity: 0.6;
    }
  }

  &:not(.exercise-card__disabled) {
    @extend .cursor-pointer;
  }

  .exercise-card-badge {
    position: absolute;
    height: 2rem;
    width: 2rem;

    top: 1rem;
    left: 1rem;

    * {
      height: 100%;
      width: 100%;
    }

    transform: translateZ(0.5rem);
  }

  .exercise-card-subtitle {
    position: absolute;
    font-size: 1rem;
    transform: translateZ(1.5rem);
    top: 1.5rem;
    margin: 0 10%;
    width: 80%;
  }

  .exercise-card-title {
    position: absolute;
    font-size: 1.5rem;
    transform: translateZ(3rem);
    top: 3.5rem;
    margin: 0 10%;
    width: 80%;
  }

  .exercise-card-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);

    *, svg {
      height: 100%;
      width: 100%;
    }
  }
}

.exercise-card-bg-element {
  position: absolute;
}

.exercise-card .exercise-card-bg-symbol {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

.exercise-card .exercise-card-bg-symbol svg {
  height: 100%;
  width: auto;
  margin-top: 15px;
}

.exercise-card-action {
  @extend .btn;
  @extend .btn-primary;
  background-color: lighten($brainimals-deep-sea-blue, 10%);
  border: 0;
  font-size: 1.5rem;
  position: absolute;
  transform-style: preserve-3d;
  line-height: 2rem;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateZ(0);
  padding: 0.5rem 0.5rem 0.1rem 0.5rem;
  margin: 0;
  border-radius: 0 0 20px 20px;
  pointer-events: none;

  .exercise-card-action-icon {
    * {
      height: 100%;
      width: 100%;
    }

    svg {
      margin-top: -0.3rem;
    }

    display: inline-block;
    height: 1.75rem;
    width: 1.75rem;
    margin-right: 0.5rem;
  }
}

.text-secondary {
  padding-top: 1rem;
  font-size: 1.5rem;
}

.exercise-card__exercise {
  color: $white;
  //background-color: $primary;
}

@include media-breakpoint-up(sm) {
  .exercise-card {
    min-height: 420px;
    min-width: 392px;
    max-width: 392px;

    .exercise-card-badge {
      height: 2.5rem;
      width: 2.5rem;
    }
    .exercise-card-subtitle {
      top: 2rem;
      font-size: 1.25rem;
    }
    .exercise-card-title {
      top: 4rem;
      font-size: 1.75rem;
    }
    .text-secondary {
      padding-top: 1rem;
      font-size: 1.5rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .exercise-card {
    min-height: 520px;
    min-width: 360px;
    max-width: 360px;

    .exercise-card-badge {
      height: 3rem;
      width: 3rem;
    }
    .exercise-card-subtitle {
      top: 2rem;
      font-size: 1.5rem;
    }
    .exercise-card-title {
      top: 5rem;
      font-size: 2rem;
    }
    .text-secondary {
      padding-top: 2rem;
      font-size: 2rem;
    }
  }
}

@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.progress-item {
  @include bubble-size(
    0,
    0,
    $font-size-base,
    $btn-line-height,
    $bubble-border-radius
  );
  background-color: transparentize($brainimals-darker-cyan, 0.6);
  color: #fff;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  &, * {
    cursor: pointer;
  }


  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    margin-right: 0.5rem;
    width: 100px;
    border-radius: $bubble-border-radius 0 0 $bubble-border-radius;

    @include media-breakpoint-up(sm) {
      margin-right: 0.75rem;
      width: 100px;
    }
    @include media-breakpoint-up(md) {
      margin-right: 1.25rem;
      width: 120px;
    }
  }

  &__start {
    display: none;
    align-items: center;

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    &-icon {
      margin-left: 0.5rem;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fff;
      transform: rotate(180deg);
    }
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__recommendation {
    color: $yellow;
  }

  &--active {
    background-color: $brainimals-deep-sea-blue;

    * {
      cursor: inherit;
    }
  }
}

@import "node_modules/bootstrap/scss/mixins/box-shadow.scss";

// stylelint-disable declaration-no-important
@mixin box-shadow-variant($class, $value) {
  #{$class} {
    @include box-shadow($value);
  }
}

$box-shadows: (
  "shadow": $box-shadow,
  "shadow-sm": $box-shadow-sm,
  "shadow-lg": $box-shadow-lg,
  "shadow-soft": $box-shadow-soft
) !default;

@each $shadow, $value in $box-shadows {
  @include box-shadow-variant(".box-#{$shadow}", $value);
}

// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.bubble {
  &:not(:first-of-type) {
    margin-top: 1rem;
  }

  @extend .align-self-start;

  cursor: default;
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  border: $btn-border-width solid transparent;
  @include bubble-size($btn-padding-x, $btn-padding-x, $font-size-base, $btn-line-height, $bubble-border-radius);
  @include transition($bubble-transition);
  min-height: min-content !important;

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  p {
    margin: 0;
    line-height: 1.5rem;
  }

  span:not(:only-child) {
    img {
      margin-top: 1rem;
    }
  }

  max-width: 100% !important;
}

.bubble-block {
  width: 100%;
}

.bubble-default {
  color: $gray-900;
  background-color: $white;
}

.content-bottom > *:first-of-type {
  margin-top: auto;
}

.content-top > *:first-of-type {
  margin-bottom: auto;
}


//
// Alternate bubbles
//

@each $color, $value in $theme-colors {
  .bubble-#{$color} {
    @include bubble-variant($value, $value);
  }
}


//
// Bubble Sizes
//

.bubble {
  .bubble__info-title,
  .bubble__tip-title {
    border-top-left-radius: $bubble-border-radius;
    border-top-right-radius: $bubble-border-radius;
  }
}

.bubble-lg {
  @include bubble-size($btn-padding-x-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $bubble-border-radius-lg);

  .bubble__info-title,
  .bubble__tip-title {
    border-top-left-radius: $bubble-border-radius-lg;
    border-top-right-radius: $bubble-border-radius-lg;
  }
}

.bubble-sm {
  @include bubble-size($btn-padding-x-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $bubble-border-radius-sm);

  .bubble__info-title,
  .bubble__tip-title {
    border-top-left-radius: $bubble-border-radius-sm;
    border-top-right-radius: $bubble-border-radius-sm;
  }
}

.bubble, .bubble-notice {
  img {
    max-height: 32vh;
    max-width: 100%;
  }
}

.bubble-help {
  img {
    max-height: 45vh;
  }
}

@include media-breakpoint-up(sm) {
  .bubble {
    @include bubble-size($btn-padding-x-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $bubble-border-radius-sm);
  }
}

@include media-breakpoint-up(md) {
  .bubble {
    @include bubble-size($btn-padding-x-md, $btn-padding-x-md, $font-size-md, $btn-line-height-md, $bubble-border-radius-md);
  }
}

@include media-breakpoint-up(lg) {
  .bubble {
    @include bubble-size($btn-padding-x-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $bubble-border-radius-lg);
  }
}


//
// Block bubble
//

.bubble-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block bubbles
  + .bubble-block {
    margin-top: $btn-block-spacing-y;
  }
}


//
// Drag-n-drop bubble
//

@each $color, $value in $theme-colors {
  .bubble-#{$color}.bubble__dragging-over {
    @extend .bubble-#{$color};

    color: color-yiq(darken($value, 7.5%));
    background-color: darken($value, 7.5%) !important;

    @include box-shadow($box-shadow-raised);
  }
}

.bubble__dragging-over {
  transform: scale(1.25) !important;
}

@include media-breakpoint-up(md) {
  .bubble__dragging-over {
    transform: scale(1.5) !important;
  }
}

.bubble__received {
  border-bottom-left-radius: 0px;
  text-align: left;

  div {
    span:only-child:not([class*="text-"]):not([class*="font-"]), span:not([class*="text-"]):not([class*="font-"]) {
      display: block;
      margin: 0 !important;

      img {
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
}

.bubble__tip {
  @extend .bubble-default;

  .bubble__tip-content span:only-child {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.bubble__info,
.bubble__interactive {
  @extend .bubble-primary;
}

.bubble__interactive {
  padding: ($input-btn-padding-y * 2) $input-btn-padding-x !important;
}

@include media-breakpoint-up(md) {
  .bubble__interactive {
    padding: ($input-btn-padding-y-md * 3) $input-btn-padding-x-md !important;
  }
}

.bubble__sent,
.bubble__interactive {
  @extend .align-self-end;
  border-bottom-right-radius: 0px;
  text-align: right;
}


.bubble__info,
.bubble__tip {
  border-bottom-left-radius: 0px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.bubble__info-title,
.bubble__tip-title {
  display: table;
  width: 100%;
  background-color: $brainimals-blue-light;
  color: $primary;
  margin: 0 0 10px 0 !important;
  white-space: nowrap;
  padding: 0.3rem 1rem;
  font-size: 1rem;
}

.bubble__info-content,
.bubble__tip-content {
  padding: 0 1rem;
  text-align: center;
}

.bubble__option {
  color: $white;
  background-color: darken($brainimals-deep-sea-blue, 0.2);
  font-size: 1.5rem;
  margin: 0 5px;
  min-width: 5rem;
  text-align: center;
  border-radius: 10rem;
}

.bubble table.table-grid {
  text-align: center;
  width: 100%;
}
.bubble table.table-grid > thead {
  color: $brainimals-blue-light;
  font-weight: 600 !important;
}

.bubble table.table-grid th:first-child {
  border-right: 0.15rem solid lighten($brainimals-blue-light, 10%);
  border-bottom: 0.15rem solid lighten($brainimals-blue-light, 10%);
}

.bubble table.table-grid th:not(:first-child):not(:last-child) {
  border-right: 0.15rem solid lighten($brainimals-blue-light, 10%);
  border-bottom: 0.15rem solid lighten($brainimals-blue-light, 10%);
  border-left: 0.15rem solid lighten($brainimals-blue-light, 10%);
}

.bubble table.table-grid th:last-child {
  border-bottom: 0.15rem solid lighten($brainimals-blue-light, 10%);
  border-left: 0.15rem solid lighten($brainimals-blue-light, 10%);
}

.bubble table.table-grid td:first-child {
  border-right: 0.15rem solid lighten($brainimals-blue-light, 10%);
  border-top: 0.15rem solid lighten($brainimals-blue-light, 10%);
}

.bubble table.table-grid td:not(:first-child):not(:last-child) {
  border-right: 0.15rem solid lighten($brainimals-blue-light, 10%);
  border-top: 0.15rem solid lighten($brainimals-blue-light, 10%);
  border-left: 0.15rem solid lighten($brainimals-blue-light, 10%);
}

.bubble table.table-grid td:last-child {
  border-top: 0.15rem solid lighten($brainimals-aqua, 30%);
  border-left: 0.15rem solid lighten($brainimals-aqua, 30%);
}

.statsContainer {
  overflow: scroll;
  height: 100%;
}

.statsTable,
.statsTable tr,
.statsTable td,
.statsTable th {
  border: 1px solid grey;
  padding: 10px;
  font-weight: normal;
}

.statsTable {
  margin: 0 auto;
}

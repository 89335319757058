@import "node_modules/bootstrap/scss/utilities/flex.scss";

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-basis-0     { flex-basis: 0 !important; }
    .flex#{$infix}-basis-auto     { flex-basis: auto !important; }
  }
}

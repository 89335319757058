@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.main-header {
  z-index: 1700;

  font-size: $font-size-base;

  $base-padding-top-ios9-10-fallback: 1.5rem;
  $base-padding-top: 0.5rem;
  padding-top: $base-padding-top-ios9-10-fallback;
  padding-top: calc(constant(safe-area-inset-top) + #{$base-padding-top}) !important;
  padding-top: calc(env(safe-area-inset-top) + #{$base-padding-top}) !important;

  .header-logo svg {
    height: 1.25rem;
    margin: 0.75rem 0;
  }

  .header-icon {
    svg {
      max-height: 3rem;
      max-width: 3rem;
      margin: 0;
    }
  }

  #OLLIS {
    fill: $brainimals-topaz;
  }
}

@include media-breakpoint-up(sm) {
  .main-header {
    .header-icon {
      svg {
        max-height: 2.5rem;
        max-width: 2.5rem;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .main-header {
    .header-icon {
      svg {
        max-height: 3rem;
        max-width: 3rem;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .main-header {
    .header-icon {
      svg {
        max-height: 3rem;
        max-width: 3rem;
      }
    }
  }
}

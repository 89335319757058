@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.background-tree {
  position: absolute;
  bottom: 0;
  z-index: -1;

  div {
    &, & svg {
      height: 100%;
      width: 100%;
    }
  }
}

.background-tree__left {
  height: 30rem;
  left: -10rem;
}

.background-tree__right {
  height: 25rem;
  right: -10rem;
}

@include media-breakpoint-up(sm) {
  .background-tree__left {
    height: 35rem;
    left: -15rem;
  }

  .background-tree__right {
    height: 30rem;
    right: -15rem;
  }
}

@include media-breakpoint-up(md) {
  .background-tree__left {
    height: 50rem;
    left: -20rem;
  }

  .background-tree__right {
    height: 43rem;
    right: -20rem;
  }
}

@include media-breakpoint-up(lg) {
  .background-tree__left {
    height: 50rem;
    left: -35rem;
  }

  .background-tree__right {
    height: 55rem;
    right: -25rem;
  }
}

//*********************************************//
/* KEYFRAMES */


@keyframes animateCloud1 {
    0% {
      transform: translate3d(-1500vw, 0, 0), scale(0.65);
    }
    100% {
      transform: translate3d(200vw, 0, 0);
    }
}

@keyframes animateCloud2 {
    0% {
      transform: translate3d(-80vw, 0, 0), scale(0.3);
    }
    100% {
      transform: translate3d(200vw, 0, 0);
    }
}

@keyframes animateCloud3 {
    0% {
      transform: translate3d(-100vw, 0, 0), scale(0.5);
    }
    100% {
      transform: translate3d(200vw, 0, 0);
    }
}

@keyframes animateCloud4 {
    0% {
      transform: translate3d(-110vw, 0, 0), scale(0.4);
    }
    100% {
      transform: translate3d(200vw, 0, 0);
    }
}

@keyframes animateCloud5 {
    0% {
      transform: translate3d(-60vw, 0, 0), scale(0.55);
    }
    100% {
      transform: translate3d(200vw, 0, 0);
    }
}

/* ANIMATIONS */

.clouds-wrap {
  bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	position: fixed;
  margin-left: -50%;
	z-index: -1;
}

.cloud-x1 {
	animation: animateCloud1 50s ease-in-out infinite;
  transform: scale(0.20);
}

.cloud-x2 {
	animation: animateCloud2 100s ease-in-out infinite;
  transform: scale(0.35);
}

.cloud-x3 {
	animation: animateCloud3 60s ease-in-out infinite;
  transform: scale(0.6);
}

.cloud-x4 {
	animation: animateCloud4 80s ease-in-out infinite;
  transform: scale(0.3);
}

.cloud-x5 {
	animation: animateCloud5 70s ease-in-out infinite;
  transform: scale(0.45);
}

/* OBJECTS */

[class*="cloud-x"] {
  will-change: transform;
}

$cloud-background: lighten($brainimals-aqua, 1%);

.cloud {
	background: $cloud-background;
	background: linear-gradient(to bottom,  $cloud-background 5%, $cloud-background 100%);

	border-radius: 100px;

	height: 120px;
	position: relative;
	width: 350px;
}

.cloud:after, .cloud:before {
  background: $cloud-background;
	content: "";
	position: absolute;
	z-indeX: -1;
}

.cloud:after {
	border-radius: 100px;

	height: 100px;
	left: 50px;
	top: -50px;
	width: 100px;
}

.cloud:before {
	border-radius: 200px;

	width: 180px;
	height: 180px;
	right: 50px;
	top: -90px;
}

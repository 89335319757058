@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.landing-form {
  padding: 2rem;
}

.landing-header {
  margin: 0.5rem auto;

  svg {
    max-width: 50vw;
  }
}

.landing-display {
  color: $brainimals-dark-grey-blue;
  font-size: $font-size-lg;
  margin: 0.5rem 0;
}

.landing-character {
  text-align: center;

  svg {
    max-height: 30vh;
    max-width: 50%;
  }
}

@include media-breakpoint-up(sm) {
  .landing-header {
    margin-bottom: 1rem;

    svg {
      max-width: 35vw;
    }
  }

  .landing-character {
    svg {
      max-height: 75vh;
      max-width: 50%;
    }
  }
}

@include media-breakpoint-up(md) {
  .landing-header {
    margin-bottom: 2rem;

    svg {
      max-width: 30vw;
    }
  }

  .landing-character {
    margin-bottom: 1rem;

    svg {
      max-height: 45vh;
      max-width: 80%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .landing-form {
    max-width: 500px;
  }

  .landing-header {
    margin-bottom: 3rem;

    svg {
      max-width: 25vw;
    }
  }

  .landing-character {
    margin-bottom: 2rem;

    svg {
      max-height: 45vh;
      max-width: 75%;
    }
  }
}

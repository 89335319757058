@import "node_modules/bootstrap/scss/utilities/borders.scss";

// stylelint-disable declaration-no-important

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (border: b) {
      @each $size in (0, 1, 2, 3, 4, 5) {
        .#{$abbrev}-#{$size} {
          #{$prop}-width: #{$size}px !important;
        }
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}-width: #{$size}px !important;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top-width: #{$size}px !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right-width: #{$size}px !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom-width: #{$size}px !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left-width: #{$size}px !important;
        }
      }
    }
  }
}

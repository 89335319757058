@import "node_modules/bootstrap/scss/_progress.scss";

.progress-indicator {
  margin-top: 1.5rem;
}

@include media-breakpoint-up(xs) {
  .progress-indicator {
    margin-top: 1rem;
  }
}

@include media-breakpoint-up(md) {
  .progress-indicator {
    margin-top: 2rem;
  }
}

@include media-breakpoint-up(md) {
  .progress-indicator {
    margin-top: 2.5rem;
  }
}

.progress-indicator-icon {
  width: 100%;
  transform: translate3d(0, 0, 0);

  transition: transform .6s ease;
  transition-delay: 60ms;

  > div {
      height: 32px;
      width: 32px;

    * {
      height: 100%;
      width: 100%;
    }
  }
}

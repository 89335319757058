@import "node_modules/bootstrap/scss/_breadcrumb.scss";

.breadcrumb-item {
  @extend .breadcrumb-item;

  & + .breadcrumb-item::before {
    @extend .breadcrumb-item::before;
    position: relative;
    bottom: -3px;
  }

  & + .active {
    font-weight: 600;
  }
}

@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.question-dialog-container {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
}

@include media-breakpoint-up(sm) {
  .question-dialog-container {
    margin: 0 15% !important;
  }
}
@include media-breakpoint-up(lg) {
  .question-dialog-container {
    margin: 0 24% !important;
  }
}


.question-dialog-container:before {
  content: "";
  height: 5vh;
  position: absolute;
  width: 100%;
  display: block;
  z-index: 10;
  background: linear-gradient( to bottom, $brainimals-aqua 10%, rgba($brainimals-aqua, 0) 100% );
}

.question-dialog-footer {
  min-height: 15vh;
  margin-bottom: 1rem;
}

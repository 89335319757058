@import "node_modules/bootstrap/scss/_badge.scss";

.badge {
  @extend .badge;
  cursor: default;
}

.btn .badge {
  cursor: inherit;
}

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @extend .badge-#{$color};

    @include box-shadow($box-shadow-raised-strong);
  }
}

@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.footer {
  background-color: $brainimals-dark-cyan;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  width: 100%;
  z-index: 1;

  &__link {
    display: inline-block;
    padding: 4px 0;
  }

  &__divider {
    margin: 0 20px;
  }
}

@import "scss/_functions.scss";
@import "scss/_variables-overrides.scss";
@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.bg-cover-ground {
  background-image: url("/assets/svg/ground.svg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
}

.program-content {
  margin-top: 65px;
  height: calc(100% - 65px);
}

.program-content-move {
  background-color: transparent;
  position: absolute;
  width: 9.5vw;
  z-index: 100;

  &__left {
    left: 0;
    top: 0;
    bottom: 0;
  }
  &__right {
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.exercises-background {
  position: absolute;
  padding-bottom: 15vh;
  height: 100%;
  width: 100%;

  * {
    height: 100%;
    width: 100%;
  }
}

.exercises,
.carousel-inner,
.carousel-item {
  height: 100% !important;
}
.carousel-wrapper {
  // Replace transition-property from carousel transition variable
  transition: set-nth($carousel-transition, 1, height);
}

.exercises-carousel-title {
  font-size: $h4-font-size;
  margin: 5vh 0 1rem 0;
}
.exercises-carousel-description {
  font-size: $h6-font-size;
  margin: 0;
}

.exercises-carousel-text {
  color: $white;
  font-size: $h6-font-size;
  margin-top: 2rem;
}

.exercises-carousel {
  text-align: center;
  height: 80vh !important;

  .btn {
    z-index: 1000;
  }
}

.carousel-indicators {
  margin-bottom: 2.5vh;
}

@include media-breakpoint-up(xs) {
  .program-content {
    margin-top: 75px;
    height: calc(100% - 75px);
  }
}
@include media-breakpoint-up(sm) {
  .program-content {
    margin-top: 65px;
    height: calc(100% - 65px);
  }
  .program-content-move {
    width: 12vw;
  }
  .carousel-indicators {
    margin-bottom: 3.5vh;
  }
  .exercises-carousel-title {
    font-size: $h5-font-size;
    margin: 5vh 0 1rem 0;
  }
  .exercises-carousel-description {
    font-size: $h6-font-size;
  }
  .exercises-carousel-text {
    font-size: $h6-font-size;
    margin-top: 2rem;
  }
}

@include media-breakpoint-up(md) {
  .program-content {
    margin-top: 75px;
    height: calc(100% - 75px);
  }
  .exercises-carousel-text {
    font-size: $h4-font-size;
    margin-top: 2rem;
  }
  .exercises-carousel-title {
    font-size: $h4-font-size;
  }
  .exercises-carousel-description {
    font-size: $h5-font-size;
  }
  .exercises-carousel-text {
    font-size: $h5-font-size;
  }
  .program-content-move {
    width: 20vw;
  }
}
@include media-breakpoint-up(lg) {
  .carousel-indicators {
    margin-bottom: 4.5vh;
  }
  .exercises-carousel-title {
    font-size: $h3-font-size;
  }
  .exercises-carousel-description {
    font-size: $h5-font-size;
  }
  .exercises-carousel-text {
    font-size: $h5-font-size;
    margin-top: 2rem;
  }
  .program-content-move {
    width: 25vw;
  }
}

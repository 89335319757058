@import "node_modules/bootstrap/scss/_navbar.scss";

canvas {
  transform: translateZ(-2px);
  transform-style: preserve-3d;
}

.navbar {
  @extend .navbar;
  padding: map-get($spacers, 3);
}

.navbar-shadow {
  @include box-shadow($box-shadow-sm);
}

.navbar-column {
  @include box-shadow($box-shadow-sm);

  position: fixed;
  left: 0;
  z-index: $zindex-tooltip + 20;
  height: 100vh;

  margin: -$grid-gutter-width;

  background-color: $white;
  transition: left 200ms ease-in-out;

  &.navbar-column-open {
    left: 100%;
  }

  .list-group-item:not(.active) {
    .list-group-item-description-hover {
      max-height: 0;
      overflow: hidden;
      opacity: 0;

      @include transition();
    }

    &:hover {
      .list-group-item-description-hover {
        max-height: 200px;
        overflow: hidden;
        opacity: 1;

        @include transition();
        transition-delay: 400ms;
      }
    }
  }
}

.navbar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-tooltip + 10;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 200ms ease-in-out;
  transition-delay: 250ms;
  backface-visibility: hidden;

  &.navbar-backdrop-open {
    pointer-events: all;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@include media-breakpoint-up(xs) {
  .navbar-column {
    width: 100vw;
    margin-left: -100%;
  }
}

@include media-breakpoint-up(sm) {
  .navbar-column {
    width: 300px;
    margin-left: -100%;
  }
}

@import "node_modules/bootstrap/scss/_popover.scss";

.popover {
  @extend .popover;
  @include box-shadow($box-shadow-raised);
}

.popover-header {
  @extend .popover-header;

  font-weight: 600;

  background: $gray-100;
  border-bottom: 0;
}
